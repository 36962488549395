const Button = (props) => {
  return (
    <button
      {...props}
      className={`
      mt-2 mb-4 text-md font-bold shadow-md px-8 py-2 rounded-full 
      hover:shadow-md 
      ${props.orange ? 'bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white'
        : 'bg-white hover:bg-amber-100 active:bg-amber-300'}
      transaction-all duration-200
      flex flex-row justify-center items-center
      ${(props.loading || props.disabled) ? 'cursor-not-allowed' : 'cursor-auto'}
      ${props.className}
      `}
      disabled={props.disabled || props.loading}
    >
      {
        props.loading &&
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4"></circle>
            <path class="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
      }
      {props.children}
    </button>
  )
}

export default Button;