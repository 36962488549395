import Markdown from "markdown-to-jsx"
import { FC } from "react"

interface Props {
  children: any
}

const _Markdown: FC<Props> = ({ children }) => {
  return (
    <Markdown
      className="text-sm mt-1 text-gray-500"
      options={{
        overrides: {
          a: (props) => <a {...props} target="_blank" className="underline">{props.children}</a>,
          hr: () => <hr className="my-4" />,
          img: (props) => <img {...props} className="w-12 rounded-md inline m-1" />
        },
      }}
    >{children}</Markdown>
  )
}

export default _Markdown