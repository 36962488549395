const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const scoreCheck = require('./scoreCheck.json');
const x10 = require('./scoreJson/x10.json');
const x11 = require('./scoreJson/x11.json');
const x12 = require('./scoreJson/x12.json');
const x13 = require('./scoreJson/x13.json');
const x14 = require('./scoreJson/x14.json');
const x16 = require('./scoreJson/x16.json');
const x20 = require('./scoreJson/x20.json');

const map = {
  x10: x10,
  x11: x11,
  x12: x12,
  x13: x13,
  x14: x14,
  x16: x16,
  x20: x20,
}

const allIds = [...x10,...x11,...x12,...x13,...x14,...x16,...x20]
  .sort((a, b) => a - b);

for (let i = 0; i < 3000 - 10; i++) {
  const realScore = scoreCheck[allIds[i]];
  let testIncluded;
  switch(realScore) {
    case 1:
      testIncluded = x10.includes(allIds[i]);
      break;
    case 2:
      testIncluded = x11.includes(allIds[i]);
      break;
    case 3:
      testIncluded = x12.includes(allIds[i]);
      break;
    case 4:
      testIncluded = x13.includes(allIds[i]);
      break;
    case 5:
      testIncluded = x14.includes(allIds[i]);
      break;
    case 7:
      testIncluded = x16.includes(allIds[i]);
      break;
    case 11:
      testIncluded = x20.includes(allIds[i]);
      break;
  }

  if (!testIncluded) {
    console.log(`Not included on ${i + 11}, ${allIds[i]}`);
    break;
  }

  if (!(allIds[i] === i + 11)) {
    console.log(`failed on ${i + 11}, ${allIds[i]}`);
    break;
  }
}

export function getRootHash(arr) {
  const leafNodes = arr.map(item => keccak256(`${item}`));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  const rootHash = merkleTree.getHexRoot();
  return rootHash;
}

export function getAllTier() {
  return {
    x10: getRootHash(x10),
    x11: getRootHash(x11),
    x12: getRootHash(x12),
    x13: getRootHash(x13),
    x14: getRootHash(x14),
    x16: getRootHash(x16),
    x20: getRootHash(x20),
  }
}

export function getTierByTokenId(_tokenId) {
  let targetKey = null;
  const tokenId = parseInt(_tokenId);

  if (x10.includes(tokenId)) { targetKey = 'x10' }
  else if (x11.includes(tokenId)) { targetKey = 'x11' }
  else if (x12.includes(tokenId)) { targetKey = 'x12' }
  else if (x13.includes(tokenId)) { targetKey = 'x13' }
  else if (x14.includes(tokenId)) { targetKey = 'x14' }
  else if (x16.includes(tokenId)) { targetKey = 'x16' }
  else if (x20.includes(tokenId)) { targetKey = 'x20' }

  if (targetKey === null) {
    console.log('no target 1', tokenId, targetKey);
    throw new Error('No target for token' + tokenId);
  }
  return parseInt(targetKey.substring(1));
}

export function getHashProofByTokenId(_tokenId) {
  let targetKey = null;
  const tokenId = parseInt(_tokenId);

  if (x10.includes(tokenId)) { targetKey = 'x10' }
  else if (x11.includes(tokenId)) { targetKey = 'x11' }
  else if (x12.includes(tokenId)) { targetKey = 'x12' }
  else if (x13.includes(tokenId)) { targetKey = 'x13' }
  else if (x14.includes(tokenId)) { targetKey = 'x14' }
  else if (x16.includes(tokenId)) { targetKey = 'x16' }
  else if (x20.includes(tokenId)) { targetKey = 'x20' }

  if (targetKey === null) {
    console.log('no target 2', tokenId, targetKey);
    throw new Error('No target for token ' + tokenId);
  }

  console.log('targetKey', targetKey);

  const leafNodes = map[targetKey].map(item => keccak256(`${item}`));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const claimAddress = keccak256(`${tokenId}`);
  const hexProof = merkleTree.getHexProof(claimAddress);
  return hexProof;
}
