import { css } from 'styled-components';

export const SIZES = {
  mobile: '641px'
}

export const Button = css`
  box-shadow: none;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 2rem;
  color: white;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
`

export const CircledButton = css`
  display: inline-block;
  padding: 0.5rem 1.2rem;
  border-radius: 25px;
  border: 2px solid white;
  font-weight: normal;
  color: white;
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: 18px;
`

export const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`