import styled from 'styled-components';

const Container = styled.div`
height: 100vh;
position: relative;
display: flex;
flex-direction: row;

@media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    display: block;
}

.left {
    position: absolute;
    left: 0;
    top: 0;
    width: 30%;
    padding: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        height: auto;
        position: relative;
        width: 100%;
        margin-top: 20px;
    }
}

.right {
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;

    @media (max-width: 768px) {
        position: relative;
        width: 100%;
    }
}

.wallet-container {
    height: 100vh;
    width: 30%;
    position: absolute;
    right: 0;

    @media (max-width: 768px) {
        display: none;
    }
}
`

export default Container;