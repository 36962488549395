
const Combination = () => {
  return (
    <>
      <div className="bg-blue-800 text-left px-8 md:px-24 py-12">
        <p className="text-2xl text-white font-bold">
          Welcome to isoroom!
          <br />In the past, you never really own a room in games no matter how much time you spent on decorating the room. 
          <br />And now with isoroom, you can finally have a virtual room that is belongs to you. Only you.
    
        </p>
      </div>
      <div className="py-24 bg-orange-100">
        <div className="max-w-screen-xl m-auto text-center">
          © 2022 <b>isoroom</b>. All rights reserved 🏘️
        </div>
      </div>
    </>
  )
}

export default Combination;