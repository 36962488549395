import { useState, useEffect, FC } from 'react'
import ListHeader from '../../components/store/ListHeader';
import ProductItem from '../../components/store/ProductItem'
import ProductPopup from '../../components/store/ProductPopup'
import Strapi from '../../services/Strapi';
import { ProductItemProps } from '../../components/store/ProductItem';
import { useAlert } from 'react-alert';
import { StoreProductDTO } from '../../interfaces/StoreProductDTO';
import PurchasesPopup from '../../components/store/PurchasesPopup';
import { useAuth } from '../../context/Auth';
import { useNavigate } from "react-router-dom";
import Web3 from 'web3';
import Apiv2 from '../../services/Apiv2';
import qs from 'qs';

const isoroomPfp = 'https://pbs.twimg.com/profile_images/1496399082838966274/uA1I4FJ6_400x400.jpg';

const isoroomContract = require("../../utils/contracts/Isoroom.json");
const isoTokenContract = require("../../utils/contracts/IsoToken.json");
const genesisVaultContract = require("../../utils/contracts/GenesisVault.json");

const ISOROOM_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;
const GENESIS_VAULT_ADDRESS = process.env.REACT_APP_GENESIS_VAULT_ADDRESS;

// const isoescrowAddr = '0xb240bb848A1Ef111f316064de35A6CEca315c11a9';
const isoescrowAddr = '0xb240b848A1Ef111f316064de35A6CEca315c11a9';

const storeProductToProductItem = (input: StoreProductDTO[]) => {
  console.log({ input })
  const products: ProductItemProps[] = [];

  for (let i = 0; i < input.length; i++) {
    const element = input[i];

    products.push({
      id: `${element.id}`,
      ...element.attributes,
      imageSrc: element.attributes.image_src,
      onClick: () => console.log('o i c'),
      imageAlt: element.attributes.name,
      seller: {
        name: 'isoroom',
        profilePic: isoroomPfp,
      },
      qty: {
        stock: element.attributes.qty -
          element.attributes.store_transactions.data
            .filter((s: any) => s.attributes.status !== 'rejected').length,
        total: element.attributes.qty
      },
      special: element.attributes.owner_exclusive ? 'Genesis Exclusive' : null,
    });
  }

  return products;
}

const PortalStore:FC<any> = () => {
  const navigate = useNavigate();
  const { connected, web3, signature, sigData } = useAuth();
  const alert = useAlert();
  const [products, setProducts] = useState<ProductItemProps[]>([]);
  const [largeViewGrid, setLargeViewGrid] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState<ProductItemProps | null>(null);
  const [purchasesPopUpOpen, setPurchasesPopUpOpen] = useState<ProductItemProps | null>(null);
  const [purchaseStep, setPurchaseStep] = useState(1);
  const [purchaseErr, setPurchaseErr] = useState(null);
  const [purchasedId, setPurchasedId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [fragmentQty, setFragmentQty] = useState(0)

  const checkIsIsoroomOwner = async (web3: Web3, address: string) => {
    const isoroomC = new web3.eth.Contract(isoroomContract.abi, ISOROOM_CONTRACT_ADDRESS);
    const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
    const balanceA = await isoroomC.methods.balanceOf(address).call();
    if (balanceA > 0) return setIsOwner(true);
    const balanceB = await genesisVaultC.methods.balanceOf(address).call();
    if (balanceB > 0) return setIsOwner(true);
  }

  const preparePayment = async (web3: any, address: string, price: number) => {
    // C For contract
    const IsoTokenC = new web3.eth.Contract(isoTokenContract.abi, ISOTOKEN_ADDRESS);
    const valueInWei = price + '000000000000000000';
    const gasLimit = await IsoTokenC.methods
      .transfer(isoescrowAddr, valueInWei)
      .estimateGas({ from: address });
    const gasPrice = await web3.eth.getGasPrice();

    console.log({ gasLimit, gasPrice })

    const params = {
      from: address,
      gasLimit: gasLimit,
      gasPrice: Math.floor(gasPrice * 1.2),
    }

    return new Promise((res, rej) => IsoTokenC.methods
      .transfer(isoescrowAddr, valueInWei)
      .send(params)
      .on('transactionHash', (txHash: string) => {
        res(txHash)
      })
      .on('error', (error: any) => {
        rej(error);
      })
    )
  };

  const prepareEIsoPayment = async (address: string, price: number) => {
    const valueInWei = price + '000000000000000000';
    const body = {
      fromAddr: address,
      toAddr: isoescrowAddr,
      value: valueInWei
    };
    const { status, data } = await Apiv2.post('eiso', JSON.stringify(body));
    return data.txid;
  }

  const fetchProducts = async () => {
    try {
      let url = 'store-products?filters[status][$eq]=open';
      url += '&populate[store_transactions][fields]=tx_hash,status';
      url += '&pagination[limit]=100';
      const result = await Strapi.get(url) as { data: StoreProductDTO[] };
      let productsAttr = storeProductToProductItem(result.data);

      setProducts(productsAttr);
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  const fetchCoupons = async (address: string) => {
    const query = qs.stringify({
      filters: {
        $and: [{
          wallet_address: { $containsi: address.toLocaleLowerCase() },
          status: 'active',
        }]
      },
      pagination: {
        limit: 100,
      },
      sort: ['id:asc']
    }, { encodeValuesOnly: true });

    let url = `store-coupons?${query}`;
    const result = await Strapi.get(url);
    const objs = result.data.map((i: any) => ({ ...i.attributes, id: i.id }));
    setCoupons(objs);
  }

  const placePurchasesOrder = async (
    web3: any,
    connected: string,
    item: ProductItemProps,
    sigData: any,
    signature: string,
    type: string,
    selectedCoupon: any,
    qty: number = 1,
  ) => {
    let result;
    try {
      const url = 'store-transactions';
      const data = {
        sig_data: sigData,
        signature,
        store_product: item.id,
        store_coupon: selectedCoupon?.id || null,
        qty,
      };

      result = await Strapi.post(url, JSON.stringify(data));
      if (result.status === 'error') throw new Error(result.message);

      setPurchaseStep(2);
      let txHash = null;
      const finalPrice = (item.price * qty) - (selectedCoupon?.amount || 0)

      if (type === 'iso') {
        txHash = await preparePayment(web3, connected, finalPrice);
      } else {
        txHash = await prepareEIsoPayment(connected, finalPrice);
        txHash = 'internal::' + txHash;
      }

      setPurchaseStep(3);
      const payUrl = 'store-transactions/pay';
      const payData = {
        "payment_str": result.data.payment_str,
        "tx": txHash,
      }
      const payResult = await Strapi.post(payUrl, JSON.stringify(payData));
      if (payResult.status === 'error') throw new Error(payResult.message);
      setPurchasedId(payResult.id);

      setPurchaseStep(4);
      fetchProducts();
      fetchCoupons(connected);
    } catch (e: any) {
      const errorMsg = e.message || e.toString();
      setPurchaseErr(errorMsg);
      const payUrl = 'store-transactions/cancel';
      const payData = {
        payment_str: result.data.payment_str,
        error: errorMsg
      };
      try { await Strapi.post(payUrl, JSON.stringify(payData)) }
      catch (e2: any) { console.log(e2) }
    }
  }

  const onRedeem = async (id: string | null, name: string, redemptionAddress: string) => {
    try {
      if (name.length === 0)
        throw new Error('Missing Discord name.');

      if (redemptionAddress.length === 0)
        throw new Error('Missing wallet address.');

      if (id === null)
        throw new Error('Missing ID, please update discord name on inventory page.');

      // if (!(new Web3()).utils.isAddress(redemptionAddress))
      //   throw new Error('Wrong wallet address format.');

      const url = 'store-transactions/update/discord-name';
      const data = {
        id,
        sig_data: sigData,
        signature,
        discord_name: name,
        redemption_address: redemptionAddress,
      };
      await Strapi.post(url, JSON.stringify(data));
      navigate('/portal/inventory')
    } catch (e: any) {
      window.alert(e.message || e.toString());
    }
  }

  const onProductItemClick = (product: any) => {
    setPurchaseStep(1);
    setPurchaseErr(null);
    setPopUpOpen(product);
    console.log(product)
  }

  const fetchStoreProducts = async () => {
    try {
      const res = await Strapi.get('store-product/all');
      setProducts(res.data);
      
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }

  // const fetchAvailableFragments = async () => {
  //   try {
  //     const payload = { wallet: connected }
  //     const res = await Strapi.post('isorooms/available-qty', JSON.stringify(payload));

  //     setFragmentQty(res.available_fragments)
      
  //   } catch (error) {
  //     throw new Error(JSON.stringify(error));
  //   }

  // }

  useEffect(() => {
    // fetchProducts();
    fetchStoreProducts();
    // fetchAvailableFragments();
  }, []);

  useEffect(() => {
    if (web3 && connected) {
      checkIsIsoroomOwner(web3, connected);
      fetchCoupons(connected);
    }
  }, [web3, connected])

  const useableCoupon = coupons.filter((i: any) => popUpOpen && popUpOpen.price >= i.min_requirement);

  return (
    <>
      {
        popUpOpen !== null &&
        <ProductPopup
          open={popUpOpen !== null}
          onClose={() => {
            setPurchaseStep(1);
            setPopUpOpen(null)
          }}
          onPay={(type: string, couponId: string | null, qty: number) => {
            setPurchasesPopUpOpen(popUpOpen);
            setPopUpOpen(null);
            if (sigData && signature && popUpOpen) {
              console.log({ sigData, signature, popUpOpen })
              placePurchasesOrder(web3, connected || '', popUpOpen, sigData, signature, type, couponId, qty);
            }
          }}
          id={popUpOpen?.id || ''}
          type={popUpOpen?.type || ''}
          name={popUpOpen?.name || '-'}
          price={popUpOpen?.price || 0}
          token={popUpOpen?.token || '-'}
          description={popUpOpen?.description || '-'}
          imageSrc={popUpOpen?.imageSrc || ''}
          imageAlt={popUpOpen?.imageAlt || '-'}
          twitter={popUpOpen?.twitter || ''}
          highlights={[
            `Mint day: ${popUpOpen?.mint_date || 'TBC'}`,
            `Discord: ${popUpOpen?.discord || 'Please open ticket'}`,
          ]}
          coupons={useableCoupon}
          maxQty={popUpOpen.category.toLowerCase() === 'fragments' ? fragmentQty : 1}
        />
      }

      {
        purchasesPopUpOpen !== null &&
        <PurchasesPopup
          address={connected || ''}
          open={purchasesPopUpOpen !== null}
          onClose={() => setPurchasesPopUpOpen(null)}
          currentStep={purchaseStep}
          error={purchaseErr}
          onRedeemClick={async (name, redemptionAddress) =>
              await onRedeem(purchasedId, name, redemptionAddress)}
        />
      }

      <div>
        <main className="max-w-7xl mx-auto">
          <div
            className="rounded-lg"
            style={{
              background: `url('https://images.unsplash.com/photo-1634973357973-f2ed2657db3c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80') center center / cover`,
              height: 150,
              width: '100%',
              position: 'relative',
              marginBottom: 10,
            }}
          />

          <div className="mt-4">
            <ListHeader
              onShowAll={(v) => setShowAll(v)}
              header="isostore"
              setMobileFiltersOpen={() => { }}
              onViewGridChange={() => setLargeViewGrid(!largeViewGrid)}
            />
            <section aria-labelledby="products-heading" className="pt-6 pb-24">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                {/* Product grid */}
                <div className="lg:col-span-4">
                  <div className={`grid grid-cols-${largeViewGrid ? '1' : '2'}
                  sm:grid-cols-${largeViewGrid ? '2' : '4'}
                  lg:grid-cols-${largeViewGrid ? '3' : '6'}`}>
                    {
                      products.map(p => {
                        if (!showAll && p.qty.stock <= 0) return;

                        return (
                          <ProductItem
                            key={p.id}
                            qty={p.qty}
                            onClick={() => onProductItemClick(p)}
                            id={p.id}
                            imageSrc={p.imageSrc}
                            imageAlt={p.imageAlt}
                            name={p.name}
                            price={p.price}
                            token={p.token}
                            category={p.category}
                            seller={p.seller}
                            description={p.description}
                            special={p.special}
                            disabled={p.special !== null && !isOwner}
                          />
                        )
                      }
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
}

export default PortalStore;
