import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.d = 1.8;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;

    this.setInstance();
    this.setControls();
  }

  setInstance() {
    const aspect = this.sizes.width / this.sizes.height;
    const d = 1.8;
    this.instance = new THREE.OrthographicCamera(
      -d * aspect,
      d * aspect,
      d,
      -d,
      -1000,
      1000
    );
    this.instance.position.x = 6;
    this.instance.position.y = 4;
    this.instance.position.z = 6;
    this.scene.add(this.instance);
  }

  setControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);

    this.controls.minPolarAngle = Math.PI * 0.3;
    this.controls.maxPolarAngle = Math.PI * 0.45;
    this.controls.minZoom = 1;
    this.controls.maxZoom = 2;
    this.controls.maxAzimuthAngle = Math.PI * 0.45;
    this.controls.minAzimuthAngle = Math.PI * 0.05;
    this.controls.target.set(0, 1.2, 0);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1; // friction
    this.controls.rotateSpeed = 0.4; // mouse sensitivity
  }

  resize() {
    const aspect = this.sizes.width / this.sizes.height;
    const d = this.d;
    this.instance.left = -d * aspect;
    this.instance.right = d * aspect;
    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
