import * as THREE from "three";
import Experience from "../Experience.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';

export default class Room {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("building_tile");
    }

    // Resource
    for (const type in this.resources.items) {
      const obj = this.resources.items[type];
      this.setModel(type, obj);
    }
  }

  generateTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = 2;
    canvas.height = 2;

    const context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 1, 2, 1);

    return canvas;
  }

  async geenrateAlphaMap() {
    // creating a texture with canvas
    var amCanvas = document.createElement('canvas'),
      ctx = amCanvas.getContext('2d');
    amCanvas.width = 64;
    amCanvas.height = 64;
    // drawing gray scale areas
    ctx.fillStyle = '#404040';
    ctx.fillRect(0, 0, 32, 32);
    ctx.fillStyle = '#808080';
    ctx.fillRect(32, 0, 32, 32);
    ctx.fillStyle = '#c0c0c0';
    ctx.fillRect(0, 32, 32, 32);
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(32, 32, 32, 32);
    var alphaMap = new THREE.CanvasTexture(amCanvas);
    return alphaMap;
  }

  async setModel(type, obj) {
    const alphaMap = this.geenrateAlphaMap();
    const envMap = new RGBELoader()
      .setPath('/textures/')
      .load('photo_studio_01_4k.hdr', function () {
        envMap.mapping = THREE.EquirectangularReflectionMapping;
      });

    let model = obj.scene;
    model.position.y = 0.1;
    this.scene.add(model);

    model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material.flatShading = true;
        child.geometry.computeVertexNormals();

        if (
          child.material.name === 'MAT1'
          || child.material.name === 'MAT4'
        ) {
          const pos = child.position;
          child.position.set(pos.x, pos.y + 0.002, pos.z);
        }

        /**
         * Emissive enhancement
         */
        if (
          child.material.name === 'STAND8'
          || child.material.name === 'RED_LIGHT'
          || child.material.name === 'DICE1.003'
          || child.material.name === 'DICE1'
          // || child.material.name === 'DICE1.004'
          || child.material.name.includes('ARCADE_GROW2')
          || child.material.name === 'WARMYELLOW_LIGHT'
          || child.material.name.includes('ARCADE_GROW1')

          || child.material.name === 'MONITOR1.002'
          || child.material.name === 'MONITOR_SCREEN3'
        ) {
          let emissiveIntensity = 1;
          let color = 0xffffff;

          if (
            child.material.name === 'WARMYELLOW_LIGHT'
            || child.material.name.includes('ARCADE_GROW1')
          ) color = 0xffe100;

          if (child.material.name.includes('ARCADE_GROW2'))
            color = 0xDBA0EF;

          if (
            child.material.name === 'DICE1.003'
            || child.material.name === 'DICE1'
          ) {
            color = 0xffffff;
            emissiveIntensity = 0.4
          }

          if (child.material.name === 'STAND8'
            || child.material.name === 'RED_LIGHT')
            color = 0xff0000;

          child.material.emissiveIntensity = emissiveIntensity;
          child.material.emissive = new THREE.Color(color);
        }

        if (
          child.material.name === 'FLOWER'
          || child.material.name === 'FLOWER.001' 
          || child.material.name === 'FLOWER_ROD'
        ) {
          child.material.emissiveIntensity = 0.3;
          child.material.color = new THREE.Color(0xffffff);
          child.material.emissive = new THREE.Color(0xffffff);
        }

        /**
         * Add light to rock to make it more 3d
         */
        if (child.material.name === 'Rock' || child.material.name === 'MOAI.002') {
          const pos = child.position;
          const width = 0.5;
          const height = 0.7;
          const intensity = 12;
          const rectLight = new THREE.RectAreaLight(0xffffff, intensity, width, height);
          if (child.material.name === 'Rock') {
            // Workroom
            rectLight.position.set(pos.x + 0.5, pos.y + 0.8, pos.z - 0.15);
            rectLight.lookAt(pos.x - 0.5, pos.y + 0.8, pos.z + 1);
          } else {
            rectLight.position.set(pos.x, pos.y + 0.8, pos.z + 0.65);
            rectLight.lookAt(pos.x + 2.5, pos.y + 0.8, pos.z - 1);
          }
          this.scene.add(rectLight)

          // const rectLightHelper = new RectAreaLightHelper(rectLight);
          // rectLight.add(rectLightHelper);
        }

        /**
         * Lego floor too high
         */
        if (
          child.material.name.includes('FLOOR20')
          || child.material.name.includes('FLOOR21')
          || child.material.name.includes('FLOOR22')
        ) {
          const pos = child.position;
          child.position.set(pos.x, pos.y - 0.01, pos.z);
        }

        /**
         * Metal
         */
        if (
          child.material.name === 'SLOT_MACHINE'
          || child.material.name.includes('TIMEMACHINE')
          || child.material.name.includes('ROBOTIC_HAND2.007')
          || child.material.name.includes('ROBOTIC_HAND1.007')

          || child.material.name === 'ROBOTIC_HAND1'
          || child.material.name.includes('ROBOTIC_HAND1.002')

          || child.material.name.includes('COIN')
          || child.material.name === 'WALL25'
          || child.material.name === 'ALUMINIUM'
          || child.material.name === 'BED4.002'
          || child.material.name === 'BED11.004'
          || child.material.name === 'BED12'

          || child.material.name.includes('TURNTABLE8.007')
        ) {
          let color = 0x8c8c8c;
          if (child.material.name === 'WALL25')
            color = 0x545454;

          if (child.material.name.includes('ROBOTIC_HAND1.002'))
            color = 0xf77b00;

          if (child.material.name.includes('TURNTABLE8'))
            color = 0x000000;

          const mirrorMaterial = new THREE.MeshStandardMaterial({
            color,
            emissive: 0xffffff,
            emissiveIntensity: 0.1,
            roughness: 0.5,
            metalness: 1,
            envMap,
            // envMapIntensity: 0.1,

          });
          child.material = mirrorMaterial;
        }

        /**
         * Glass material
         */
        if (child.material.name.includes('GLASS')) {
          const params = {
            color: 0x94eaff,
            transmission: 1,
            opacity: 1,
            metalness: 0,
            roughness: 0,
            ior: 2,
            thickness: 5,
            specularIntensity: 1,
            specularColor: 0xffffff,
            envMapIntensity: 1,
            lightIntensity: 1,
            exposure: 1,
            // alphaMap,
            envMap,
            transparent: true,
          };

          if (
            // Glass desk
            child.name === 'Cube052'
            || child.name === 'Cube012'
          ) {
            const paramsCopy = { ...params };
            paramsCopy.metalness = 0.2;
            paramsCopy.roughness = 0.5;
            paramsCopy.thickness = 0.5;
            // paramsCopy.transmission = 0.65;
            // paramsCopy.clearcoat = 1;
            // paramsCopy.clearcoatRoughness = 1;
            // paramsCopy.normalScale = 5;
            // paramsCopy.clearcoatNormalScale = 5;
            const mirrorMaterial = new THREE.MeshPhysicalMaterial(paramsCopy);
            child.material = mirrorMaterial;
          } else if (child.name === 'Wasserspender001') { // Gashapon egg
            // TODO : Dont know why glass not applicable
            const paramsCopy = { ...params };
            paramsCopy.color = 0xbfbfbf;
            paramsCopy.metalness = 0.4;
            paramsCopy.roughness = 0.2;
            paramsCopy.thickness = 0.2;
            const mirrorMaterial = new THREE.MeshPhysicalMaterial(paramsCopy);
            child.material = mirrorMaterial;
          } else if (child.name === 'Plane004') {
            const paramsCopy = { ...params };
            paramsCopy.metalness = 0.2;
            paramsCopy.roughness = 0.5;
            paramsCopy.thickness = 0.5;
            const mirrorMaterial = new THREE.MeshPhysicalMaterial(paramsCopy);
            child.material = mirrorMaterial;
          } else {
            const mirrorMaterial = new THREE.MeshPhysicalMaterial({ ...params });
            child.material = mirrorMaterial;
          }
        }

        child.flatShading = true;
      }
    });
  }

  changeFrame(imgSrc, frame) {
    this.scene.traverse(async (child) => {
      if (child.name.includes(`FRAME_SET${frame}`)) {
        const textureLoader = new THREE.TextureLoader();
        const material = await textureLoader.load(imgSrc);
        material.center = new THREE.Vector2(0.5, 0.5);
        material.flipY = false;
        const frameMaterial = new THREE.MeshBasicMaterial({ map: material });
        child.material = frameMaterial;
      }
    });
  }
}
