import { FC } from 'react'
import { BigNumber } from 'ethers';

interface BalanceProps {
  isoBalance: any;
  decimals: any;
  addTokenToMetamask: () => void;
}

const Balance:FC<BalanceProps> = ({ isoBalance, decimals, addTokenToMetamask }) => {
  return (
    <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
      <p className="text-lg font-semibold text-slate-900">🪙 Your $ISO balance</p>
      <p className="text-2xl font-medium text-slate-700 mt-2"><b>{
        isoBalance === null ? 'Loading...' : BigNumber.from(isoBalance).div(decimals).toString()
      }</b></p>
      <p onClick={addTokenToMetamask} className="mt-2 text-xs underline cursor-pointer">Add $ISO to Metamask</p>
    </div>
  )
}

export default Balance;