import { FC, useEffect, useCallback, useState, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useAuth } from '../../context/Auth';
import { Unity, useUnityContext } from "react-unity-webgl";
import Badge from '../../components/Badge';

const Game: FC<any> = () => {
  const { unityProvider, isLoaded, loadingProgression, unload,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate } = useUnityContext({
      loaderUrl: "/gameBuild/Build.loader.js",
      dataUrl: "/gameBuild/Build.data.unityweb",
      frameworkUrl: "/gameBuild/Build.framework.js.unityweb",
      codeUrl: "/gameBuild/Build.wasm.unityweb",
    });

  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  const loadingPercentage = Math.round(loadingProgression * 100);

  useEffect(() => {
    return () => window.location.reload();
  }, [])

  return (
    <div>
      <div
        className="rounded-lg"
        style={{
          background: `url('/image/isoworld-banner.jpg') center center / cover`,
          height: 150,
          width: '100%',
          position: 'relative',
          marginBottom: 10,
        }}
      />

      <div className="rounded-x text-green-900 font-bold mb-4">
        <Badge mode="green">Beta</Badge>
        {'  '}Leaving the page requires re-login.
      </div>

      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <b>Loading... ({loadingPercentage}%)</b>
        </div>
      )}
      <Unity
        style={{ width: '100%' }}
        devicePixelRatio={devicePixelRatio}
        unityProvider={unityProvider}
      />
    </div>);
}

export default Game;
