import { FC } from "react";

interface Props {
  mode: 'default'
  | 'dark'
  | 'red'
  | 'green'
  | 'yellow'
  | 'indigo'
  | 'purple'
  | 'pink';
  children: any;
  className?: string;
}

const Badge: FC<Props> = ({ mode, children, className }) => {
  if (mode === 'dark')
    return <span className={`bg-gray-100 text-gray-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-gray-700 dark:text-gray-300 ${className}`}>{children}</span>
  if (mode === 'red')
    return <span className={`bg-red-100 text-red-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-red-200 dark:text-red-900 ${className}`}>{children}</span>
  if (mode === 'green')
    return <span className={`bg-green-100 text-green-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-green-200 dark:text-green-900 ${className}`}>{children}</span>
  if (mode === 'yellow')
    return <span className={`bg-yellow-100 text-yellow-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-yellow-200 dark:text-yellow-900 ${className}`}>{children}</span>
  if (mode === 'indigo')
    return <span className={`bg-indigo-100 text-indigo-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-indigo-200 dark:text-indigo-900 ${className}`}>{children}</span>
  if (mode === 'purple')
    return <span className={`bg-purple-100 text-purple-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-purple-200 dark:text-purple-900 ${className}`}>{children}</span>
  if (mode === 'pink')
    return <span className={`bg-pink-100 text-pink-800 font-bold
      px-3 py-1 text-xs rounded-full dark:bg-pink-200 dark:text-pink-900 ${className}`}>{children}</span>

  return <span className={`bg-white text-blue-800 font-bold
    px-3 py-1 text-xs rounded-full dark:bg-white dark:text-blue-800 ${className}`}>{children}</span>
}

export default Badge;