
import { useState, useEffect } from 'react';

const Item = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb-4 rounded-lg bg-gray-50 shadow-xl p-4 w-full cursor-pointer" onClick={() => setOpen(!open)}>
      <p className="font-bold">{question}</p>
        
      <div className={`${open ? 'h-auto mt-4' : 'h-0 mt-0'} overflow-hidden transition-all duration-400`}>
        {answer}
      </div> 
    </div>
  )
}

export default Item;