import * as THREE from "three";
import Experience from "../Experience.js";
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
    }

    let lightOffset = 0;
    let pointLightOffset = 0;
    let lightColor = 0xffffff;

    /**
     * Some room need to dim the light
     */
    if ([31].includes(this.experience.roomData.combination.wall_id)) {
      lightOffset = -10;
      pointLightOffset = 5;
    }

    if (
      [17].includes(this.experience.roomData.combination.wall_id)
    ) {
      lightOffset = -10;
      pointLightOffset = 4;
    }

    if ([18].includes(this.experience.roomData.combination.wall_id)) {
      // lightOffset = 5;
      pointLightOffset = 5;
    }

    if (
      [26, 29, 10, 31, 7, 9, 10, 20, 15, 25, 3, 8].includes(this.experience.roomData.combination.wall_id)
    ) {
      lightOffset = -6;
    }

    if (
      [27].includes(this.experience.roomData.combination.wall_id)
    ) {
      lightOffset = 4;
    }

    if (
      [28].includes(this.experience.roomData.combination.wall_id)
    ) {
      lightOffset = -3;
      // pointLightOffset = -1.5;
      lightColor = 0x8735ad;
    }

    this.setAmbientLight();
    this.setSunLight();
    this.setSpotlightLeft(lightOffset, lightColor);
    this.setSpotlightRight(lightOffset, lightColor);
    this.setPointLight(pointLightOffset, lightColor);

    /**
     * Desk light need to adjust the position for different id
     */
    if (this.experience.roomData.type === 'workroom') {
      if (this.experience.roomData.combination.main_furniture_2_id === 4)
        this.setDeskLight(0.52, 1.01, -0.15);
      if (this.experience.roomData.combination.main_furniture_2_id === 2)
        this.setDeskLight(0.05, 1, -0.08);
      if (this.experience.roomData.combination.main_furniture_2_id === 5)
        this.setDeskLight(0.04, 1.03, -0.25);
      if (this.experience.roomData.combination.main_furniture_2_id === 6)
        this.setDeskLight(0.5, 1, -0.25);
      if (this.experience.roomData.combination.main_furniture_2_id === 7)
        this.setDeskLight(0.09, 0.97, -0.05);
    }

    if ([26].includes(this.experience.roomData.combination.wall_id)) {
      this.setSpaceLight();
    }

    if (this.experience.roomData.combination.wall_id === 28) {
      this.setSpaceLight2();
    }
  }

  setAmbientLight(lightColor = 0xffffff) {
    this.ambientLight = new THREE.AmbientLight(lightColor, 1); // soft white light
    this.scene.add(this.ambientLight);

    if (this.debug.active) {
      this.debugFolder
        .add(this.ambientLight, "intensity")
        .name("ambientLightIntensity")
        .min(0)
        .max(10)
        .step(0.001)
    }
  }

  setSunLight(lightColor = 0xffffff) {
    this.sunLight = new THREE.DirectionalLight(lightColor, 0.5);
    this.experience.sunLight = this.sunLight;

    this.sunLight.castShadow = true;
    this.sunLight.shadow.radius = 50;
    this.sunLight.shadow.mapSize.set(4096, 4096);
    this.sunLight.shadow.camera.far = 20;
    this.sunLight.shadow.normalBias = .05;

    this.sunLight.position.set(1, 6, 1);
    this.scene.add(this.sunLight);

    // Debug
    if (this.debug.active) {
      const helper = new THREE.DirectionalLightHelper(this.sunLight, 5, 0xffaa00);
      this.scene.add(helper);

      this.debugFolder
        .add(this.sunLight.shadow, "radius")
        .name("Sun Light castShadow")
        .min(0)
        .max(50)
        .step(0.1);

      this.debugFolder
        .add(this.sunLight, "intensity")
        .name("sunLightIntensity")
        .min(0)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "x")
        .name("sunLightX")
        .min(-5)
        .max(50)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "y")
        .name("sunLightY")
        .min(-5)
        .max(50)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "z")
        .name("sunLightZ")
        .min(-5)
        .max(50)
        .step(0.001);
    }
  }

  setSpotlightRight(lightOffset, lightColor = 0xffffff) {
    const spotLightRight = new THREE.SpotLight(
      lightColor, 10 + lightOffset, 10, 0.8, 1, 1);
    spotLightRight.position.set(5, 3, 0);

    spotLightRight.target.position.set(0, 1.2, 0);
    spotLightRight.target.updateMatrixWorld();

    spotLightRight.castShadow = true;
    spotLightRight.shadow.mapSize.width = 2048;
    spotLightRight.shadow.mapSize.height = 2048;
    spotLightRight.shadow.camera.near = 6;
    spotLightRight.shadow.camera.far = 6.8;
    spotLightRight.shadow.camera.fov = 10;
    spotLightRight.shadow.radius = 8;

    this.scene.add(spotLightRight);

    if (this.debug.active) {
      const spotLightHelper = new THREE.SpotLightHelper(spotLightRight);
      this.scene.add(spotLightHelper);

      // const helper = new THREE.CameraHelper( spotLightRight.shadow.camera );
      // this.scene.add( helper );

      this.debugFolder
        .add(spotLightRight, "intensity")
        .name("spotlight 1 Intensity")
        .min(0)
        .max(10)
        .step(0.01);
    }
  }

  setSpotlightLeft(lightOffset, lightColor = 0xffffff) {
    // color : Integer,
    // intensity : Float,
    // distance : Float,
    // angle : Radians,
    // penumbra : Float,
    // decay : Float
    const spotLightLeft = new THREE.SpotLight(
      lightColor, 10 + lightOffset, 10, 0.8, 1, 1);
    spotLightLeft.position.set(0, 3, 5);

    spotLightLeft.target.position.set(0, 1.2, 0);
    spotLightLeft.target.updateMatrixWorld();

    spotLightLeft.castShadow = true;
    spotLightLeft.shadow.mapSize.width = 2048;
    spotLightLeft.shadow.mapSize.height = 2048;
    spotLightLeft.shadow.camera.near = 6;
    spotLightLeft.shadow.camera.far = 6.8;
    spotLightLeft.shadow.camera.fov = 10;
    spotLightLeft.shadow.radius = 8;

    this.scene.add(spotLightLeft);

    if (this.debug.active) {
      const spotLightHelper = new THREE.SpotLightHelper(spotLightLeft);
      this.scene.add(spotLightHelper);

      this.debugFolder
        .add(spotLightLeft, "intensity")
        .name("spotlight 2 Intensity")
        .min(0)
        .max(10)
        .step(0.01);
    }
  }

  setPointLight(offset, lightColor = 0xffffff) {
    // Space: 0xe191ff
    // Normal: 0xffffff
    this.pointLight = new THREE.PointLight(lightColor, 1.5 + offset, 5, 2);
    this.pointLight.position.set(0.1, 2, 0.1);

    this.scene.add(this.pointLight);

    if (this.debug.active) {
      const sphereSize = 0.2;
      const pointLightHelper = new THREE.PointLightHelper(this.pointLight, sphereSize);
      this.scene.add(pointLightHelper);
    }
  }

  setDeskLight(x = 0.11, y = 1, z = -0.02) {
    // color : Integer, intensity : Float, distance : Float, angle : Radians, penumbra : Float, decay : Float
    const deskLight = new THREE.SpotLight(
      0xffe293,
      15,
      0.8,
      1.2,
      1,
      0.1
    );
    const pos = { x, y, z };
    deskLight.position.set(pos.x, pos.y, pos.z);

    deskLight.target.position.set(pos.x + 0.1, pos.y - 0.2, pos.z - 0.03);
    deskLight.target.updateMatrixWorld();

    deskLight.castShadow = true;
    deskLight.shadow.mapSize.width = 1024;
    deskLight.shadow.mapSize.height = 1024;
    deskLight.shadow.camera.near = 0;
    deskLight.shadow.camera.far = 10;
    deskLight.shadow.camera.fov = 30;

    // deskLight.target = this.resources.items.room.scene.getObjectByName("ROBOT7");

    this.scene.add(deskLight);

    if (this.debug.active) {
      const spotLightHelper = new THREE.SpotLightHelper(deskLight);
      this.scene.add(spotLightHelper);

      this.debugFolder
        .add(deskLight, "intensity")
        .name("Desk Light Intensity")
        .min(0)
        .max(20)
        .step(0.01);

      this.debugFolder
        .add(pos, "x")
        .name("Desk Light X")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.x = v;
          deskLight.position.set(pos.x, pos.y, pos.z);
        })

      this.debugFolder
        .add(pos, "y")
        .name("Desk Light Y")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.y = v;
          deskLight.position.set(pos.x, pos.y, pos.z);
        })

      this.debugFolder
        .add(pos, "z")
        .name("Desk Light Z")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.z = v;
          deskLight.position.set(pos.x, pos.y, pos.z);
        })
    }
  }

  setSpaceLight() {
    const width = 0.5;
    const height = 2;
    const intensity = 20;
    const pos = { x: -1.12, y: 0.92 - 0.3, z: 0.46 };

    if (this.experience.roomData.type === 'bedroom') {
      const rectLightLeft = new THREE.RectAreaLight(0xf06bff, intensity, width, height);
      rectLightLeft.position.set(pos.x, pos.y, pos.z);
      rectLightLeft.lookAt(pos.x + 1, pos.y - 0.1, pos.z);
      this.scene.add(rectLightLeft)
    }

    const rectLightRight = new THREE.RectAreaLight(0xf06bff, intensity, width, height);
    rectLightRight.position.set(pos.x, pos.y, pos.z - 0.93);
    rectLightRight.lookAt(pos.x + 1, pos.y - 0.1, pos.z - 0.93);
    this.scene.add(rectLightRight)
  }

  setSpaceLight2() {
    const width = 1.5;
    const height = 1;
    const intensity = 5;
    const pos = { x: -1.01, y: 1.02, z: 0.08 };

    const rectLight = new THREE.RectAreaLight(0xfff2ff, intensity, width, height);
    rectLight.position.set(pos.x, pos.y, pos.z);
    rectLight.lookAt(pos.x + 1, pos.y + 0.2, pos.z);
    this.scene.add(rectLight)

    if (this.debug.active) {
      const rectLightHelper = new RectAreaLightHelper(rectLight);
      rectLight.add(rectLightHelper);

      this.debugFolder
        .add(rectLight, "intensity")
        .name("Space Light Intensity")
        .min(0)
        .max(20)
        .step(0.01);

      this.debugFolder
        .add(pos, "x")
        .name("Space Light X")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.x = v;
          rectLight.position.set(pos.x, pos.y, pos.z);
        })

      this.debugFolder
        .add(pos, "y")
        .name("Space Light Y")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.y = v;
          rectLight.position.set(pos.x, pos.y, pos.z);
        })

      this.debugFolder
        .add(pos, "z")
        .name("Space Light Z")
        .min(-10)
        .max(10)
        .step(0.01)
        .onChange((v) => {
          pos.z = v;
          rectLight.position.set(pos.x, pos.y, pos.z);
        })

    }
  }
}

