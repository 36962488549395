import * as dat from 'lil-gui'
import * as THREE from 'three';
import Experience from "../Experience.js";

export default class Debug {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.active = window.location.hash === '#debug'

        if (this.active) {
            this.ui = new dat.GUI()
            this.gridHelper();
        }
    }

    gridHelper() {
        const size = 100;
        const divisions = 100;

        const gridHelper = new THREE.GridHelper(size, divisions);
        this.scene.add(gridHelper);
    }
}