const url = process.env.REACT_APP_API_V2_URL;

const Apiv2 = {
  authorizationHeader: (type = 'json') => {
    const header = {};
    
    const sig = localStorage.signature;
    const msg = localStorage['signature-data'];
    if (sig) header['x-eth-sig'] = sig;
    if (msg) header['x-eth-msg'] = msg;

    if (type === 'json') header['Content-Type'] = 'application/json';
    return header;
  },
  call: async (method, collection, body = null, headers) => {
    const type = typeof body === 'object' ? 'files' : 'json';

    const options = {
      method,
      headers: {
        ...Apiv2.authorizationHeader(type),
        ...headers,
      },
    };

    if (method !== 'GET' && body !== null) options.body = body;

    const response = await fetch(`${url}/${collection}`, options);
    const result = await response.json();

    if (response.status >= 400) {
      throw new Error(`${response.status}-${result.status}: ${result.message}`);
    }

    return result;
  },
  get: (collection, headers) => {
    return Apiv2.call('GET', collection, null, headers);
  },
  post: (collection, body, headers) => {
    return Apiv2.call('POST', collection, body, headers);
  },
  put: (collection, body, headers) => {
    return Apiv2.call('PUT', collection, body, headers);
  },
  delete: (collection, headers) => {
    return Apiv2.call('DELETE', collection, headers);
  },
  upload: (files) => {
    const data = new FormData();
    files.forEach((file) => data.append('files', file));
    return Apiv2.call('POST', 'upload', data);
  },
};

export default Apiv2;
