import { useState, useEffect, FC } from 'react'
import ListHeader from '../../components/store/ListHeader';
import ProductItem from '../../components/store/ProductItem'
import LootboxPopup from '../../components/store/LootboxPopup'
import Strapi from '../../services/Strapi';
import { ProductItemProps } from '../../components/store/ProductItem';
import { useAlert } from 'react-alert';
import { StoreLootboxDTO } from '../../interfaces/StoreLootboxDTO';
import PurchasesPopup from '../../components/store/PurchasesPopup';
import { useAuth } from '../../context/Auth';
import { useNavigate } from "react-router-dom";
import Web3 from 'web3';
import Apiv2 from '../../services/Apiv2';

const isoroomPfp = 'https://pbs.twimg.com/profile_images/1496399082838966274/uA1I4FJ6_400x400.jpg';

const isoroomContract = require("../../utils/contracts/Isoroom.json");
const isoTokenContract = require("../../utils/contracts/IsoToken.json");
const genesisVaultContract = require("../../utils/contracts/GenesisVault.json");

const ISOROOM_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;
const GENESIS_VAULT_ADDRESS = process.env.REACT_APP_GENESIS_VAULT_ADDRESS;

const isoescrowAddr = '0xb240b848A1Ef111f316064de35A6CEca315c11a9';

const storeProductToProductItem = (input: StoreLootboxDTO[]) => {
  const products: any[] = [];

  for (let i = 0; i < input.length; i++) {
    const element = input[i];
    const purchased = element.attributes?.store_lootbox_transactions?.data?.reduce((pv, cv) => {
      if (cv.attributes.status === 'rejected') return pv;
      return pv + parseInt(cv.attributes.qty)
    }, 0)

    const expireDate = new Date(element.attributes.expire_date);
    const today = new Date()

    if (expireDate >= today) {
      products.push({
        id: `${element.id}`,
        ...element.attributes,
        imageSrc: element.attributes.image_src,
        onClick: () => console.log('o i c'),
        imageAlt: element.attributes.name,
        seller: {
          name: 'isoroom',
          profilePic: isoroomPfp,
        },
        qty: {
          stock: element.attributes.qty - purchased,
          total: element.attributes.qty
        },
        special: element.attributes.owner_exclusive ? 'Genesis Exclusive' : null,
      });
    }
  }

  return products;
}

interface IUserStats {
  staking_count?: number;
  loobox_per_staked_room?: number;
  total_can_buy?: number;
  already_bought?: number;
  lootbox_left?: number;
  lootboxes?: Array<any>;
  summary?: Array<any>;
}

const PortalStore: FC<any> = () => {
  const navigate = useNavigate();
  const { connected, web3, signature, sigData } = useAuth();
  const alert = useAlert();
  const [products, setProducts] = useState<ProductItemProps[]>([]);
  const [largeViewGrid, setLargeViewGrid] = useState(true);
  const [popUpOpen, setPopUpOpen] = useState<ProductItemProps | null>(null);
  const [purchasesPopUpOpen, setPurchasesPopUpOpen] = useState<ProductItemProps | null>(null);
  const [purchaseStep, setPurchaseStep] = useState(1);
  const [purchaseErr, setPurchaseErr] = useState(null);
  const [purchasedId, setPurchasedId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [purchasedCount, setPurchasedCount] = useState<number>(0);
  const [stakedRoomCount, setStakedRoomCount] = useState<number>(0);

  const [userStats, setUserStats] = useState<IUserStats>({});

  const getStakedRoomCount = async (web3: Web3, address: string) => {
    const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
    const bal = await genesisVaultC.methods.balanceOf(address).call();
    setStakedRoomCount(parseInt(bal) || 0);
  }

  const preparePayment = async (web3: any, address: string, price: number) => {
    // C For contract
    const IsoTokenC = new web3.eth.Contract(isoTokenContract.abi, ISOTOKEN_ADDRESS);
    const valueInWei = price + '000000000000000000';
    const gasLimit = await IsoTokenC.methods
      .transfer(isoescrowAddr, valueInWei).estimateGas({ from: address });
    const gasPrice = await web3.eth.getGasPrice();

    const params = {
      from: address,
      gasLimit: gasLimit,
      gasPrice: Math.floor(gasPrice * 1.2),
    }

    return new Promise((res, rej) => IsoTokenC.methods
      .transfer(isoescrowAddr, valueInWei)
      .send(params)
      .on('transactionHash', (txHash: string) => {
        res(txHash)
      })
      .on('error', (error: any) => {
        rej(error);
      })
    )
  };

  const prepareEIsoPayment = async (address: string, price: number) => {
    const valueInWei = price + '000000000000000000';
    const body = {
      fromAddr: address,
      toAddr: isoescrowAddr,
      value: valueInWei
    };
    const { status, data } = await Apiv2.post('eiso', JSON.stringify(body));
    return data.txid;
  }

  const fetchProducts = async () => {
    try {
      let url = 'store-lootboxes?filters[status][$eq]=open';
      url += '&populate[store_lootbox_transactions][fields]=tx_hash,qty,status';
      const result = await Strapi.get(url) as { data: StoreLootboxDTO[] };
      let productsAttr = storeProductToProductItem(result.data);

      console.log('result', result, productsAttr)
      setProducts(productsAttr);
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  const placePurchasesOrder = async (
    web3: any,
    connected: string,
    item: ProductItemProps,
    sigData: any,
    signature: string,
    type: string,
    qty: number
  ) => {
    let result;
    try {
      const url = 'store-lootbox-transactions';
      const data = {
        sig_data: sigData,
        signature,
        store_lootbox: item.id,
        qty,
      };
      result = await Strapi.post(url, JSON.stringify(data));
      if (result.status === 'error') throw new Error(result.message);

      setPurchaseStep(2);
      let txHash = null;

      if (type === 'iso') {
        txHash = await preparePayment(web3, connected, item.price * qty);
      } else {
        txHash = await prepareEIsoPayment(connected, item.price * qty);
        txHash = 'internal::' + txHash;
      }

      setPurchaseStep(3);
      const payUrl = 'store-lootbox-transactions/pay';
      const payData = {
        "payment_str": result.data.payment_str,
        "tx": txHash,
      }
      const payResult = await Strapi.post(payUrl, JSON.stringify(payData));
      if (payResult.status === 'error') throw new Error(payResult.message);
      setPurchasedId(payResult.id);

      setPurchaseStep(4);
      // fetchProducts();
      getLootboxes();
    } catch (e: any) {
      const errorMsg = e.message || e.toString();
      setPurchaseErr(errorMsg);
      const payUrl = 'store-lootbox-transactions/cancel';
      const payData = {
        payment_str: result.data.payment_str,
        error: errorMsg
      };
      try { await Strapi.post(payUrl, JSON.stringify(payData)) }
      catch (e2: any) { }
    }
  }

  const onRedeem = async (id: string | null, name: string, redemptionAddress: string) => {
    try {
      // if (name.length === 0)
      //   throw new Error('Missing Discord name.');

      // if (id === null)
      //   throw new Error('Missing ID, please update discord name on inventory page.');

      // if (!(new Web3()).utils.isAddress(redemptionAddress))
      //   throw new Error('Wrong wallet address format.');

      // const url = 'store-lootbox-transactions/redeem';
      // const data = {
      //   id,
      //   sig_data: sigData,
      //   signature,
      //   discord_name: name,
      //   redemption_address: redemptionAddress,
      // };
      // await Strapi.post(url, JSON.stringify(data));

      navigate('/portal/inventory')
    } catch (e: any) {
      console.log(e);
      window.alert(e.message || e.toString());
    }
  }

  const getPurchasedCount = async (address: string) => {
    const url = `store-lootbox-transactions/count/purchased?wallet_address=${address}`
    const count = await Strapi.get(url);
    setPurchasedCount(count);
  }

  const getLootboxes = async () => {
    const url = `store-lootbox/get?wallet=${connected}`;
    const data = await Strapi.get(url);

    setProducts(data.lootboxes)
    setUserStats(data);
  }

  useEffect(() => {
    // fetchProducts();
    getLootboxes();
  }, []);

  useEffect(() => {
    if (web3 && connected) {
      getStakedRoomCount(web3, connected);
      getPurchasedCount(connected);
    }
  }, [web3, connected])

  const maxQty = stakedRoomCount - purchasedCount > 0 ? stakedRoomCount - purchasedCount : 0

  return (
    <>

      {
        popUpOpen !== null &&
        <LootboxPopup
          open={popUpOpen !== null}
          onClose={() => {
            setPurchaseStep(1);
            setPopUpOpen(null)
          }}
          onPay={(type: string, qty: number) => {
            setPurchasesPopUpOpen(popUpOpen);
            setPopUpOpen(null);
            if (sigData && signature && popUpOpen)
              placePurchasesOrder(web3, connected || '', popUpOpen, sigData, signature, type, qty);
          }}
          name={popUpOpen?.name || '-'}
          price={popUpOpen?.price || 0}
          token='ISO'
          description={popUpOpen?.description || '-'}
          imageSrc={popUpOpen?.imageSrc || ''}
          imageAlt={popUpOpen?.imageAlt || '-'}
          twitter={popUpOpen?.twitter || '-'}
          highlights={[
            `Mint day: ${popUpOpen?.mint_date || 'TBC'}`,
            `Discord: ${popUpOpen?.discord || 'Please open ticket'}`,
          ]}
          maxQty={userStats.lootbox_left || 0}
        />
      }

      {
        purchasesPopUpOpen !== null &&
        <PurchasesPopup
          address={connected || ''}
          open={purchasesPopUpOpen !== null}
          onClose={() => setPurchasesPopUpOpen(null)}
          currentStep={purchaseStep}
          error={purchaseErr}
          onRedeemClick={async (name, redemptionAddress) =>
            await onRedeem(purchasedId, name, redemptionAddress)}
        />
      }

      <div>
        <main className="max-w-7xl mx-auto">
          <div
            className="rounded-lg"
            style={{
              background: `url('https://images.unsplash.com/photo-1636109751387-e5e2434268e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80') center center / cover`,
              height: 150,
              width: '100%',
              position: 'relative',
              marginBottom: 10,
            }}
          />

          <div className="mt-4">
            <ListHeader
              onShowAll={(v) => setShowAll(v)}
              header="isogiftroom"
              setMobileFiltersOpen={() => { }}
              onViewGridChange={() => setLargeViewGrid(!largeViewGrid)}
            />
            <section aria-labelledby="products-heading" className="pt-3 pb-24">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <p className="p-1 bg-slate-200 rounded-md font-bold text-sm mb-3 ml-1 inline-block">
                You have {userStats.staking_count}{" "}
                staking Genesis rooms, {userStats.lootbox_left} quotas left.
              </p>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                {/* Product grid */}
                <div className="lg:col-span-4">
                  <div className={`grid grid-cols-${largeViewGrid ? '1' : '2'}
                  sm:grid-cols-${largeViewGrid ? '2' : '4'}
                  lg:grid-cols-${largeViewGrid ? '3' : '6'}`}>
                    {
                      products.map(p => {
                        return (
                          <ProductItem
                            key={p.id}
                            qty={p.qty}
                            onClick={() => {
                              setPurchaseStep(1);
                              setPurchaseErr(null);
                              setPopUpOpen(p);
                            }}
                            id={p.id}
                            imageSrc={p.imageSrc}
                            imageAlt={p.imageAlt}
                            name={p.name}
                            price={p.price}
                            token="iso"
                            category="Lootbox"
                            seller={p.seller}
                            description={p.description}
                            special={p.special}
                            disabled={userStats.lootbox_left == 0}
                          />
                        )
                      }
                      )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
}

export default PortalStore;