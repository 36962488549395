import { FC } from 'react'

interface OwningProps {
  isoroomBalance: any;
  utilityIsoroomBalance: any;
} 

const Owning:FC<OwningProps> = ({ isoroomBalance, utilityIsoroomBalance }) => {
  return (
    <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
      <p className="text-lg font-semibold text-slate-900">🏠 You have</p>
      <p className="text-2xl font-medium text-slate-700 mt-2">
        <p><b>{!isoroomBalance ? 'Loading...' : `${isoroomBalance} genesis`}</b></p>
        <p><b>{!utilityIsoroomBalance ? 'Loading...' : `${utilityIsoroomBalance} utility`}</b></p>
      </p>
      <p className="text-sm font-medium text-slate-700 mt-2">
        isoroom{isoroomBalance > 1 ? 's' : ''} in your wallet
      </p>
    </div>
  )
}

export default Owning;