import Nav from '../components/Nav';
import Faq from '../components/Faq/';
import Footer from '../components/Footer';

const FaqPage = () => {
  return (
    <div>
      <Nav stayTop />
      <section id="faq"><Faq /></section>
      <Footer />
    </div>
  )
}

export default FaqPage;