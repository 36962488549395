import { useRef, useEffect, ReactNode } from 'react';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

interface ProfileShortcutMemuModalProps {
  /**
   * Should memu show
   */
  show: boolean;
  /**
   * Close menu on clicking outside
   */
  onClose: () => void;
  /**
   * User's wallet address
   */
  address: string;
  /**
   * children
   */
  children: ReactNode;
}

/**
 * Show user's token balance and extend to profile menu
 */
const ProfileShortcutMemuModal = ({
  show,
  onClose,
  address,
  children,
}: ProfileShortcutMemuModalProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target))
        onClose()
    }

    if (!show) return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, wrapperRef, onClose]);

  return (
    <div
      ref={wrapperRef}
      aria-live="assertive"
      className="inset-0 flex items-end py-4 pointer-events-none sm:items-start"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-2">
              <div className="p-1 flex flex-row items-center 
              hover:bg-gray-50 active:bg-gray-100
              transition duration-150 ease-in-out
              ">
                <div className="ml-2">
                  <p className="font-bold">{address?.substring(0, 10)}...</p>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default ProfileShortcutMemuModal;