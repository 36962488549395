import { FC } from 'react';
import { useAuth } from '../../../context/Auth';

interface EIsoBalanceProps {
  fullLoaded: boolean;
  eIsoBal: any;
}

const EIsoBalance:FC<EIsoBalanceProps> = ({ fullLoaded, eIsoBal }) => {

  const ctx = useAuth();
  const web3: any = ctx.web3;


  return (
    <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
      <p className="text-lg font-semibold text-slate-900 flex items-center">
        💱 Your $eISO balance
      </p>

      <p className="text-2xl font-semibold text-slate-700 mt-2">
        {!fullLoaded ?
          <div className="animate-pulse flex space-x-4">Loading...</div>
          : Number.parseFloat(web3?.utils.fromWei(eIsoBal)).toFixed(0) || 0
        }
      </p>
      <p className="text-sm font-medium text-slate-700 mt-4">
        <b>(Previously Gift Token)</b> $eISO can be used on the portal with no gas fee. Bridging you $ISO and $eISO on the <b>Bridge</b> page.
      </p>
    </div>
  )
}

export default EIsoBalance;

