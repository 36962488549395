
import { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import Markdown from '../../Markdown';
import Select from 'react-select';
import ModalWrapper from '../../general/ModalWrapper';

interface ProductPopupProps {
  open: boolean;
  onClose: () => void;
  onPay: (type: string, qty: number) => void;

  name: string;
  price: number;
  token: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  highlights: string[];
  twitter: string;
  maxQty: number;
}

/**
 * A template storybook
 */
const ProductPopup = ({
  open,
  onClose,
  onPay,
  name,
  price,
  token,
  description,
  imageSrc,
  imageAlt,
  highlights,
  twitter,
  maxQty,
}: ProductPopupProps) => {
  const [qty, setQty] = useState<any>({ label: 1, value: 1 });

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
        <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
          <button
            type="button"
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
            <div className="sm:col-span-4 lg:col-span-5">
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img src={imageSrc} alt={imageAlt} className="object-center object-cover" />
              </div>
            </div>
            <div className="sm:col-span-8 lg:col-span-7">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{name}</h2>

              <section aria-labelledby="information-heading" className="mt-3">
                <h3 id="information-heading" className="sr-only">
                  Product information
                </h3>

                <div className="mt-6">
                  <h4 className="mb-4">Description</h4>
                  <Markdown>{description}</Markdown>
                </div>

                <div className="mt-6">
                  <h4>Price</h4>
                  <p className="font-bold mt-2">{price} $ISO</p>
                </div>

                {
                  <div className="mt-4">
                    <Select
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      value={qty}
                      placeholder="Qty"
                      onChange={(v) => setQty(v)}
                      options={new Array(maxQty).fill(1).map((_, i) => ({ value: _ + i, label: _ + i }))}
                    />
                    <span className="text-xs font-bold">* You can buy 1 per staking room</span>
                  </div>
                }
              </section>

              <section aria-labelledby="options-heading" className="mt-6">
                <div className="mt-6 grid grid-cols-2 gap-2">
                  <button
                    onClick={() => onPay('iso', qty.value)}
                    className="bg-gray-200 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-bold text-gray-900 hover:bg-gray-300
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500"
                  >
                    Pay {qty.value * price} {token}
                  </button>
                  <button
                    onClick={() => onPay('eiso', qty.value)}
                    className="bg-orange-200 border-2 border-amber-600 rounded-md py-3 px-8 flex items-center justify-center text-base font-bold text-white hover:bg-orange-700
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500"
                  >
                    Pay {qty.value * price} eISO
                  </button>
                </div>

                {/* <div className="mt-6 text-center">
                  <a href="#" className="group inline-flex text-base font-medium">
                    <ShieldCheckIcon
                      className="flex-shrink-0 mr-2 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="text-gray-500 hover:text-gray-700">Secured by isoroom</span>
                  </a>
                </div> */}
              </section>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ProductPopup;