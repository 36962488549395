import { LinkIcon } from '@heroicons/react/outline'
import { FC } from 'react';
import styled from 'styled-components';
import { useLocation, Link } from "react-router-dom";
import ProfileShortcut from '../store/ProfileShortcut';
import { useAuth } from '../../context/Auth';
import Badge from '../Badge';

const Container = styled.div`
  // flex-basis: 15vw;
  // min-width: 300px;
  // max-width: 400px;
`

export interface NavigationProps {
  name: string;
  icon: string;
  href: string;
  onClick?: () => void;
  target?: string;
  beta?: boolean;
  roles?: string[];
  notification?: number;
}

interface NavWrapProps {
  children: React.ReactNode;
  target: string;
  href: string;
  current: boolean;
  onClick?: () => void;
}

const aClassName = (current: boolean) => classNames(
  current
    ? 'bg-gray-100 text-gray-900 hover:text-gray-900 hover:bg-gray-100'
    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
  'group flex items-center px-4 py-3 text-sm font-medium rounded-md'
)

const NavWrap = ({ children, target, href, current, onClick = undefined }: NavWrapProps) => {
  if (target == '_blank' || onClick) {
    return (
      <a
        href={onClick ? '#' : href}
        onClick={onClick ? onClick : () => { }}
        className={aClassName(current)}
        target={target}
      >
        {children}
      </a>)
  } else {
    return (
      <Link to={href}>
        <a className={aClassName(current)}>
          {children}
        </a>
      </Link>
    )
  }
}

const NavButton = (props: any) => {
  return (
    <NavWrap
      onClick={props.onClick}
      href={props.href}
      target={props.target}
      current={props.current}
    >
      <span className="text-xl mr-2">{props.icon}</span>
      <span className="flex-1">{props.name}</span>
      {props.count ? (
        <span
          className={classNames(
            props.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
            'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
          )}
        >
          {props.count}
        </span>
      ) : null}
      {
        props.target &&
        <LinkIcon className="w-4 h-4 opacity-70" aria-hidden="true" />
      }
      {
        props.beta &&
        <Badge mode="green">Beta</Badge>
      }
      {
        props.notification > 0 &&
        <Badge mode="red">{props.notification}</Badge>
      }
    </NavWrap >
  )
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  onDisconnect: () => void;
  navigation: NavigationProps[];
}

const getRoles = (address: string) => {
  const role = [];

  if ([
    '0xf3B10029aB079153a55c41a360074ffa78671677',
    '0x03452F4148058ED6A92B7E9F41Bbd58ee02C2C3F',
    '0x51609dc51eE0b7c8eDE96C13b3006e1D92336f83',
    '0x7c9B0337F79DdAdbf8b225259adfAeDe30DcA3C1',
    '0x02a4F5c54a0122e0Cba017702D1026103a2603f7',

    '0xf2e88b3730d97f549e35Daee082c4cF2c327E82C'
  ].includes(address)) role.push('admin');

  return role;
}

const isInclusive = (arr1: any = [], arr2: any = []) =>
  arr1.some((r: any) => arr2.includes(r));

const Sidebar: FC<Props> = ({ onDisconnect, navigation }) => {
  const location = useLocation();
  const { connected, tokenBalance } = useAuth();
  const role = getRoles(connected || '');

  return (
    <Container
      className="flex-col min-h-0 bg-white pl-3 py-3 flex justify-between h-full"
    >
      <div className="flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <img
            className="h-12 w-auto mb-4"
            src="/image/isoroom-h-logo.png"
            alt="Workflow"
          />
        </div>
        <nav className="mt-5 px-2 bg-white space-y-1" aria-label="Sidebar">
          {navigation.map((item) => {
            if (item.roles && !isInclusive(role, item.roles))
              return;

            return (
              <NavButton
                {...item}
                current={location.pathname === item.href}
              />
            )
          })}
        </nav>
        <div className="border-t border-gray-200 pt-4 mt-4">
          <nav className="px-2 bg-white space-y-1" aria-label="Sidebar">
            <NavButton
              onClick={onDisconnect}
              name="Disconnect"
              icon="❌"
              href="#"
              count={null}
              current={false}
            />
          </nav>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center m-2 px-2 py-3 rounded-xl bg-slate-100">
        <ProfileShortcut
          className="w-full"
          address={connected}
          balance={tokenBalance}
        />
      </div>
    </Container>
  )
}

export default Sidebar;