import { FC, useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/Auth';
import Dropdown, { ItemOpt } from '../../components/Dropdown';
import { ArrowCircleDownIcon } from '@heroicons/react/outline';
import Button from '../../components/Button';
import { prepareTransfer } from '../../utils';
import { useAlert } from 'react-alert';
import Apiv2 from '../../services/Apiv2';
import SwapPopup from '../../components/bridge/SwapPopup';
import HistoryTable from '../../components/bridge/HistoryTable';
import gIsoControllerContract from '../../utils/contracts/GIsoController.json';
import Checkbox from '../../components/Checkbox';

const GISO_CONTROLLER_ADDRESS = process.env.REACT_APP_GISO_CONTROLLER_ADDRESS;
const dp = '000000000000000000';

const tokenList: ItemOpt[] = [
  { name: 'ISO', key: process.env.REACT_APP_ISOTOKEN_ADDRESS || '' },
  { name: 'eISO', key: process.env.REACT_APP_ADDRESS_ISOESCROW || '' },
]

const PortalBridge: FC<any> = () => {
  const alert = useAlert();
  const { connected, web3 } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sendToken, setSendToken] = useState(tokenList[0]);
  const [receiveToken, setReceiveToken] = useState(tokenList[1]);
  const [sendValue, setSendValue] = useState(0);
  const [step, setStep] = useState(0);
  const [history, setHistory] = useState([]);
  const [confirmationNo, setConfirmationNo] = useState(0);
  const [tnc, setTnc] = useState(false);
  // const [receiveValue, setReceiveValue] = useState(0);

  const resetState = () => {
    setTnc(false);
    setStep(0);
    setLoading(false);
    setConfirmationNo(0);
    fetchBridgeHistory();
  }

  const setToken = (type: string, opt: ItemOpt) => {
    const oppositOpt = tokenList
      .find(item => item.key !== opt.key) || tokenList[0];
    if (type === 'send') {
      setSendToken(opt);
      setReceiveToken(oppositOpt);
    } else {
      setReceiveToken(opt);
      setSendToken(oppositOpt);
    }
  }

  const onError = (e: any) => {
    setLoading(false);
    setStep(0);
    alert.error(e.message || e.toString());
  };

  const onTransactionHash = async (txhash: string, value: string, fromToken: string, toToken: string) => {
    try {
      const body = JSON.stringify({ txhash, value, fromToken, toToken });
      await Apiv2.post('eiso/bridge', body);
      setStep(2);
    } catch (e: any) {
      onError(e);
    }
  }

  const onConfirmation = async (confirmationNumber: number, receipt: any) => {
    const confirmRequirement = 12;
    if (confirmationNumber >= confirmRequirement + 1) return;
    const txhash = receipt.transactionHash;

    try {
      setConfirmationNo(confirmationNumber);
      if (confirmationNumber < confirmRequirement) {
        setStep(3);
        return;
      }

      await Apiv2.get(`eiso/bridge/verify/${txhash}`);
      setStep(4);
      alert.success('Transaction completed.');
      setLoading(false);
    } catch (e: any) {
      onError(e);
    }
  }

  const onSwapIsoToEIso = async (
    web3: any,
    connected: any,
    value: number,
    fromToken: string,
    toToken: string
  ) => {
    try {
      setLoading(true);
      setStep(1);
      const valueInWei = `${value}${dp}`;
      await prepareTransfer(web3, connected, 'escrow', valueInWei,
        {
          transactionHash: (txhash: string) =>
            onTransactionHash(txhash, valueInWei, fromToken, toToken),
          error: onError,
          confirmation: onConfirmation,
        });
    } catch (e: any) {
      onError(e);
    }
  }

  const onSwapEIsoToIso = async (
    web3: any,
    connected: any,
    txid: string,
    message: string,
    v: string,
    r: string,
    s: string
  ) => {
    try {
      const gIsoController = new web3.eth.Contract(
        gIsoControllerContract.abi, GISO_CONTROLLER_ADDRESS);
      const confirmRequirement = 2;

      gIsoController.methods
        .withdrawIso(message, v, r, s)
        .send({ from: connected })
        .on('transactionHash', async function (txhash: string) {
          try {
            setStep(2);
            await Apiv2.put(`eiso/bridge/${txid}`, JSON.stringify({ txhash }));
          } catch (e: any) { onError(e) }
        })
        .on('confirmation', async function (confirmationNumber: number, receipt: any) {
          try {
            if (confirmationNumber >= confirmRequirement + 1) return;
            setConfirmationNo(confirmationNumber);

            if (confirmationNumber === confirmRequirement) {
              await Apiv2.get(`eiso/bridge/verify/${receipt.transactionHash}`);
              setStep(4);
              alert.success('Transaction completed.');
              setLoading(false);
            } else {
              setStep(3);
              console.log('on confirmation', confirmationNumber, receipt);
            }
          } catch (e) { onError(e) }
        })
        .on('error', onError);
    } catch (e: any) {
      onError(e);
    }
  }

  const onSwap = useCallback(async (value: number, fromToken: string, toToken: string) => {
    if (!connected || !web3) throw new Error('Missing address');

    if (fromToken === process.env.REACT_APP_ISOTOKEN_ADDRESS) {
      onSwapIsoToEIso(web3, connected, value, fromToken, toToken);
    } else {
      setLoading(true);
      setStep(1);
      const valueInWei = `${value}${dp}`;
      const body = JSON.stringify({ value: valueInWei, fromToken, toToken });
      const result = await Apiv2.post('eiso/bridge', body);
      const { txid, message, v, r, s } = result.data;
      onSwapEIsoToIso(web3, connected, txid, message, v, r, s);
    }
  }, [web3, connected]);

  const fetchBridgeHistory = async () => {
    try {
      const result = await Apiv2.get('eiso/bridge');
      setHistory(result.data);
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  const onPendingClick = useCallback(async (tx: any) => {
    if (tx.txhash) {
      setStep(3);
      await Apiv2.get(`eiso/bridge/verify/${tx.transactionHash}`);
      setStep(4);
      alert.success('Transaction completed.');
      setLoading(false);
      return;
    }

    /**
     * If got sign but not redeem
     */
    if (tx.fromToken === process.env.REACT_APP_ADDRESS_ISOESCROW) {
      setStep(1);
      onSwapEIsoToIso(web3, connected,
        tx.id, tx.meta.message, tx.meta.v, tx.meta.r, tx.meta.s)
    }
  }, [web3, connected]);

  useEffect(() => {
    fetchBridgeHistory();
  }, []);

  const buttonActive = sendValue > 0;

  return (
    <>
      <SwapPopup
        open={step > 0}
        onClose={resetState}
        confirmationNo={confirmationNo}
        currentStep={step}
        error=""
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Escrow bridge</h1>
          <p className="mt-1 text-sm text-gray-500">
            You can bridge your $ISO token to $eISO with our isoescrow.
          </p>

          <div className="mt-8 p-4 bg-white rounded-lg shadow-md">
            <p className="font-bold text-gray-900 mb-2">Swap</p>
            <div className="flex flex-row items-center">
              <Dropdown
                className="w-32"
                current={sendToken}
                onClick={(opt) => setToken('send', opt)}
                items={tokenList}
              />
              <input
                className="ml-2 p-2 font-bold bg-slate-200 rounded-lg text-xl w-full"
                placeholder="0"
                type="number"
                onChange={(e) => setSendValue(parseFloat(e.target.value))}
              />
            </div>
            <ArrowCircleDownIcon
              className="w-6 my-2 mx-auto cursor-pointer hover:bg-slate-200 rounded-full transition-all duration-100"
              onClick={() => setToken('send', tokenList.find(i => i.key !== sendToken.key) || tokenList[0])}
            />
            <div className="flex flex-row items-center">
              <Dropdown
                className="w-32"
                current={receiveToken}
                onClick={(opt) => setToken('receive', opt)}
                items={tokenList}
              />
              <input
                className="ml-2 p-2 font-bold bg-slate-200 rounded-lg text-xl w-full"
                placeholder="0"
                type="number"
                value={sendValue}
                disabled
              // onChange={(e) => setReceiveValue(parseFloat(e.target.value))}
              />
            </div>

            <Checkbox
              checked={tnc}
              className="my-4 ml-1"
              onChange={(e) => setTnc(e.target.checked)}
              text="I understand once the transaction is started it cannot be reverted."
            />
            <Button
              orange
              className="w-full mt-2 mb-0"
              disabled={!buttonActive || !tnc}
              loading={loading}
              onClick={() => onSwap(sendValue, sendToken.key, receiveToken.key)}
            >Swap</Button>
          </div>
          {/* - Fee of 2% applies when swapping from $eISO to $ISO */}
        </div>

        <HistoryTable
          transactions={history}
          onPendingClick={onPendingClick}
        />
      </div>
    </>
  );
}

export default PortalBridge;
