import { FC } from 'react';
import Select from 'react-select';

interface StakingProps {
  stakingIds: Array<any>;
  utilityStakingIds: Array<any>;
  toUnStakeIds: Array<any>;
  loading: boolean;
  setToUnStakeIds: (value: string) => void;
  onUnStake: () => void;
}

const Staking:FC<StakingProps> = ({ stakingIds, utilityStakingIds, toUnStakeIds, loading, setToUnStakeIds, onUnStake }) => {
  return (
    <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
      <p className="text-lg font-semibold text-slate-900">🏦 You are staking</p>
      <p className="text-sm font-medium text-slate-700 mt-2">
        Genesis isoroom:<br />
        {stakingIds.length > 0 ? stakingIds.map(id => `#${id}`).join(', ') : '-'}
      </p>

      <p className="text-sm font-medium text-slate-700 mt-2">
        Utility isoroom:<br />
        {utilityStakingIds.length > 0 ? utilityStakingIds.map(id => `#${id}`).join(', ') : '-'}
      </p>

      {
        (stakingIds.length > 0 || utilityStakingIds.length > 0) &&
        <div className="mt-4">
          <Select
            isMulti
            value={toUnStakeIds}
            placeholder="isoroom To Unstake"
            onChange={(v: any) => setToUnStakeIds(v)}
            options={[
              ...stakingIds.map(i => ({ value: i, label: `g-${i}`, type: 'genesis-vault' })),
              ...utilityStakingIds.map(i => ({ value: i, label: `u-${i}`, type: 'isoroom-vault' })),
            ]}
          />
          <button
            disabled={loading}
            onClick={onUnStake}
            className="mt-2 h-8 px-6 font-semibold rounded-md bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white">
            {loading ? 'Loading...' : 'Unstake'}
          </button>
        </div>
      }
      {/* <p className="text-xs font-bold mt-4">Unstake stopped, please contact us for unstaking.</p> */}
    </div>
  )
}

export default Staking;