import { useRef, useState } from "react";
import { useClickOutside } from '../../utils/hooks/helper';
import MetamaskButton from '../../components/MetamaskButton';
import { useAuth } from '../../context/Auth';
import { NavbarStyled } from "./styled";
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
  const navigate = useNavigate();
  const { connected, connectWallet, disconnect, balanceOf } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const cname = "dropdown__content--active";
  const aboutMenuRef = useRef();
  const dropdownContentRef = useRef();

  useClickOutside(aboutMenuRef, resetStyles)

  function resetStyles() {
    dropdownContentRef.current.classList.remove(cname)
    setDropdownOpen(false)
    return;
  }

  function onNavbarItemClicked() {

    if (dropdownOpen) {
      setDropdownOpen(false);
      resetStyles();
      return;
    }

    setDropdownOpen(true)
    dropdownContentRef.current.classList.add(cname)
  }

  return (
    <NavbarStyled>
      <section className="navbar px-2 md:px-4">
        <div className="logo">
          <img
            className="logo__iso"
            src="/image/isoworld_logo_w.png"
            alt="iso-logo" />
        </div>

        <div className="flex flex-row justify-center items-center">
          <div className="navbar__menu">
            <a href="https://isoroom.gitbook.io/isoworld/" target="_blank">
              <button
                className="navbar__button"
                type="button">
                Whitepaper
              </button>
            </a>
          </div>

          {
            !connected ?
              <MetamaskButton
                address={connected}
                onClick={async () => {
                  if (connected) return disconnect();
                  await connectWallet();
                  navigate(`/portal`);
                }}
              />
              :
              <button
                onClick={() => navigate(`/portal`)}
                className="
                  ml-2 text-black
                  text-md font-bold shadow-sm px-8 py-2 rounded-full bg-white
                  hover:bg-amber-100 hover:shadow-md
                  active:bg-amber-300
                  transaction-all duration-200
                  "
              >
                <span className="">🚪 Portal</span>
              </button>
          }
        </div>
      </section>
    </NavbarStyled>
  )
}

export default Navbar;