import { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import Item from './Item';

const Introducing = () => {
  return (
    <div className="pt-24 m-auto gradient-bg">
      <div className="max-w-screen-xl m-auto">
        <Parallax className="custom-class" y={[-80, 10]} tagOuter="figure">
          <h2 className="font-bold text-4xl tracking-wider h2 text-white">
            QUESTIONS?
          </h2>
        </Parallax>

        <div className="-mt-32 px-2 py-24 max-w-screen-lg m-auto">
          <h4 className="text-white font-bold text-2xl mb-2"># Project Background</h4>
          {
            [
              {
                question: 'Q: What is isoroom?',
                answer: 'A: isorooms are the isometric rooms in the blockchain world where you can showcase your favourite NFT collections in your virtual house. '
              },
              {
                question: 'Q: Why do we make isoroom?',
                answer: 'A: We all love NFT! We love the art, we love the culture, and of course it\'s the community that we love! Surrounded by a group of NFT investors and enthusiasts, we discover that there is a common need among them, which is to show and share their favourite NFT collections in a more stylish and interactive way. That\'s how isoroom is born! We are here to sincerely invite you to join our awesome community, so as to invite your friends to visit your isoroom for your greatest NFT collections!'
              },
              {
                question: 'Q: What is the vision and roadmap of isoroom?',
                answer: 'A: We will first launch our Genesis Rooms, then we will launch a few more batches including Toilets, Kitchens, Bedrooms and Special Rooms! All the isorooms are capped 10,000. After all the interior isorooms are sold, we will launch our isohouse Exterior Design, such as houses, buildings, hotels or even rockets! When we have successfully built a strong and big community, we are planning to build a NFT metaverse where our isoroom and isohouse owners can build and trade NFTs. It sounds exciting, isn\'t it!'
              },
              {
                question: 'Q: What will mint funds be used for?',
                answer: 'A: In order to provide the best value and design to our owners, we will put a large portion of mint funds to bring the top talents to our team. At the same time, we may also reserve a part of it as our marketing budget so that we could invite more and more members to join our community!'
              },
            ].map((item, i) => (
              <Item
                key={`1-${i}`}
                question={item.question}
                answer={item.answer}
              />
            ))
          }

          <h4 className="text-white font-bold text-2xl mb-2"># Product</h4>
          {
            [
              {
                question: 'Q: Where can I buy isoroom?',
                answer: 'A: All primary sales of my NFTs can be minted on [https://www.isoroom.io/](https://www.drawtism.io/) which is a secured website. You can link your Metamask wallet to store you collectables. For secondary market, isorooms are available on OpenSea.io. You can get isorooms that are for immediate sale or place an offer on any isoroom or bid on active auctions.',
              },
              {
                question: 'Q: What blockchain is isoroom on?',
                answer: 'A: isorooms are based on the Ethereum blockchain.',
              },
              {
                question: 'Q: What is the maximum number of isoroom available?',
                answer: 'A: For now there are a total of 3,000 Genesis isorooms that yield $ISO. isorooms are capped at 10,000.',
              },
              {
                question: 'Q: How many different types of isorooms are there?',
                answer: 'A: There are in total 6 different types of isorooms, which are Living Room, Work Room, Toilets, Kitchens, Bedrooms and Special Rooms. There are 10+ attributes for each type of isorooms, and there are 20+ elements for each attributes.',
              },
              {
                question: 'Q: What is $ISO and what is it used for?',
                answer: 'A: $ISO is an ERC-20 token that is being used for various tasks inside the isoroom ecosystem. It can be used to exchange for a new isoroom.',
              },
              {
                question: 'Q: Where can I get $ISO?',
                answer: 'A: $ISO could be earned by owning one of the cool isorooms. isoroom owners are able to earn $ISO on daily basis like a passive income.',
              },
              {
                question: 'Q: How do I stay up to date about isoroom?',
                answer: 'A: This Discord server is the right place for you. Moreover, you can follow our official Twitter channel and be the first to showcase your NFT collections in your isoroom!',
              },
              {
                question: 'Q: What benefits do we get from owning an isoroom?',
                answer: 'First of all, our isoroom is the perfect place for you to showcase your favourite NFT collections to your friends. It feels like inviting them to visit you at your warm sweet home. Another reason would be that you could earn $ISO when you own an isoroom. With $ISO, you could exchange for more cool rooms for FREE, and it could also be used as a currency in the isoworld!',
              },
            ].map((item, i) => (
              <Item
                key={`2-${i}`}
                question={item.question}
                answer={item.answer}
              />
            ))
          }
          
          <h4 className="text-white font-bold text-2xl mb-2"># General Questions</h4>
          {
            [
              {
                question: 'Q: What is an NFT?',
                answer: 'A: Non-Fungible Tokens are unique, easily verifiable digital assets that can represent items such as images, videos, music and more. Anything that exists online can be purchased as an NFT, theoretically. An NFT is “a type of cryptographic token”, but NFTs are different from cryptocurrencies because they’re not interchangeable. Think of Pokémon cards: You can trade them, but a Geodude is not the same as a holographic Charizard etc. Also, NFTs live on a blockchain, they are easy to track. This tracking allows for verification of their ownerships as well as their past history and owners.',
              },
              {
                question: 'Q: **I missed the sale of isoroom and now they\'re all sold, how can I get one?**',
                answer: 'A: You may wait for another batch of isoroom. If all batches of isorooms are claimed, we suggest that you could visit NFT marketplaces like [https://opensea.io/](https://opensea.io/) and keep your eye out for anyone relisting them, at whatever price they wish.',
              }
            ].map((item, i) => (
              <Item
                key={`3-${i}`}
                question={item.question}
                answer={item.answer}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Introducing;