import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';
import InputGroup from '../../InputGroup';

interface PurchasesPopupProps {
  address: string;
  open: boolean;
  onClose: () => void;
  currentStep: number;
  error: string | null;
  onRedeemClick: (name: string, address: string) => Promise<void>;
}

const steps = [
  { name: 'Placing Purchase Order', step: 1 },
  { name: 'Verifying transaction', step: 2 },
  { name: 'Order Processing', step: 3 },
  { name: 'Purchased! Input your discord name to redeem now!', step: 4 },
]

/**
 * A template storybook
 */
const PurchasesPopup = ({
  address,
  open,
  onClose,
  currentStep,
  error,
  onRedeemClick,
}: PurchasesPopupProps) => {
  const [name, setName] = useState('');
  const [redemptionAddress, setRedemptionAddress] = useState(address);
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setName('');
    setLoading(false);
  }

  const _onClose = () => {
    if (!window.confirm('Confirm to stop the transaction?')) return;
    onClose();
  }

  const _onRedeemClick = async (name: string, redemptionAddress: string) => {
    try {
      setLoading(true);
      await onRedeemClick(name, redemptionAddress)
    }
    catch(e) {}
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentStep === 1) resetState();
  }, [currentStep]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={_onClose}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => _onClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="w-full">
                  <ol role="list" className="space-y-6">
                    {steps.map((step) => (
                      <li key={step.name}>
                        {step.step < currentStep ? (
                          <span className="flex items-start">
                            <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                              <CheckCircleIcon
                                className="h-full w-full text-orange-600 group-hover:text-orange-800"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-3 font-bold text-slate-900 group-hover:text-gray-900">
                              {step.name}
                            </span>
                          </span>
                        ) : step.step === currentStep ? (
                          <span className="flex items-start">
                            <span className="animate-ping flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                              <span className="absolute h-4 w-4 rounded-full bg-orange-200" />
                              <span className="relative block w-2 h-2 bg-orange-600 rounded-full" />
                            </span>
                            <span className="ml-3 font-bold text-orange-600">{step.name}</span>
                          </span>
                        ) : (
                          <div className="flex items-start">
                            <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                              <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                            </div>
                            <p className="ml-3 text-gray-500 group-hover:text-gray-900">{step.name}</p>
                          </div>
                        )}
                      </li>
                    ))}
                  </ol>

                  {
                    currentStep === steps.length &&
                    <div className="mt-4">
                      <InputGroup
                        rows={[
                          {
                            id: 'purchases-popup-discord-name',
                            name: 'Discord Name',
                            onChange: (e) => setName(e.target.value),
                          },
                          {
                            id: 'purchases-popup-wallet-address',
                            name: 'Wallet Address',
                            onChange: (e) => setRedemptionAddress(e.target.value),
                            defaultValue: address,
                          },
                        ]}
                      />

                      <p className="my-4 text-sm font-bold text-center">
                        Please input a new discord ID and wallet address for each additional whitelist purchased.
                      </p>

                      <Button
                        loading={loading}
                        orange
                        style={{ width: '100%', marginTop: 5, marginBottom: 0 }}
                        onClick={() => _onRedeemClick(name, redemptionAddress)}
                      >Redeem now!</Button>
                    </div>
                  }
                  {
                    error &&
                    <p className={`text-center font-bold text-md
                        mt-8 ${error ? 'text-red-800' : ''}`}>{error}</p>
                  }
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PurchasesPopup;