/**
 * 
 * @param {*} param0
 */

const signTypedDataV4 = async ({ web3, message, address }) => {
  const msgParams = JSON.stringify(message);

  var params = [address, msgParams];
  var method = 'eth_signTypedData_v4';

  const data =  {
    method,
    params,
    from: address,
  };

  return new Promise((res, rej) => {
    web3.currentProvider.sendAsync(data,
      function (err, result) {
        if (err) return rej(err);
        if (result.error) return rej(err);
  
        res({
          data: JSON.parse(msgParams),
          sig: result.result,
        })
      }
    );
  })
}

export default signTypedDataV4;