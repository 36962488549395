import { BigNumber } from 'ethers';
import qs from 'qs';
import { useEffect, useState, useCallback } from 'react';
import { useAlert } from 'react-alert';
import dayjs from 'dayjs';
import Select from 'react-select';
import { useAuth } from '../../context/Auth';
import Apiv2 from '../../services/Apiv2';
import Strapi from '../../services/Strapi';
import { getHashProofByTokenId, getTierByTokenId } from '../../utils/scoreTree';
import GiftHistoryModal from './GiftHistoryModal';
import GiftModal from './GiftModal';
import { useNavigate } from "react-router-dom";
import Markdown from '../../components/Markdown';
import Gift from './widgets/Gift';
import IsoBalance from './widgets/IsoBalance';
import EIsoBalance from './widgets/EIsoBalance';
import Owning from './widgets/Owning';
import RoyalMinting from './widgets/RoyalMinting';
import StakeUtility from './widgets/StakeUtility';
import Staking from './widgets/Staking';
import Claimable from './widgets/Claimable';
import Announcement from './widgets/Announcement';
import Web3Contract from '../../services/Contract';

const isoroomContract = require("../../utils/contracts/Isoroom.json");
const isoTokenContract = require("../../utils/contracts/IsoToken.json");
const genesisVaultContract = require("../../utils/contracts/GenesisVault.json");
const utilityIsoroomContract = require("../../utils/contracts/UtilityIsoroom.json");
const isoroomVaultContract = require("../../utils/contracts/IsoroomVault.json");
const royalIsoroomContract = require("../../utils/contracts/RoyalIsoroom.json");

const ISOROOM_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;
const GENESIS_VAULT_ADDRESS = process.env.REACT_APP_GENESIS_VAULT_ADDRESS;
const UTILITY_ISOROOM_ADDRESS = process.env.REACT_APP_UTILITY_ISOROOM;
const ISOROOM_VAULT_ADDRESS = process.env.REACT_APP_ISOROOM_VAULT;
const ROYAL_ISOROOM_ADDRESS = process.env.REACT_APP_ROYAL_ISOROOM;

const decimals = BigNumber.from(10).pow(18);
const giftBatch = 'gift-20220507';

const PortalDashboard = ({ }) => {
  const alert = useAlert();
  let { connected, web3, signature, sigData } = useAuth();
  
  const [fullLoaded, setFullLoaded] = useState(false);
  const [loading, setLoading] = useState(null);
  const [isoBalance, setIsoBalance] = useState(null);
  const [isoroomBalance, setIsoroomBalance] = useState(null);
  const [utilityIsoroomBalance, setUtilityIsoroomBalance] = useState(null);
  const [tokenIds, setTokenIds] = useState([]);
  const [claimable, setClaimable] = useState([]);
  const [stakingIds, setStakingIds] = useState([]);
  const [utilityStakingIds, setUtilityStakingIds] = useState([]);
  const [toStakeIds, setToStakeIds] = useState([]);
  const [toUnStakeIds, setToUnStakeIds] = useState([]);
  const [approvedStaking, setApprovedStaking] = useState(false);
  const [earning, setEarning] = useState(false);
  const [giftModal, setGiftModal] = useState(false);
  const [giftHistoryModal, setGiftHistoryModal] = useState(false);
  const [claimedGift, setClaimedGift] = useState([]);
  const [eIsoBal, setEIsoBal] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const [isoroomVaultStakingIds, setIsoroomVaultStakingIds] = useState('');
  const [royalEligibility, setRoyalEligibility] = useState({ })

  const resetState = () => {
    setTokenIds([]);
    setClaimable([]);
    setStakingIds([]);
    setToStakeIds([]);
    setToUnStakeIds([]);
    setFullLoaded(false);
    setUtilityIsoroomBalance([]);
    setUtilityStakingIds([]);
    setIsoroomVaultStakingIds('');
  }

  const checkClimedGift = async (address, page = 0) => {
    try {
      const limit = 100;
      const query = qs.stringify({
        filters: {
          $or: [
            { winner_address: { $eq: address.toLocaleLowerCase() } },
            { address: { $eq: address.toLocaleLowerCase() } },
          ]
        },
        pagination: {
          start: page * limit,
          limit,
        },
        sort: ['id:desc']
      },
        {
          encodeValuesOnly: true,
        })

      const url = `claiming-results?${query}`;
      const result = await Strapi.get(url);
      return result.data;
    } catch (e) {
      alert.error(e.toString());
    }
  }

  const loadMoreGift = async (address, loadedGiftLen) => {
    try {
      if (loadedGiftLen % 100 !== 0) return;
      const nextPage = Math.floor(loadedGiftLen / 100);
      const items = await checkClimedGift(address, nextPage);
      setClaimedGift(ori => [...ori, ...items]);
    } catch (e) {
      alert.error(e.toString());
    }
  }

  const fetchProfile = async (_address, web3) => {
    resetState();

    // C for contract
    const isoroomC = new web3.eth.Contract(isoroomContract.abi, ISOROOM_CONTRACT_ADDRESS);
    const isoTokenC = new web3.eth.Contract(isoTokenContract.abi, ISOTOKEN_ADDRESS);
    const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
    const utilityIsoroomC = new web3.eth.Contract(utilityIsoroomContract.abi, UTILITY_ISOROOM_ADDRESS);
    const isoroomVaultC = new web3.eth.Contract(isoroomVaultContract.abi, ISOROOM_VAULT_ADDRESS);

    /**
     * Check all claimed gift for giftiso use
     */
    const allClaimedGift = await checkClimedGift(_address);
    setClaimedGift(allClaimedGift);

    /**
     * Get claimable token
     */
    const eIsoResponse = await await Apiv2.get('eiso');
    setEIsoBal(eIsoResponse.data);

    /**
     * Get isotoken balance
     */
    const _isoBalnace = await isoTokenC.methods.balanceOf(_address).call();
    setIsoBalance(_isoBalnace);

    /**
     * Get isoroom balance
     */
    const _balanceOf = await isoroomC.methods.balanceOf(_address).call();
    setIsoroomBalance(_balanceOf);

    /**
     * Get utility isoroom balance
     */
    const _uIsoroomBal = await utilityIsoroomC.methods.balanceOf(_address).call();
    setUtilityIsoroomBalance(_uIsoroomBal);

    /**
     * Check staking contract approved
     */
    const _approved = await isoroomC.methods.isApprovedForAll(_address, GENESIS_VAULT_ADDRESS).call();
    setApprovedStaking(_approved)

    /**
     * Genesis staking information
     */
    const stakingIds = await genesisVaultC.methods.tokensOfOwner(_address).call();
    setStakingIds(stakingIds);

    /**
     * Utility staking information
     */
    const uStakingIds = await isoroomVaultC.methods
      .tokensOfOwner(UTILITY_ISOROOM_ADDRESS, 6000, _address).call();
    setUtilityStakingIds(uStakingIds);

    /**
     * Claimable token (gIso)
     */
    const _earning = await genesisVaultC.methods.earningInfo(stakingIds).call();
    setEarning(_earning);

    /**
     * Get ids and check airdrop
     */
    let ids = [];
    for (let i = 0; i < _balanceOf; i++) {
      let tokenId = await isoroomC.methods
        .tokenOfOwnerByIndex(_address, i).call();
      setTokenIds(ori => [...ori, tokenId]);
      ids.push(tokenId);

      const claimed = await genesisVaultC.methods.airdropClaimed(ids[i]).call();
      if (!claimed) setClaimable(ori => [...ori, ids[i]]);
    }

    setFullLoaded(true);
  }

  const addTokenToMetamask = async () => {
    const tokenAddress = ISOTOKEN_ADDRESS;
    const tokenSymbol = 'ISO';
    const tokenDecimals = 18;
    const tokenImage = 'https://isoroom.io/logo-06.png';

    try {
      if (!window.ethereum) throw new Error("Only support desktop Metamask.");
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      alert.success('$ISO added to your Metamask');
    } catch (e) {
      alert.error(e.toString());
    }
  }

  const claimAirdrop = async (_ids, walletAddr, web3) => {
    try {
      const ids = _ids.filter(i => i > 10);
      setLoading(true);
      const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
      const tier = ids.map(id => getTierByTokenId(id));
      const tierProof = ids.map(id => getHashProofByTokenId(id));

      // const gasEstimate = await genesisVaultC.methods
      //     .claimAirdrop(ids, tier, tierProof).estimateGas({ from: walletAddr.toLowerCase() });

      await genesisVaultC.methods
        .claimAirdrop(ids, tier, tierProof)
        .send({
          from: walletAddr,
          // gas: Math.round(gasEstimate * 1.2),
        });

      alert.success('Airdrop claimed!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const approveStakingContract = async (walletAddr, web3) => {
    try {
      setLoading(true);
      const isoroomC = new web3.eth.Contract(isoroomContract.abi, ISOROOM_CONTRACT_ADDRESS);
      // const gasEstimate = await isoroomC.methods
      //     .setApprovalForAll(GENESIS_VAULT_ADDRESS, true)
      //     .estimateGas({ from: walletAddr });

      await isoroomC.methods
        .setApprovalForAll(GENESIS_VAULT_ADDRESS, true)
        .send({
          from: walletAddr,
          // gas: Math.round(gasEstimate * 1.2),
        });

      alert.success('Contract Approved!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const onStake = async (_ids, walletAddr, web3) => {
    try {
      setLoading(true);
      if (_ids.length === 0) throw new Error('Please select isoroom before staking');
      const ids = _ids.map(i => i.value);
      const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
      const tier = ids.map(id => getTierByTokenId(id));
      const tierProof = ids.map(id => getHashProofByTokenId(id));

      // const gasEstimate = await genesisVaultC.methods
      //     .stake(ids, tier, tierProof).estimateGas({ from: walletAddr });

      await genesisVaultC.methods
        .stake(ids, tier, tierProof)
        .send({
          from: walletAddr,
          // gas: Math.round(gasEstimate * 1.2),
        });

      alert.success('isoroom Staked!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const onIsoroomVaultStake = async (idsStr, walletAddr, web3) => {
    try {
      setLoading(true);
      const idArr = (idsStr.replace(/ +/g, '_').split(',')).map(i => parseInt(i));
      const isoroomVaultC = new web3.eth.Contract(isoroomVaultContract.abi, ISOROOM_VAULT_ADDRESS);

      console.log(UTILITY_ISOROOM_ADDRESS, idArr);

      await isoroomVaultC.methods
        .stake(UTILITY_ISOROOM_ADDRESS, idArr)
        .send({ from: walletAddr });

      alert.success('isoroom Unstaked!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const onUnStake = async (_ids, walletAddr, web3) => {
    try {
      setLoading(true);
      if (_ids.length === 0) throw new Error('Please select isoroom before unstake');

      let type = null;
      for (let i = 0; i < _ids.length; i++) {
        if (type !== null && type !== _ids[i].type)
          throw new Error('Utility isoroom and Genesis isoroom need to be unstaked in two transaction');
        type = _ids[i].type
      }
      const ids = _ids.map(i => i.value);

      if (type === 'genesis-vault') {
        const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
        await genesisVaultC.methods
          .unstake(ids)
          .send({ from: walletAddr });
      } else {
        const isoroomVaultC = new web3.eth.Contract(isoroomVaultContract.abi, ISOROOM_VAULT_ADDRESS);
        await isoroomVaultC.methods
          .unstake(UTILITY_ISOROOM_ADDRESS, ids)
          .send({ from: walletAddr });
      }

      alert.success('isoroom Unstaked!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const onClaim = async (ids, walletAddr, web3) => {
    try {
      setLoading(true);
      const genesisVaultC = new web3.eth.Contract(genesisVaultContract.abi, GENESIS_VAULT_ADDRESS);
      // const gasEstimate = await genesisVaultC.methods
      //     .claim(ids).estimateGas({ from: walletAddr });

      await genesisVaultC.methods
        .claim(ids)
        .send({
          from: walletAddr,
          // gas: Math.round(gasEstimate * 1.2),
        });

      alert.success('Token Claimed!');
      fetchProfile(walletAddr, web3);
    } catch (e) {
      alert.error('Wallet error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  const fetchAnnouncement = async () => {
    try {
      const result = await Strapi.get('announcements?sort=id:desc');
      const data = result.data.map(i => ({ id: i.id, ...i.attributes }));
      setAnnouncements(data);
    } catch (e) {
      alert.error(e.message || e.toString());
    }
  }

  const fetchRoyalElgibility = async(address) => {
    const result = await Strapi.get(`isorooms/royal/eligibility/${address}`);
    setRoyalEligibility(result);
  }

  const onRoyalRoomMint = useCallback(async(qty) => {
    try {
      setLoading(true);
      const body = { data: { signature, sigData } }
      const  { message, r, s, v } = await Strapi.post(`isorooms/royal/sig`, JSON.stringify(body));

      const royalIsoroomC = new web3.eth.Contract(royalIsoroomContract.abi, ROYAL_ISOROOM_ADDRESS);
      await royalIsoroomC.methods.mint(message, v, r, s, qty).send({ from: connected });
      
      alert.success('Successfully minted! Please find your royal isoroom on Opensea profile!')
    } catch(e) {
      alert.error(e.message || e.toString());
    } finally {
      setLoading(false);
    }
  }, [signature, sigData, web3, connected]);


  useEffect(() => {
    fetchAnnouncement();
    if (connected == null || !web3) return
    fetchProfile(connected, web3);
    fetchRoyalElgibility(connected);
  }, [connected, web3]);

  return (
    <div>
      <GiftModal
        address={connected}
        open={giftModal}
        onClose={() => setGiftModal(false)}
        roomIds={stakingIds || []}
        batchId={giftBatch}
        claimable={true}
      />
      
      <GiftHistoryModal
        open={giftHistoryModal}
        onClose={() => setGiftHistoryModal(false)}
        history={claimedGift}
        loadMore={() => loadMoreGift(connected, claimedGift.length)}
      />

      <div
        className="rounded-lg"
        style={{
          background: `url('/banner01.png') center center / cover`,
          height: 150,
          width: '100%',
          position: 'relative',
          marginBottom: 10,
        }}
      />

      <div className="">
        <div className="py-2 px-1 pt-2">
          <h3 className="text-xs font-bold text-blue-800">Hi, {connected?.substring(0, 20)}...</h3>
          <h2 className="text-2xl font-bold">welcome to isoportal</h2>

          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">

            <Announcement announcements={announcements} />

            <RoyalMinting
              {...royalEligibility}
              loading={loading}
              onMint={onRoyalRoomMint}
            />

            <IsoBalance
              isoBalance={isoBalance}
              decimals={decimals}
              addTokenToMetamask={addTokenToMetamask} />

            <EIsoBalance 
              fullLoaded={fullLoaded}
              eIsoBal={eIsoBal} />

            <Owning 
              utilityIsoroomBalance={utilityIsoroomBalance}
              isoroomBalance={isoroomBalance} />

            {/* Airdrop or Stake */}
            {
              // claimable.length > 0 ?
              //   <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
              //     <p className="text-lg font-semibold text-slate-900">🎉 Airdrop claimable rooms</p>
              //     <p className="text-sm">You can get 4000 to 8000 airdrop base on isoroom's rarity! You must claim your airdrop before staking to prevent repeated reward calculation.</p>
              //     <p className="text-sm font-medium text-slate-700 mt-2">{claimable.map(id => `#${id}`).join(', ')}</p>
              //     <button
              //       disabled={loading}
              //       onClick={() => claimAirdrop(claimable, connected, web3)}
              //       className="mt-2 h-8 px-6 font-semibold rounded-md bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white">
              //       {loading ? 'Loading...' : 'Claim Airdrop Now!'}</button>
              //   </div>
              //   :
              //   !approvedStaking ?
              //     <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
              //       <p className="text-lg font-semibold text-slate-900">Stake your isoroom</p>
              //       <p className="text-sm">Just like Opensea, we need your approval to allow our staking contract interaction with your isoroom so that the cotract can receive and return your isoroom on your request. You need to pay ONE TIME gas fee for approving the staking contract forever. You may also revert the approval anytime.</p>
              //       <button
              //         disabled={loading}
              //         onClick={() => !approvedStaking ? approveStakingContract(connected, web3) : onStake(toStakeIds, connected, web3)}
              //         className="mt-2 h-8 px-6 font-semibold rounded-md bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white">
              //         {loading ? 'Loading...' : !approvedStaking ? 'Approve Staking Contract' : 'Stake Now!'}
              //       </button>
              //     </div>
              //     :
              //     <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px">
              //       <p className="text-lg font-semibold text-slate-900">#️ Stake Genesis isoroom ($ISO)</p>
              //       <p className="text-sm font-medium text-slate-700 mt-2">{tokenIds.map(id => `#${id}`).join(', ')}</p>
              //       {
              //         tokenIds.length > 0 ?
              //           <div className="mt-4">
              //             <Select
              //               isMulti
              //               value={toStakeIds}
              //               placeholder="isoroom To Stake"
              //               onChange={(v) => setToStakeIds(v)}
              //               options={tokenIds.map(i => ({ value: i, label: i }))}
              //             />
              //             <button
              //               disabled={loading}
              //               onClick={() => !approvedStaking ? approveStakingContract(connected, web3) : onStake(toStakeIds, connected, web3)}
              //               className="mt-2 h-8 px-6 font-semibold rounded-md bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white">
              //               {loading ? 'Loading...' : !approvedStaking ? 'Approve Staking Contract' : 'Stake Now!'}
              //             </button>
              //             {
              //               claimable.length > 0 && <p className="text-red-900 mt-2 font-bold break-all">Please claim airdrop before staking otherwise you will get an error</p>
              //             }
              //           </div>
              //           : <p>{!loading ? "You don't have non staking Genesis isoroom." : ''}</p>
              //       }
              //     </div>
            }

            {/* <StakeUtility
              loading={loading}
              handleChange={setIsoroomVaultStakingIds}
              onStakeClick={() => onIsoroomVaultStake(isoroomVaultStakingIds, connected, web3)}
            /> */}

            <Staking
              loading={loading}
              utilityStakingIds={utilityStakingIds}
              toUnStakeIds={toUnStakeIds}
              stakingIds={stakingIds}
              setToUnStakeIds={setToUnStakeIds}
              onUnStake={() => onUnStake(toUnStakeIds, connected, web3)}
            />

            {/* <Claimable 
              loading={loading}
              decimals={decimals}
              earning={earning}
              stakingIds={stakingIds}
              onClaim={() => onClaim(stakingIds, connected, web3)}
            /> */}

          </div>
        </div>
      </div>
    </div>

  )
}

export default PortalDashboard;