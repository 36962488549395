import React, { lazy, Suspense } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import SceneContextWrapper from './context/Scene';
import { AuthProvider } from './context/Auth';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertTemplate';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Customise from './pages/Customise';
import { Analytics, PortalLayout } from './pages/Portal';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Landing from './pages/Landing';
import { ThemeProvider } from 'styled-components';
import { ISOtheme } from './styles/styled/theme';
import { Parallax } from './components/Parallax';

// pages for code-spltting
const Dashboard = lazy(() => import('./pages/Portal/Dashboard'))
const Bridge = lazy(() => import('./pages/Portal/Bridge'))
const GiftRoom = lazy(() => import('./pages/Portal/GiftRoom'))
const Store = lazy(() => import('./pages/Portal/Store'))
const Inventory = lazy(() => import('./pages/Portal/Inventory'))
const Announcement = lazy(() => import('./pages/Portal/Announcement'))
const SupportPage = lazy(() => import('./pages/Support'))
const RefundPage = lazy(() => import('./pages/Support/Refund'))
const UserInventoryPage = lazy(() => import('./pages/Support/Inventory'))
const TransactionPage = lazy(() => import('./pages/Support/Transaction'))
const UserPage = lazy(() => import('./pages/Support/User'))
const BridgeVerify = lazy(() => import('./pages/Support/BridgeVerify'))
const Game = lazy(() => import('./pages/Portal/Game'))

// you can also just use 'bottom center'
const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 50000,
  offset: '30px',
  transition: transitions.FADE,
  containerStyle: { background: 'white' }
}

const theme = ISOtheme.light;
const getFallbackBlock = () => {
  return "Loading..."
}

function App() {
  return (
    <ParallaxProvider>
      <React.StrictMode>
        <SceneContextWrapper>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <AuthProvider>
              <BrowserRouter>
                <Suspense fallback={getFallbackBlock()}>
                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/customise" element={<Customise />} />
                    <Route path="/analytics" element={<Analytics />} />

                    <Route path="support" element={<SupportPage />}>
                      <Route index path="bridge/verify" element={<BridgeVerify />} />
                      <Route path="refund" element={<RefundPage />} />
                      <Route path="user" element={<UserPage />} />
                      <Route path="inventory" element={<UserInventoryPage />} />
                      <Route path="transactions" element={<TransactionPage />} />
                    </Route>

                    <Route path="portal" element={<PortalLayout />} >
                      <Route index element={<Dashboard />} />
                      <Route path="store" element={<Store />} />
                      <Route path="inventory" element={<Inventory />} />
                      <Route path="gift-room" element={<GiftRoom />} />
                      <Route path="bridge" element={<Bridge />} />
                      <Route path="announcement" element={<Announcement />} />
                      <Route path="game" element={<Game />} />
                    </Route>
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </AuthProvider>
          </AlertProvider>
        </SceneContextWrapper>
      </React.StrictMode>
    </ParallaxProvider>
  );
}

export default App;
