import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import ModalWrapper from '../components/general/ModalWrapper';

const buttonClassName = `
relative w-full mb-3 text-left
text-black font-semibold py-2 px-4 border border-transparent
rounded-xl 

focus:ring-2 focus:ring-offset-2
focus:ring-offset-white focus:ring-orange-900 focus:outline-none
hover:border-orange-700
transition duration-150 ease-in-out

flex justify-between items-center`

const ConnectionModal = ({ open, onClose, onSelectWallet }) => {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white p-4">
            <div className="text-left w-full">
              <img
                className="absolute right-4 top-4 hover:opacity-50 transition duration-150 ease-in-out cursor-pointer"
                width="20"
                src="/close-svgrepo-com.svg"
                onClick={onClose}
              />
              <h3 className="text-sm font-bold leading-6 text-gray-900" id="modal-title">
                Connect a wallet
              </h3>
              <div className="mt-2">
                <button onClick={() => onSelectWallet('metamask')} className={buttonClassName}>
                  Metamask
                  <img style={{ maxHeight: 25 }} src='/wallet_logo/metamask.png' />
                </button>
                <button onClick={() => onSelectWallet('wallet-connect')} className={buttonClassName}>
                  Wallet Connect
                  <img height="30" src='/wallet_logo/wallet_connect.svg' />
                </button>
                <button onClick={() => onSelectWallet('wallet-link')} className={buttonClassName}>
                  Coinbase Wallet
                  <img height="30" src='/wallet_logo/coinbase.svg' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConnectionModal;