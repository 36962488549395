import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import PortalContainer from '../../sections/PortalContainer';

interface LayoutProps {}

const PortalLayout:FC<LayoutProps> = () => {
  return (
    <PortalContainer>
      <Outlet />
    </PortalContainer>
  )
}

export default PortalLayout;