import WalletLink from 'walletlink'
import Web3 from "web3"

const APP_NAME = 'isoroom'
const APP_LOGO_URL = 'https://lh3.googleusercontent.com/XtZQp0bMmchll0Ftp1VFzusSW4UvngFqpuhkVOFw6BUPttEo3tooRIMh44AzcLk4RvAf3lnQ_fyPUjVckzmCfzoMZeJBJTaZzwdHpAA=s130'
const ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/aeb99452b96e4b70b846b6796eb2bef5' // isoroom.nft@gmail.com
const CHAIN_ID = 1

// Initialize WalletLink
export const connectWalletLink = async () => {
  console.log('start connect wallet link');

  const walletLink = new WalletLink({
    appName: APP_NAME,
    appLogoUrl: APP_LOGO_URL,
    darkMode: false
  });

  const ethereum = walletLink.makeWeb3Provider(ETH_JSONRPC_URL, CHAIN_ID)
  const web3 = new Web3(ethereum)

  // Use eth_RequestAccounts
  const accounts = await ethereum.send('eth_requestAccounts');
  return { accounts, web3 };
}