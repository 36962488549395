import { useState, useCallback, useRef } from 'react';
import Experience from '../../isoroom/Experience';
import Container from './Container';
import Wallet from './Wallet';
import ReactTooltip from 'react-tooltip';

let experience;

const TopBanner = () => {
    const canvasRef = useRef(null);
    const [roomId, setRoomId] = useState(null);

    const selectImg = useCallback((imgSrc, frame) => {
        experience.changeFrame(imgSrc, frame);
    }, [experience]);

    const saveFile = (strData, filename) => {
        const link = document.createElement("a");
        if (typeof link.download === "string") {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild(link); //remove the link when done
        }
    };

    const captureAndShare = () => {
        var imgData;
        try {
            const strDownloadMime = "image/octet-stream";
            var strMime = "image/jpeg";
            imgData = experience.renderer.instance.domElement.toDataURL(strMime);
            saveFile(imgData.replace(strMime, strDownloadMime), "isoroom.jpg");
        } catch (e) {
            console.log(e);
            return;
        }
    }

    const selectRoom = (roomTarget) => {
        setRoomId(roomTarget);
        experience = new Experience(canvasRef.current, {}, roomTarget);
    }

    return (
        <Container className="">
            <div>
                <div className="bg-gray-300 w-full relative banner-img flex justify-center">
                    <canvas
                        ref={canvasRef}
                        className="webgl"
                    />
                    {
                        roomId !== null &&
                        <div className="absolute bottom-10 flex justify-center items-between">
                            <button
                                onClick={() => window.location.href = '/'}
                                className="
                                hidden md:block
                                z-50 text-md font-bold shadow-sm ml-1 px-8 py-2 rounded-full bg-white
                                hover:bg-amber-100 hover:shadow-md
                                active:bg-amber-300
                                transaction-all duration-200
                                "
                            >
                                <span className="">🔙 Back</span>
                            </button>
                            <button
                                onClick={captureAndShare}
                                className="
                                hidden md:block
                                z-50 text-md font-bold shadow-sm ml-1 px-8 py-2 rounded-full bg-white
                                hover:bg-amber-100 hover:shadow-md
                                active:bg-amber-300
                                transaction-all duration-200
                                "
                            >
                                <span className="">📷 Capture</span>
                            </button>
                            <ReactTooltip />
                            <button
                                data-tip="Coming soon!"
                                disabled
                                className="
                                cursor-not-allowed hidden md:block
                                z-50 text-md font-bold shadow-sm ml-1 px-8 py-2 rounded-full bg-white
                                hover:bg-amber-100 hover:shadow-md
                                active:bg-amber-300
                                transaction-all duration-200
                                "
                            >
                                <span className="">⛵ Show on Opensea (Coming Soon)</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className="wallet-container transition-all duration-1000 ease-in-out" style={{ maxHeight: '100vh', overflow: 'scroll', right: 0 }}>
                <Wallet
                    roomId={roomId}
                    selectImg={selectImg}
                    selectRoom={selectRoom}
                />
            </div>
        </Container>
    )
}

export default TopBanner;