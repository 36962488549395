/* This example requires Tailwind CSS v2.0+ */
import { FC, ReactElement, useState } from 'react'
import { Switch } from '@headlessui/react'

interface Props {
  defaultValue?: boolean;
  onChange: (v: boolean) => void;
  label: ReactElement<any>;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const ToggleButton: FC<Props> = ({ defaultValue = false, onChange, label }) => {
  const [enabled, setEnabled] = useState(defaultValue)

  const _onChnage = (v: boolean) => {
    onChange(v);
    setEnabled(v);
  }

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={_onChnage}
        className={classNames(
          enabled ? 'bg-orange-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 cursor-pointer">
        {label}
      </Switch.Label>
    </Switch.Group>
  )
}

export default ToggleButton;