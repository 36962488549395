import React from 'react';
import { FaClipboardList, FaHouseDamage, FaImage } from 'react-icons/fa';
import { ISOtheme } from '../../styles/styled/theme';

export const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function useDefineAssets(type) {
  switch(type) {
    case 'whitelist':
      return {
        color: ISOtheme.getColor('blue'),
        icon: FaClipboardList
      };
    case 'nft': 
      return {
        color: ISOtheme.getColor('orange'),
        icon: FaImage
      }
    case 'house':
      return {
        color: ISOtheme.getColor('teal'),
        icon: FaHouseDamage
      }
    default:
      return {
        color: '',
        icon: null
      }
  } 
}