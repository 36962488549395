import { FC } from 'react';

interface Props {
  onChange: (e: any) => void;
  text: string;
  hints?: string;
  className?: string;
  checked?: boolean;
  textClassName?: string;
}

const Checkbox: FC<Props> = ({ onChange, text, hints, className, checked, textClassName = '' }) => {
  return (
    <fieldset className={`space-y-5 ${className}`}>
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            checked={checked}
            onChange={onChange}
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm flex flex-col justify-start items-start">
          <label htmlFor="comments" className={`text-slate-900 font-bold select-none ${textClassName}`}>
            {text}
          </label>
          <span id="comments-description ml-2" className="text-slate-500">
            {hints}
          </span>
        </div>
      </div>
    </fieldset>
  )
}

export default Checkbox;