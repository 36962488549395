import * as THREE from "three";
import Experience from "./Experience.js";

import { BloomEffect, EffectComposer, EffectPass, RenderPass, HueSaturationEffect } from "postprocessing";

export default class Renderer {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("renderer");
    }

    this.usePostprocess = true; // Can't with node post

    this.setInstance();

    if (this.usePostprocess) {
      this.setPostProcess();
    }
  }

  setInstance() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
      
      // Post processing introduces the concept of passes and effects to extend the
      // common rendering workflow with fullscreen image manipulation tools. The 
      // following WebGL attributes should be used for an optimal post processing workflow:
      powerPreference: "high-performance",
      stencil: false,
      depth: false 
    });
    this.instance.physicallyCorrectLights = true;
    this.instance.outputEncoding = THREE.sRGBEncoding;
    this.instance.toneMapping = THREE.NoToneMapping;
    this.instance.toneMappingExposure = 1;
    this.instance.shadowMap.enabled = true;
    this.instance.shadowMap.type = THREE.VSMShadowMap;
    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));

    this.instance.setClearColor( 0x9e9d9b, 1 );

    window.cam = this.camera.instance;
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
  }

  update() {
    if (this.usePostprocess) {
      this.postProcess.composer.render();
    } else {
      this.instance.render(this.scene, this.camera.instance);
    }
  }

  setPostProcess() {
    this.postProcess = {};

    const effectComposerOpt = {
      multisampling: 2,
    }
    this.postProcess.composer = new EffectComposer(this.instance, effectComposerOpt);
    this.postProcess.composer.addPass(new RenderPass(this.scene, this.camera.instance));
    this.postProcess.composer.addPass(new EffectPass(this.camera, new BloomEffect({
      intensity: 0.15
    })));

    // const hueSaturationOpt = {
    //   hue: 0.07,
    //   saturation: -0.12
    // }
    // let hueSaturationEffect = new HueSaturationEffect(hueSaturationOpt)
    // let hueSaturationEffectPass = new EffectPass(this.camera, hueSaturationEffect)
    // this.postProcess.composer.addPass(hueSaturationEffectPass);

  }
}
