import React, { FC, useEffect, useState } from 'react'
import { Link, Outlet, useNavigate, NavLink } from 'react-router-dom';
import { useAuth } from '../../context/Auth';
import Strapi from '../../services/Strapi';
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import ConnectSection from '../../components/ConnectSection';
import './style.css';

interface IMod {
  id: number;
  wallet_address: string;
  username: string;
}

const ModPage: FC<any> = (props) => {

  const [mods, setMods] = useState<Array<IMod>>([]);
  const [mod, setMod] = useState<IMod>();
  let { connected, connectWallet, disconnect, signature } = useAuth();
  const navigate = useNavigate();

  async function getHasAccessUsers() {
    const res = await Strapi.get('mod-page-accesses');

    const users = res.data.map((user: any) => ({
      id: user.id,
      wallet_address: user.attributes.wallet_address,
      username: user.attributes.username
    }))

    const mod = users.find((user: IMod) => user.wallet_address == connected);

    if (!mod)
      return navigate(-1)

    setMods(users);
    setMod(mod);
    navigate('/support/bridge/verify')
  }

  useEffect(() => {
    getHasAccessUsers()
  }, [connected])

  if (!signature) {
    return <ConnectSection
      loading={false}
      connected={connected}
      disconnect={disconnect}
      connectWallet={connectWallet}
    />
  }

  return (
    <section className="min-h-screen h-fit bg-[#f1f5f9]">

      <div className="grid grid-cols-12 shadow-lg shadow-neutral-200 bg-white px-5">
        <div className="col-span-2">
          <div className="flex items-center py-3">
            <img
              style={{ width: "150px" }}
              src="/image/isoroom-h-logo.png" alt="logo" />
            <p
              style={{ marginTop: "-1.5px" }}
              className="bg-white -ml-6 font-bold text-lg">
              | support
            </p>
          </div>
        </div>

        <div className="col-span-4">
          <div className="grid grid-cols-12 h-full">
            <div className="col-span-3 overflow-hidden relative">
              <NavLink
                className="relative"
                to="/support/bridge/verify">
                <button
                  className="h-full w-full flex items-center justify-center"
                  type="button">
                  <p className="font-semibold">Bridge Verify</p>
                </button>
              </NavLink>
            </div>

            <div className="col-span-3 overflow-hidden relative">
              <NavLink
                className="relative"
                to="/support/user">
                <button
                  className="h-full w-full flex items-center justify-center"
                  type="button">
                  <p className="font-semibold">User</p>
                </button>
              </NavLink>
            </div>

            <div className="col-span-3 overflow-hidden relative">
              {/* <NavLink
                className="support-navlink cursor-not-allowed opacity-25"
                to="/support/refund"> */}
              <button
                className="h-full w-full flex items-center justify-center cursor-not-allowed opacity-25"
                type="button">
                <p className="font-semibold">Refund</p>
              </button>
              {/* </NavLink> */}
            </div>
          </div>
        </div>

        <div className="col-span-6 flex justify-end items-center">
          <div className="w-[300px] py-3 flex items-center justify-between">
            <p className="font-bold text-sm">Hello, {mod?.username}!</p>
            <Link to="/portal">
              <p className="text-orange-500 font-semibold text-sm">Return to Portal</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="grid grid-cols-12 mt-5 gap-5">
          <div className="col-span-9">
            <Outlet />
          </div>

          <div className="col-span-3">
            <div className="p-5 rounded-lg bg-white shadow-md shadow-neutral-300">
              <p className="font-bold text-slate-800 mb-2">Has access: {mods.length}</p>
              {mods.map((mod: IMod) => (
                <div
                  key={mod.id}
                  className="grid grid-cols-12 last:mb-0 mb-2 gap-3">
                  <div className="col-span-4">
                    <p className="text-white uppercase text-sm font-bold bg-orange-500 rounded-xl px-2 w-fit">{mod.username}</p>
                  </div>
                  <div className="col-span-8 flex items-center">
                    <p className="text-xs truncate">{mod.wallet_address}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default ModPage;