import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
// import ModelContainer from './ModelContainer';
// import OneModel from "./One";
// import ThreeModel from "./Three";
// import TwoModel from "./Two";

function Box(props) {
  // This reference will give us direct access to the THREE.Mesh object
  const ref = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x += 0.01));
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? "hotpink" : "orange"} />
    </mesh>
  );
}

const Combination = () => {
  return (
    <div className="py-24 px-4">
      <div className="max-w-4xl m-auto">
        <h3 className="text-orange-900 text-3xl font-bold tracking-wider">
          WHO ARE WE BEHIND ISOROOM?
        </h3>
        <p className="text-gray-800 mt-8 max-w-lg">
          GM! We are a team of designers and developers who are passionate in
          NFTs. We strongly believe in creating values and culture in the NFT
          world. This is the start of a huge cultural change in history. And we
          would like to be part of it to push the human race forward.
        </p>

        <div className="mt-2 px-2 py-0 grid grid-cols-9 gap-8">
          <div className="col-span-9 md:col-span-3 flex flex-col justify-center">
            {/* <ModelContainer>
              <OneModel />
            </ModelContainer> */}

            <div className="mt-5">
              <b>isoroom.sam</b>
              <br />
              The Architect
              <br />
              <a href="https://twitter.com/iso_sam">@iso_sam</a>
            </div>
          </div>
          <div className="col-span-9 md:col-span-3 flex flex-col justify-center">
            {/* <ModelContainer>
              <TwoModel />
            </ModelContainer> */}

            <div className="mt-5">
              <b>isoroom.stan</b>
              <br />
              Blockchain Expert
              <br />
              <a href="https://twitter.com/stanxxxc">@stanxxxc</a>
            </div>
          </div>
          <div className="col-span-9 md:col-span-3 flex flex-col justify-center">
            {/* <ModelContainer>
              <ThreeModel />
            </ModelContainer> */}

            <div className="mt-5">
              <b>isoroom.nd</b>
              <br />
              The Marketing Spirit
              <br />
              <a href="https://twitter.com/isoandy_">@isoandy_</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Combination;
