import dayjs from 'dayjs';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import ConnectSection from '../components/ConnectSection';
import Sidebar, { NavigationProps } from '../components/general/Sidebar';
import { useAuth } from '../context/Auth';
import Strapi from '../services/Strapi';

interface Props {
  children: React.ReactNode;
}

const PortalContainer: FC<Props> = ({ children }) => {
  let { connected, connectWallet, disconnect, signature } = useAuth();
  const [loading, setLoading] = useState(false);
  const [annNoti, setAnnNoti] = useState(0);
  const [navigation, setNavigation] = useState<NavigationProps[]>([
    { name: 'Dashboard', icon: '📜', href: '/portal' },
    { name: 'Announcement', icon: '📰', href: '/portal/announcement', notification: annNoti },
    // { name: 'Gift Room', icon: '🎁', href: '/portal/gift-room' },
    { name: 'Store', icon: '🏪', href: '/portal/store' },
    { name: 'Inventory', icon: '📦', href: '/portal/inventory' },
    { name: 'eISO Bridge', icon: '💱', href: '/portal/bridge', beta: true },
    { name: 'Game', icon: '🕹️', href: '/portal/game', beta: true },
    { name: 'DAO', icon: '🗳️', href: 'https://snapshot.org/#/isodao.eth', target: '_blank' },
    { name: 'What is $ISO', icon: '❓', href: 'https://litepaper.isoroom.io/usdiso-token/what-is-usdiso', target: '_blank' },
    { name: 'Analytics', icon: '🧮', href: '/analytics', roles: ['admin'] },
  ])

  const checkAnnouncement = async () => {
    const result = await Strapi.get('announcements?sort=id:desc');
    const data = result.data.map((i: any) => ({ id: i.id, ...i.attributes }));
    const lastSeen = parseInt(localStorage.getItem('announcement-last-seen') || '0')
    const notSeenAnnouncement = data
      .filter((post: any) => dayjs(post.publishedAt).diff(dayjs(lastSeen)) > 0);
    setAnnNoti(notSeenAnnouncement.length || 0);
  }

  useEffect(() => {
    checkAnnouncement();
    console.log({ x: process.env })

    if (process.env.NODE_ENV == 'development') {
      setNavigation([
        ...navigation,
        { name: 'Support', icon: '🖥️', href: '/support/', roles: ['admin'] }
      ])
    }
  }, [])

  if (!signature) {
    return <ConnectSection
      loading={loading}
      connected={connected}
      disconnect={disconnect}
      connectWallet={connectWallet}
    />
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-12 gap-4">
      <div className="col-span-0 sm:col-span-2 md:col-span-3 lg:col-span-3">
        <Sidebar
          navigation={navigation}
          onDisconnect={disconnect}
        />
      </div>
      <div
        style={{ height: "100vh" }}
        className="min-h-screen py-4 pr-4 overflow-y-scroll
        col-span-1 sm:col-span-4 md:col-span-5 lg:col-span-9">
        <div
          className="lg:rounded-lg bg-slate-100 p-4 h-full"
          style={{ minHeight: 'calc(100vh - 34px)', height: "fit-content" }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PortalContainer;