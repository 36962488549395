import { useEffect, useState } from 'react';
import MetamaskButton from '../components/MetamaskButton';
import { useAuth } from '../context/Auth';
import { useNavigate } from "react-router-dom";

const pages = [
    { name: 'Roadmap', id: 'roadmap', href: '/' },
    { name: 'About Us', id: 'about-us', href: '/' },
    { name: 'Rarities', id: 'rarities', href: '/' },
    { name: 'Team', id: 'team', href: '/' },
    { name: 'FAQ', id: 'faq', href: '/faq' },
]

var Nav = ({ stayTop = false }) => {
    const navigate = useNavigate();
    const { connected, connectWallet, disconnect, balanceOf } = useAuth();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [width, setWidth] = useState(0);
    const currentPage = 'Home';

    const resize = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        let lastKnownScrollPosition = 0;
        let ticking = false;

        document.addEventListener('scroll', function (e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    setScrollPos(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        });

        setWidth(window.innerWidth);
        window.addEventListener("resize", resize, true);

        return document.removeEventListener('scroll', () => { });
    }, []);

    return (
        <nav className={`md:fixed top-0 w-full z-40 ${(stayTop || scrollPos > 800 || width < 920) ? 'bg-white' : ''} rounded-b-xl transition-all duration-400`}>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button*/}
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            <span className="sr-only">Open main menu</span>
                            {/*
                                Icon when menu is closed.
                                Heroicon name: outline/menu
                                Menu open: "hidden", Menu closed: "block"
                            */}
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            {/*
                                Icon when menu is open.
                                Heroicon name: outline/x
                                Menu open: "block", Menu closed: "hidden"
                            */}
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <a href="/">
                            <div className="flex-shrink-0 flex items-center">
                                <img width="110" height="32" className="block lg:hidden h-8 w-auto" src="/image/isoroom-h-logo.png" alt="Workflow" />
                                <img width="110" height="32" className="hidden lg:block h-8 w-auto" src="/image/isoroom-h-logo.png" alt="Workflow" />
                            </div>
                        </a>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                {/* Current: "bg-gray-800 text-white", Default: "text-gray-800 hover:bg-gray-700 hover:text-white" */}
                                {
                                    pages.map(item => (
                                        <a
                                            smooth
                                            href={`${item.href}#${item.id}`}
                                            className={`
                                                ${currentPage === item.name ? 'bg-blue-500 text-white' : 'hover:bg-blue-700 hover:text-white text-blue-800'}
                                                px-3 py-2 rounded-md text-sm font-medium transition-all`
                                            }
                                            aria-current="page"
                                        >
                                            {item.name}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        {
                            !connected ?
                                <MetamaskButton
                                    address={connected}
                                    onClick={async () => {
                                        if (connected) return disconnect();
                                        await connectWallet();
                                        navigate(`/portal`);
                                    }}
                                />
                                :
                                <button
                                    onClick={() => navigate(`/portal`)}
                                    className="
                                    ml-2
                                    text-md font-bold shadow-sm px-8 py-2 rounded-full bg-white
                                    hover:bg-amber-100 hover:shadow-md
                                    active:bg-amber-300
                                    transaction-all duration-200
                                    "
                                >
                                    <span className="">🚪 Portal</span>
                                </button>
                        }
                    </div>
                </div>
            </div>

            {/* Mobile menu, show/hide based on menu state. */}
            <div className={`transition-all sm:hidden mb-10 ${mobileMenuOpen ? 'opacity-100 h-48' : 'opacity-0 h-0'}`} id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1">
                    {
                        pages.map(item => (
                            <a
                                smooth
                                href={`#${item.id}`}
                                className={`
                                    ${currentPage === item.name ? 'bg-blue-500 text-white' : 'hover:bg-blue-700 hover:text-white text-blue-800'}
                                    block px-3 py-2 rounded-md text-base font-medium`
                                }
                                aria-current="page"
                            >
                                {item.name}
                            </a>
                        ))
                    }
                </div>
            </div>
        </nav>
    );
}

export default Nav;