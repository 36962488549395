const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

function checkWhitelistForAddress(whitelistAddress, checkAddress) {
  const leafNodes = whitelistAddress.map(addr => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  const claimAddress = keccak256(checkAddress);
  const hexProof = merkleTree.getHexProof(claimAddress);
  return hexProof;
}

export default checkWhitelistForAddress;