import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FilterIcon, ViewGridIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import ToggleButton from '../../ToggleButton';

interface ListHeaderProps {
  header?: string;

  sortOptions?: {
    name: string;
    href: string;
    current: boolean;
  }[];

  onShowAll: (v: boolean) => void;
  setMobileFiltersOpen: (open: boolean) => void;
  onViewGridChange: () => void;
}

/**
 * A template storybook
 */
const ListHeader = ({ header, sortOptions, onShowAll, setMobileFiltersOpen, onViewGridChange }: ListHeaderProps) => {
  return (
    <div className="relative z-10 flex items-baseline justify-between pb-3 border-b border-gray-200">
      {
        header ?
        <h1 className="text-2xl font-bold">{header}</h1>
        : <div/>
      }

      <div className="flex items-center">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            {
              sortOptions &&
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
            }
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {sortOptions && sortOptions.map((option) => (
                  <Menu.Item key={option.name}>
                    {({ active }) => (
                      <a
                        href={option.href}
                        className={[
                          option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm',
                        ].filter(Boolean).join(' ')}
                      >
                        {option.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <ToggleButton
          onChange={(v) => onShowAll(v)}
          label={<p className="text-xs font-bold text-gray-500">Show all</p>}
        />
        <button type="button" className="p-2 -m-2 ml-2 sm:ml-3 text-gray-400 hover:text-gray-500"
          onClick={onViewGridChange}>
          <span className="sr-only">View grid</span>
          <ViewGridIcon className="w-5 h-5" aria-hidden="true" />
        </button>
        {/* <button
          type="button"
          className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden"
          onClick={() => setMobileFiltersOpen(true)}
        >
          <span className="sr-only">Filters</span>
          <FilterIcon className="w-5 h-5" aria-hidden="true" />
        </button> */}
      </div>
    </div>
  )
}

export default ListHeader;