import { FC } from "react";

interface Props {
  rows: {
    id: string;
    name: string;
    onChange: (e: any) => void;
    placeholder?: string;
    defaultValue?: string;
    value?: string;
  }[]
}

const InputGroup: FC<Props> = ({ rows }) => {
  return (
    <div className="isolate -space-y-px rounded-md shadow-sm">
      {
        rows.map((r, i) => (
          <div className={`relative border border-gray-300 rounded-md px-3 py-2 focus-within:z-10
            ${i < rows.length - 1 ? 'rounded-b-none' : ''}
            ${i > 0 ? 'rounded-t-none' : ''}
          `}>
            <label htmlFor={r.id} className="block text-xs font-medium text-gray-700">
              {r.name}
            </label>
            <input
              type="text"
              name={r.name}
              id={r.id}
              onChange={r.onChange}
              className="block w-full border-0 text-gray-900 placeholder-gray-500 focus:ring-0 p-1"
              placeholder={r.placeholder}
              defaultValue={r.defaultValue}
              value={r.value}
            />
          </div>
        ))
      }
    </div>
  )
}

export default InputGroup;