import Badge from "../../Badge";

export interface InventoryItemProps {
  onClick: () => void;
  id: string;
  imageSrc: string;
  imageAlt: string;
  name: string;
  price: number;
  token: string;
  category: string;
  description: string;
  status: string;
  qty: number;
}

/**
 * A InventoryItem storybook
 */
const InventoryItem = ({
  onClick,
  id,
  imageSrc,
  imageAlt,
  name,
  price,
  token,
  category,
  status,
  qty,
}: InventoryItemProps) => {
  return (
    <div
      onClick={onClick}
      key={id}
      className={`relative group p-2 rounded-md hover:bg-gray-100
        duration-300 ease-in-out cursor-pointer`}>
      <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100 shadow relative">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="object-center object-cover w-full"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/image/1x1-square.png";
          }}
        />
        {
          status !== 'processing' &&
            <Badge mode={
              status === 'completed' 
                ? 'green' : status === 'rejected' 
                ? 'red'
                : 'default'
            } className="absolute top-2 right-1.5 capitalize">
              {status}
            </Badge>
        }
      </div>
      <div className="p-1">
        <h3 className="mt-4 font-bold text-sm text-gray-700">
          <span className="absolute inset-0" />
          {name}
        </h3>
        <Badge mode="indigo">Qty: {qty}</Badge>
      </div>
    </div>
  )
}

export default InventoryItem;