import React, { useEffect, useContext, useCallback, useState } from 'react';
import {
  connectMetamask,
  removeMetamaskListener,
} from './Metamask';
import { connectWalletLink } from './WalletLink';
import {
  connectWalletConnect,
  disconnectWalletConnect
} from './WalletConnect';
import ConnectionModal from './ConnectionModal';
import { useAlert } from 'react-alert'
import { signTypedDataV4 } from '../utils';
import Apiv2 from '../services/Apiv2';

const isoroomContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;

const isoroomContract = require("../utils/contracts/Isoroom.json");
const isoTokenContract = require("../utils/contracts/IsoToken.json");

const AuthContext = React.createContext({
  connected: null,
  connectWallet: () => false,
  onSignIn: () => false,
  disconnect: () => false,
  balanceOf: 0,
  tokenBalance: { iso: '0', eIso: '0' },
  web3: null,
  signature: null,
  sigData: {}
});

export const AuthProvider = ({ children }) => {
  // TODO : Try to move to ui code
  const alert = useAlert();
  const [connected, setConnected] = useState(null);
  const [balanceOf, setBalanceOf] = useState(0);
  const [tokenBalance, setTokenBalance] = useState({ iso: '0', eIso: '0' });
  const [connectionType, setConnectionType] = useState(null);
  const [connectionModal, setConnectionModal] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [signature, setSignature] = useState(null);
  const [sigData, setSigData] = useState(null);

  const resetState = () => {
    setConnected(null);
    setBalanceOf(0);
    setTokenBalance({ iso: '0', eIso: '0' });
    setConnectionType(null);
    setConnectionModal(false);
    setWeb3(null);
    setSignature(null);
    setSigData(null);

    localStorage.removeItem('signature-data');
    localStorage.removeItem('signature');
  }

  const updateIsoroomTokenBalance = async (address, web3) => {
    if (!address) return;
    const isoroomC = new web3.eth.Contract(isoroomContract.abi, isoroomContractAddress);
    let _balanceOf = await isoroomC
      .methods.balanceOf(address).call();

    setBalanceOf(_balanceOf);
  };

  const updatetokenBalance = async (address, web3) => {
    if (!address) return;
    const isoTokenC = new web3.eth.Contract(isoTokenContract.abi, ISOTOKEN_ADDRESS);
    let _isoBalnace = await isoTokenC.methods.balanceOf(address).call();

    const eIso = (await Apiv2.get('eiso')).data;

    setTokenBalance({
      iso: _isoBalnace || '0',
      eIso: eIso || '0',
    });
  };

  const handleChainChanged = () => {
    resetState();
    window.location.reload();
  }

  const handleAccountsChanged = (accounts) => {
    resetState();
  }

  const handleDisconnect = () => {
    resetState();
  }

  const onSignIn = async (web3, address) => {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    const sData = JSON.parse(localStorage.getItem('signature-data'));
    const sSig = localStorage.getItem('signature');

    if (sData && sSig && address === sData.message.owner
      && (currentTime - sData.message.datetime) < 432000)
      return { data: sData, sig: sSig };

    const message = {
      domain: {
        chainId: process.env.REACT_APP_NETWORK_VERSION,
        name: 'Signin isoroom',
        verifyingContract: process.env.REACT_APP_CONTRACT_ADDRESS,
        version: '1',
      },
      message: {
        datetime: currentTime.toString(),
        owner: address,
        action: 'signing into isoportal',
      },
      primaryType: 'Message',
      types: {
        EIP712Domain: [
          { name: 'chainId', type: 'uint256' },
          { name: 'name', type: 'string' },
          { name: 'verifyingContract', type: 'address' },
          { name: 'version', type: 'string' },
        ],
        Message: [
          { name: 'datetime', type: 'string' },
          { name: 'owner', type: 'address' },
          { name: 'action', type: 'string' }
        ],
      }
    }

    const { data, sig } = await signTypedDataV4({ web3, message, address });
    localStorage.setItem('signature-data', JSON.stringify(data));
    localStorage.setItem('signature', sig);

    return { data, sig };
  }

  /**
   * @param {*} type : metamask | wallet-link | walletconnect
   * Error should handled by frontend
   */
  const connectWallet = async (_type) => {
    try {
      setConnectionModal(false);

      const listener = {
        handleChainChanged,
        handleAccountsChanged,
        handleDisconnect,
      }
    
      let connectionObj;

      if (_type === 'metamask') {
        connectionObj = await connectMetamask(listener);
      } 
      
      if (_type === 'wallet-link') {
        connectionObj = await connectWalletLink();
      } 
      
      if (_type === 'wallet-connect') {
        connectionObj = await connectWalletConnect(listener);
      }

      setConnected(connectionObj.web3.utils
        .toChecksumAddress(connectionObj.accounts[0]));
      setWeb3(connectionObj.web3);
      setConnectionType(_type);

      /**
       * TODO : Check why Coinbase wallet (wallet-link) cannot sign
       */
      const { sig, data } = await onSignIn(connectionObj.web3, connectionObj.accounts[0]);
      setSignature(sig);
      setSigData(data);
    } catch(e) {
      alert.error(e.message || e.toString());
    }
  }

  const disconnect = useCallback(() => {
    const listener = {
      handleChainChanged,
      handleAccountsChanged,
      handleDisconnect,
    }

    switch (connectionType) {
      case 'metamask':
        removeMetamaskListener(listener);
        break;
      case 'wallet-link':
        break;
      case 'wallet-connect':
        disconnectWalletConnect()
        break;
    }

    resetState();
  }, [connectionType]);

  useEffect(() => {
    if (connected === null || !web3) return;
    updateIsoroomTokenBalance(connected, web3);
  }, [connected, web3]);

  useEffect(() => {
    if (signature !== null)
      updatetokenBalance(connected, web3);
  }, [signature])

  return (
    <AuthContext.Provider
      value={{
        connected,
        connectWallet: () => setConnectionModal(true),
        onSignIn,
        disconnect,
        balanceOf,
        tokenBalance: tokenBalance,
        web3,
        signature,
        sigData,
      }}
    >
      <ConnectionModal
        open={connectionModal}
        onClose={() => setConnectionModal(false)}
        onSelectWallet={connectWallet}
      />
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
