import { useState, FC } from 'react'
import Select from 'react-select';

interface RoyalMintingProps {
  genesisBal: number;
  utilityBal: number;
  royalIsoroomMinted: number;
  fragmentsBal: number;
  eligibility: number;
  loading: boolean;
  onMint: any;
}

const RoyalMinting: FC<RoyalMintingProps> = ({ genesisBal = 0, utilityBal = 0, royalIsoroomMinted = 0, fragmentsBal = 0, eligibility = 0, loading, onMint }) => {
  const [qty, setQty] = useState(0);

  return (
    <div className="mt-4 rounded-md p-2 shadow-md font-sans bg-white min-h-200px rainbow">
      <p className="text-lg font-semibold text-slate-900">👑 Royal isoroom minting started!</p>
      <p className="text-sm text-slate-700 mt-2"><b>You have</b></p>
      <p className="text-sm text-slate-700">{genesisBal} holding Genesis room</p>
      <p className="text-sm text-slate-700 mb-5">{utilityBal} holding Utility room</p>
      {/* <p className="text-sm text-slate-700">{fragmentsBal} Fragments</p> */}

      <a href="https://opensea.io/collection/isoworld-royal-isoroom" target="_blank"
        className="text-sm font-bold underline text-slate-700 mt-2">You have minted {royalIsoroomMinted} Royal room</a>

      <p className="text-sm text-slate-700 mt-2">You can mint <b>{eligibility}</b> Royal isoroom now!</p>

      <div className="mt-2">
        <Select
          value={{ value: qty, label: qty }}
          placeholder="isoroom To Stake"
          onChange={(v: any) => setQty(v.value)}
          options={(new Array(eligibility).fill(1)).map((_, i) => ({ value: i + 1, label: i + 1 }))}
        />
        <button
          disabled={qty === 0 || loading}
          onClick={() => onMint(qty)}
          className="mt-2 h-8 px-6 font-semibold rounded-md bg-orange-800 hover:bg-orange-500 focus:ring-offset-white focus:ring-orange-900 focus:outline-none text-white">
          {loading ? 'Loading...' : 'Mint Now'}
        </button>
      </div>
    </div>
  )
}

export default RoyalMinting;