import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Badge from '../../Badge';
import Button from '../../Button';
import ModalWrapper from '../../general/ModalWrapper';
import InputGroup from '../../InputGroup';
import Markdown from '../../Markdown';

interface InventoryPopupProps {
  open: boolean;
  onClose: () => void;

  name: string;
  price: number;
  token: string;
  imageSrc: string;
  imageAlt: string;
  shippingStatus: string;
  twitter: string;
  redemption_address: string;
  discord_name: string;
  onLootboxOpen: (discordName: string, addr: string) => void;
  lootboxResult: Array<any>;
  description: string;
  loading: boolean;
}

/**
 * A template storybook
 */
const InventoryPopup = ({
  open,
  onClose,
  name,
  imageSrc,
  imageAlt,
  shippingStatus,
  twitter,
  redemption_address,
  discord_name,
  description,
  lootboxResult,
  onLootboxOpen,
  loading,
}: InventoryPopupProps) => {
  const [discordName, setDiscordName] = useState(discord_name);
  const [redemptionAddress, setRedemptionAddress] = useState(redemption_address);

  useEffect(() => {
    setDiscordName(discord_name);
    setRedemptionAddress(redemption_address);
  }, [discord_name, redemption_address])

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
        <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
          <button
            type="button"
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          {lootboxResult.length > 0 ? (
            <div>
              <p className="font-semibold text-lg text-neutral-900 mb-2">Congratulations, you got:</p>
              <div className="flex items-center justify-start">
                {lootboxResult.map((item) => (
                  <div
                    key={item.id}
                    className={`relative group p-2 rounded-md hover:bg-gray-100
                      duration-300 ease-in-out cursor-pointer`}>
                    <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100 shadow relative">
                      <img
                        style={{ height: '175px' }}
                        loading="lazy"
                        src={item.image_url}
                        alt={item.name}
                        className="object-center object-cover w-full" />
                    </div>
                    <div className="p-1">
                      <h3 className="mt-4 font-bold text-sm text-gray-700">
                        <span className="absolute inset-0" />
                        {item.name}
                      </h3>
                      <Badge mode="indigo">Qty: {item.qty || 1}</Badge>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
              <div className="sm:col-span-4 lg:col-span-5">
                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                  <img src={imageSrc} alt={imageAlt} className="object-center object-cover" />
                </div>
              </div>
              <div className="sm:col-span-8 lg:col-span-7">
                <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{name}</h2>
                {/* <section aria-labelledby="information-heading" className="mt-3">
                  <h3 id="information-heading" className="block text-sm font-bold text-gray-900 mb-2">
                    Description
                  </h3>
                  <Markdown>{description || ''}</Markdown>
                </section> */}

                <section aria-labelledby="information-heading" className="mt-3 border-t border-gray-200 pt-3">
                  <h4 className="block text-sm font-bold text-gray-900">Status</h4>

                  <div className="mt-2 prose prose-sm whitespace-pre-line">
                    <Markdown>{shippingStatus}</Markdown>
                  </div>
                </section>

                <section aria-labelledby="information-heading" className="mt-3 border-t border-gray-200 pt-3">
                  <h3 id="information-heading" className="block text-sm font-bold text-gray-900">
                    Redemption information
                  </h3>

                  <div className="mt-2">
                    <InputGroup
                      rows={[
                        {
                          id: 'purchases-popup-discord-name',
                          name: 'Discord Name',
                          onChange: (e) => setDiscordName(e.target.value),
                          value: discordName,
                          // defaultValue: discord_name,
                        },
                        {
                          id: 'purchases-popup-wallet-address',
                          name: 'Redemption Address',
                          onChange: (e) => setRedemptionAddress(e.target.value),
                          value: redemptionAddress,
                          // defaultValue: redemption_address,
                        },
                      ]}
                    />
                    <Button
                      // disabled
                      loading={loading}
                      onClick={() =>
                        onLootboxOpen(discordName, redemptionAddress)}
                      orange
                      style={{ width: '100%', marginTop: 5, marginBottom: 0 }}>
                      Open Now!
                    </Button>
                  </div>
                </section>
              </div>
            </div>
          )}

        </div>
      </div>
    </ModalWrapper>
  )
}

export default InventoryPopup;