import dayjs from 'dayjs';
import { useEffect, useState, FC } from 'react';
import Select from 'react-select';
import Web3 from 'web3';
import MetamaskButton from '../MetamaskButton';
import { whitelistMintDate, publicMintDate } from '../../constants';
import { useAlert } from 'react-alert'
import { useAuth } from '../../context/Auth';
import Button from '../Button';
import Checkbox from '../Checkbox';

/**
 * Variable update before deploy
 */
const utilityIsoroomAddress = process.env.REACT_APP_UTILITY_ISOROOM;
const price = 0;
const priceInWei = price * 10 ** 18;

let counterInterval: any;
let publicCounterInterval: any;

interface TimerBoxProps {
  number: number;
  type: string;
}

const TimerBox: FC<TimerBoxProps> = ({ number, type }) => {
  return (
    <div
      className="rounded-md bg-white flex flex-col justify-center items-center font-bold w-20 h-24 shadow-lg mr-2"
    >
      <p className="text-slate-900" style={{ fontSize: 36, lineHeight: 1 }}>{number}</p>
      <div className="text-slate-900">{type}</div>
    </div>
  )
}

const etherScanUrl = `${process.env.REACT_APP_ETHERSCAN_URL}/address/${process.env.REACT_APP_UTILITY_ISOROOM}`;

const WhitelistMintSection: FC<any> = () => {
  const alert = useAlert();
  const { connected, connectWallet, web3 } = useAuth();

  const [warning, setWarning] = useState(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [minted, setMinted] = useState<number>(0);
  const [ticket, setTicket] = useState<number>(-1);
  // Set 1 to show counter as default
  const [countDown, setCountDown] = useState(1);
  const [publicCountDown, setPublicCountDown] = useState(1);
  const [qty, setQty] = useState<number | null>(1);
  const [maxSupply, setMaxSupply] = useState(4444);
  const [remaining, setRemaining] = useState(4444);
  const [stakeOnMint, setStakeOnMint] = useState(false);

  const getContract = (web3: Web3) => {
    if (!web3) throw new Error("No web3 connected");

    const utilityIsoroom = require("../../utils/contracts/UtilityIsoroom.json");
    const utilityIsoroomConnection = new web3.eth.Contract(utilityIsoroom.abi, utilityIsoroomAddress);

    return utilityIsoroomConnection;
  }

  const resetState = () => {
    setWarning(null);
    setSuccessMessage(null);
    setLoading(false);
  }

  const updateCounter = () => {
    const secDiff = dayjs().diff(dayjs(whitelistMintDate)) * -1;
    if (secDiff < 0) {
      clearInterval(counterInterval);
      return setCountDown(0);
    }
    setCountDown(secDiff);
  }

  const updatePublicCounter = () => {
    const secDiff = dayjs().diff(dayjs(publicMintDate)) * -1;
    if (secDiff < 0) {
      clearInterval(publicCounterInterval);
      return setPublicCountDown(0);
    }
    setPublicCountDown(secDiff);
  }

  const updateTicket = async (address: string) => {
    const url = process.env.REACT_APP_API_CONNECT + '/api/whitelisted/' + address;
    const response = await fetch(url);
    const quota = (await response.json()).quota;
    return quota
  }

  const onWalletConnected = async (web3: Web3, connectedAddress: string) => {
    if (connectedAddress == null) return;
    let _ticket = null;

    try {
      _ticket = await updateTicket(connectedAddress);
      setTicket(_ticket);

      if (!_ticket) return;
      // Only call contract after countDown
      if (countDown > 0) return;
      const utilityIsoroomConnection = getContract(web3);
      const result = await utilityIsoroomConnection
        .methods.whitelistRecord(connectedAddress).call();

      setMinted(result);
      setQty(_ticket - result);
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  const _connectWallet = async () => {
    try {
      resetState();
      setLoading(true);
      await connectWallet();
    } catch (e: any) {
      alert.error(e.message || e.toString());
    } finally {
      setLoading(false);
    }
  }

  const publicMintNow = async (web3: Web3, address: string, qty: number, stake: boolean) => {
    try {
      resetState();
      setLoading(true);

      const utilityIsoroomConnection = getContract(web3);
      const accounts = await web3.eth.getAccounts();
      await utilityIsoroomConnection.methods
        .publicMint(qty, stake)
        .send({
          from: accounts[0],
          value: `${priceInWei * qty}`,
        });

      setSuccessMessage('Successfully minted!')
      onWalletConnected(web3, address);
    } catch (e: any) {
      setWarning(e.message);
    } finally {
      setLoading(false);
    }
  }

  const mintNow = async (web3: Web3, address: string, ticket: number, qty: number, stake: boolean) => {
    try {
      resetState();
      setLoading(true);

      const url = process.env.REACT_APP_API_CONNECT + '/api/whitelisted/' + address + '/sign';
      const response = await fetch(url);
      const { message, messageHash, r, s, signature, v } = await response.json();
      const utilityIsoroomConnection = getContract(web3);
      const accounts = await web3.eth.getAccounts();
      await utilityIsoroomConnection.methods
        .whitelistMint(message, v, r, s, qty, stake)
        .send({
          from: accounts[0],
          value: `${priceInWei * qty}`,
        });

      setSuccessMessage('Successfully minted!')
      onWalletConnected(web3, address);
    } catch (e: any) {
      setWarning(e.message);
    } finally {
      setLoading(false);
    }
  }

  const updateTotalSupply = async (web3: Web3) => {
    const utilityIsoroomConnection = getContract(web3);
    const ms = await utilityIsoroomConnection.methods.maxSupply().call();
    const ts = await utilityIsoroomConnection.methods.totalSupply().call();
    setMaxSupply(ms);
    setRemaining(ms - ts);
  }

  useEffect(() => {
    if (web3 && connected)
      onWalletConnected(web3, connected);
  }, [web3, connected])

  useEffect(() => {
    updateCounter();
    updatePublicCounter();
    counterInterval = setInterval(updateCounter, 1000);
    publicCounterInterval = setInterval(updatePublicCounter, 1000);

    return () => {
      clearInterval(counterInterval);
      clearInterval(publicCounterInterval);
    }
  }, [])

  useEffect(() => {
    if (web3) updateTotalSupply(web3);
  }, [web3])

  const seconds = Math.floor((countDown / 1000) % 60);
  const minutes = Math.floor((countDown / 1000 / 60) % 60);
  const hours = Math.floor((countDown / (1000 * 60 * 60)) % 24);
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

  const pSeconds = Math.floor((publicCountDown / 1000) % 60);
  const pMinutes = Math.floor((publicCountDown / 1000 / 60) % 60);
  const pHours = Math.floor((publicCountDown / (1000 * 60 * 60)) % 24);

  return (
    <div
      className="mt-10 p-4 rounded-xl min-h-36 bg-white mx-auto relative z-10 shadow-lg"
      style={{ width: 800, maxWidth: '90%' }}
    >
      {
        countDown > 0 ?
          <div>
            <p className="font-bold text-slate-900 mb-2">Whitelist minting start on {dayjs(whitelistMintDate).format('MMMM D YYYY, h:mm:ss a')}</p>
            <div className="flex justify-center items-center">
              <TimerBox number={days} type="Days" />
              <TimerBox number={hours} type="Hours" />
              <TimerBox number={minutes} type="Min" />
              <TimerBox number={seconds} type="Sec" />
            </div>
            <Button className="text-slate-900 mx-auto mb-0" onClick={() => _connectWallet()}>
              {
                ticket === -1 ?
                  '📖 Click to check your whitelist status!'
                  : ticket > 0 ?
                    `✅ You can mint ${ticket} Utility isoroom(s)!`
                    : "😭 Sorry you are not eligible for presale"
              }
            </Button>
          </div>
          :
          /**
           * Public minting
           */
          publicCountDown === 0 ?
            <div className="text-center">
              <p className="text-slate-900 mb-4 font-bold">🎈 Utility isoroom public minting started!</p>
              {
                connected === null ?
                  <MetamaskButton className='mx-auto mb-4' onClick={_connectWallet} />
                  :
                  <div className="bg-white text-slate-900 rounded-md p-2 flex flex-col justify-center items-center">
                    <p className="font-bold">Hi, {`${connected}`.substring(0, 10)}...</p>
                    <>
                      <p className="font-bold text">✅ You can mint at most 20 utility isorooms in one transaction!</p>
                      <div className="flex flex-row mt-4 items-center">
                        <div className="w-24">
                          <Select
                            value={{ label: qty, value: qty }}
                            placeholder="Qty"
                            // style={{ width: 20 }}
                            onChange={(v) => setQty(v?.value || 0)}
                            options={
                              new Array(20).fill('-')
                                .map((_, i) => ({ label: i + 1, value: i + 1 }))
                            }
                          />
                        </div>
                        <button
                          disabled={loading || remaining === 0}
                          onClick={() => connected ?
                            web3 ? publicMintNow(web3, connected, qty || 0, stakeOnMint)
                              : alert.error("Wallet not connected.")
                            : null
                          }
                          className={`
                          relative ml-4
                          text-white text-lg leading-6 font-semibold py-2 px-4 border border-transparent
                          rounded-xl focus:ring-2 focus:ring-offset-2
                          
                          bg-gradient-to-r from-orange-500 to-orange-700
                          
                          transition-all duration-400 ease-in-out  hover:from-orangeto-orange-200 hover:to-orangefrom-orange-100
                          focus:ring-offset-white focus:ring-orange-500 focus:outline-none
                          shadow-sm hover:shadow-xl

                          active:red-800
                        `}
                        >{
                            loading ? 'Loading...'
                              : `Mint ${qty || ''} Now! Pay ${((qty || 0) * price).toFixed(4)} eth`
                          }</button>
                      </div>
                      <div className="mt-4">
                        <Checkbox
                          onChange={(e) => setStakeOnMint(e.target.checked)}
                          text="L2 Staking now to earn $eISO to rob our isostore!"
                          hints="(The gas fee is the lowest when you stake on mint now)"
                          checked={stakeOnMint}
                          textClassName=''
                        />
                      </div>

                      {successMessage !== null
                        && <p className="text-green-900 mt-2 font-bold">{successMessage}</p>}

                    </>
                  </div>
              }
            </div>
            :
            /**
             * Whitelist minting
             */
            <div className="text-center">
              <p className="text-slate-900 mb-4 font-bold">🎈 Utility isoroom whitelist free mint started!</p>
              {
                connected === null ?
                  <MetamaskButton className='mx-auto mb-4' onClick={_connectWallet} />
                  :
                  <div className="bg-white text-slate-900 rounded-md p-2 flex flex-col justify-center items-center">
                    <p className="font-bold">Hi, {`${connected}`.substring(0, 10)}...</p>
                    <p className="font-bold">
                      {
                        ticket <= 0 ?
                          '😭 You are not eligible for presale.'
                          : (ticket - minted <= 0) ?
                            minted > 0 ? `You have sucessfully minted ${minted}! All whitelist ticket used, thank you for participating.`
                              : '😭 You are not eligible for presale.'
                            : ''
                      }
                    </p>
                    {
                      (ticket - minted > 0) &&
                      <>
                        <p className="font-bold text">✅ You can mint {ticket - minted} utility isoroom(s)!</p>
                        <div className="flex flex-row mt-4 items-center">
                          <div className="w-24">
                            <Select
                              value={{ label: qty, value: qty }}
                              placeholder="Qty"
                              // style={{ width: 20 }}
                              onChange={(v) => setQty(v?.value || 0)}
                              options={
                                new Array(ticket - minted).fill('-')
                                  .map((_, i) => ({ label: i + 1, value: i + 1 }))
                              }
                            />
                          </div>
                          <button
                            disabled={loading || remaining === 0}
                            onClick={() => connected ?
                              web3 ? mintNow(web3, connected, ticket, qty || 0, stakeOnMint)
                                : alert.error("Wallet not connected.")
                              : null
                            }
                            className={`
                          relative ml-4
                          text-white text-lg leading-6 font-semibold py-2 px-4 border border-transparent
                          rounded-xl focus:ring-2 focus:ring-offset-2
                          
                          bg-gradient-to-r from-orange-500 to-orange-700
                          
                          transition-all duration-400 ease-in-out  hover:from-orangeto-orange-200 hover:to-orangefrom-orange-100
                          focus:ring-offset-white focus:ring-orange-500 focus:outline-none
                          shadow-sm hover:shadow-xl

                          active:red-800
                        `}
                          >{
                              loading ? 'Loading...'
                                : `Mint ${qty || ''} Now! Pay ${((qty || 0) * price).toFixed(4)} eth`
                            }</button>
                        </div>
                        <div className="mt-4">
                          <Checkbox
                            onChange={(e) => setStakeOnMint(e.target.checked)}
                            text="L2 Staking now to earn $eISO to rob our isostore!"
                            hints="(The gas fee is the lowest when you stake on mint now)"
                            checked={stakeOnMint}
                            textClassName=''
                          />
                        </div>

                        {successMessage !== null
                          && <p className="text-green-900 mt-2 font-bold">{successMessage}</p>}

                      </>
                    }
                  </div>
              }
              {/* <p className="text-slate-900 text-xs font-bold mt-2">Public minting in {pHours}hrs {pMinutes}mins {pSeconds}secs</p> */}
            </div>
      }
      {warning !== null && <p className="text-red-900 mt-2 font-bold text-center">{warning}</p>}

      <div className="mt-4 text-black text-xs mx-auto flex flex-col justify-center items-center">
        <p className="flex row items-center">
          Contract<img src="/check.png" width="10" className="mr-1" />:
          <a className="break-all underline max-w-full overflow-hidden"
            href={etherScanUrl} target="_blank">{process.env.REACT_APP_UTILITY_ISOROOM}</a>
        </p>
        <p>
          Opensea: <a className="break-all underline max-w-full overflow-hidden"
            href="https://opensea.io/collection/isoworld-utility-isoroom" target="_blank">
            https://opensea.io/collection/isoworld-utility-isoroom
          </a>
        </p>
      </div>

    </div>
  )
}

export default WhitelistMintSection;