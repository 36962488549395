import EventEmitter from "./EventEmitter.js";
import Experience from "../Experience.js";

export default class Sizes extends EventEmitter {
  constructor(size) {
    super();
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.drag = false;
    this.size = size;
    // Setup
    this.width = window.innerWidth * size;
    this.height = window.innerHeight;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    this.cursor = {
      x: 0,
      y: 0,
    };

    // Resize event
    window.addEventListener("resize", () => {
      this.width = window.innerWidth * size;
      this.height = window.innerHeight;
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);

      this.trigger("resize");
    });
  }
}
