import styled from "styled-components"
import { CircledButton, SIZES } from "./shared"


export const RoadMapWrapper = styled.section`
  height: 270px;
  background-color: ${props => props.background};
  background-image: url("/landing/${props => props.backgroundImage}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  flex-direction: ${props => props.direction};
  border-radius: 25px;
  display: flex;
  margin-bottom: 7rem;  

  @media (max-width:${SIZES.mobile}) {
    height: 520px;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
  
  .check-icon {
    position: relative;
    margin: 0 1rem;
    z-index: 1;

    .white-mid {
      position: absolute;
      border-radius: 40px;
      left: 7px;
      top: 7px;
      z-index: -1;
      height: 35px;
      width: 35px;
      background: white;
    }
  }
    
  .text {
    width: 60%;
    padding: 1.5rem 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: left;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 36px;
      font-weight: bold;
      display: flex;
      align-items: center;

      @media (max-width:${SIZES.mobile}) {
        font-size: 24px;
        margin-bottom: 1rem;
      }
    }

    @media (max-width:${SIZES.mobile}) {
      width: 100%;
      margin-top: -3rem;
    }

    .desc {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: normal;
    }
  }
  
  .image-box {
    width: 40%;
    padding: 0 1.5rem;

    img {
      margin-top: ${props => props.imageMargin || '0'};
    }

    @media (max-width:${SIZES.mobile}) {
      width: 100%;
      padding: 0 1rem !important;
    }
  }

  .unique {
    @media (max-width:${SIZES.mobile}) {
      margin-top: -5rem;
      margin-bottom: 3rem;
    }
  }
`

export const TeamMemberWrapper = styled.section`
  background: ${props => props.background};
  padding: 1.5rem;
  height: 420px;
  border-radius: 28px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .role {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    p {
      line-height: 3.5rem;
      font-family: 'Montserrat',sans-serif;
      opacity: 40%;
      text-transform: uppercase;
      font-size: 52px;
      font-weight: bold;
      color: white;

      @media (max-width:${SIZES.mobile}) {
        font-size: 45px;
      }
    }
  }

  .center-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;

    img {
      max-width: unset;
      height: 460px;
      width: auto;
    }
  }

  .username {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: .3rem 1rem .3rem .5rem;
    width: fit-content;
    display: flex;
    gap: .5rem;

    .logo {
      width: 24px;
      height: 24px;
      background: #F29E1E;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    p {
      color: #3A3861;
      font-weight: bold;
    }
  }
`

export const LandingTextStyled = styled.div`
  position: relative;
  z-index: 5;
 
  .landing {
    margin-top: -24rem;
    padding: 2rem 0;

    .text-content {
      display: block;

      > p {
        font-size: 20px;
      }

      @media (max-width:${SIZES.mobile}) {
        display: none;
      }  
    }

    .text-content-mobile {
      display: none;
      font-size: 18px;
      text-align: justify;
      @media (max-width:${SIZES.mobile}) {
       display: block;
      }
    }

    @media (max-width:${SIZES.mobile}) {
      margin-top: 0;
    }

    > h1 {
      color: white;
      margin: 0;
      font-size: 85px;
      font-weight: bold;
      text-shadow: 0px 3px 99px #006564;
      line-height: 1.2;
      
      @media (max-width:${SIZES.mobile}) {
        font-size: 55px;
        text-align: center;
      }
    }
  
    > p {
      color: white;
      font-size: 20px;
      margin: 2px 0;
      text-shadow: 0px 3px 6px #007576;
    }
  
    > hr {
      background: white;
      border: 1px solid white;
      margin: 1.5rem 0;
      opacity: 30%;
    }
  
    &__btns {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 12px;
      align-items: center;
      justify-content: flex-start;
  
      > button {
          ${CircledButton}
        }
    }
  }
`

export const LandingPageStyled = styled.div`
  color: white;
  font-family: 'Helvetica 45 Light', sans-serif;
  background: linear-gradient(180deg, rgba(103,205,214,1) 0%, rgba(22,183,147,1) 100%);
  padding: 0 0 2rem 0;

  .backdrop_text {
    width: 80vw;
    max-width: 1280px;
    position: absolute;
    transform: translate(-50%, 10%);
    left: 50%;
    top: 14rem;

    h1 {
      font-family: 'Montserrat', sans-serif;
      color: white;
      font-size: clamp(2rem,13.2vw,13.2rem);
      font-weight: 900;
      opacity: 30%;
      text-transform: uppercase;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }
  
  .app-container {
    max-width: 1280px;

    .roadmap {
      @media (max-width:${SIZES.mobile}) {
        margin-top: 7rem;
      }
    }

    h1.opaque_text {
      font-family: 'Montserrat', sans-serif;
      
      font-size: clamp(2rem,9vw,13.2rem);
      opacity: 30%;
      color: white;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 2rem;

      @media (max-width:${SIZES.mobile}) {
        font-size: 2rem;
        margin-bottom: 0;
      }
    } 
  
    .welcome {
      background: #2cd2c7;
      background-image: url("/landing/bg1.png");
      border-radius: 25px;
      padding: 4rem 8rem;
      font-family: 'Montserrat', sans-serif;
      color: white;

      @media (max-width:${SIZES.mobile}) {
        padding: 2rem;
      }
  
      .title {
        font-size: 80px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 3rem;

        @media (max-width:${SIZES.mobile}) {
          font-size: 25px;
          margin-bottom: 1rem;
        }
      }
  
      .desc {
        font-size: 25px;
        font-weight: normal;

        @media (max-width:${SIZES.mobile}) {
          font-size: 22px;
        }
      }
  
      &__btns {
        margin-top: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        @media (max-width:${SIZES.mobile}) {
          flex-direction: column;
          margin-top: 2rem;
        }
        
        button {
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border: 2px solid #FFFFFF;
          border-radius: 21px;
          color: #20BA9B;
          font-size: 20px;
          padding: .2rem 1rem;
          text-transform: uppercase;
          font-weight: 500;

          @media (max-width:${SIZES.mobile}) {
            width: 100%;
          }
        }
      }
    }
  
    .footer {
      font-family: 'Montserrat',sans-serif;
      color: white;
      margin-top: 2rem;
      text-align: center;
      font-size: 25px;
    }
  }  

  .teams {
    @media (max-width:${SIZES.mobile}) {
      display: flex !important;
      flex-direction: column;
    }
  }
`