import { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ShieldCheckIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import InputWithButton from '../../InputWithButton';
import Markdown from '../../Markdown';
import InputGroup from '../../InputGroup';
import Button from '../../Button';
import DialogBox from '../../Dialog';

interface InventoryPopupProps {
  open: boolean;
  onClose: () => void;

  name: string;
  price: number;
  token: string;
  imageSrc: string;
  imageAlt: string;
  shippingStatus: string;
  twitter: string;
  redemption_address: string;
  discord_name: string;
  onRedemptionInfoUpdate: ((v: string, addr: string) => void) | null;
}

/**
 * A template storybook
 */
const InventoryPopup = ({
  open,
  onClose,
  name,
  imageSrc,
  imageAlt,
  shippingStatus,
  twitter,
  redemption_address,
  discord_name,
  onRedemptionInfoUpdate,
}: InventoryPopupProps) => {
  const [discordName, setDiscordName] = useState(discord_name);
  const [redemptionAddress, setRedemptionAddress] = useState(redemption_address);

  console.log({ redemption_address })

  useEffect(() => {
    setDiscordName(discord_name);
    setRedemptionAddress(redemption_address);
  }, [discord_name, redemption_address])

  return (
    <DialogBox isOpen={open} onClose={onClose}>
      <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
        <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
          <button
            type="button"
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
            <div className="sm:col-span-4 lg:col-span-5">
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <img src={imageSrc} alt={imageAlt} className="object-center object-cover" />
              </div>
            </div>
            <div className="sm:col-span-8 lg:col-span-7">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{name}</h2>

              <ul role="list" className="flex items-center space-x-6 mt-2">
                <li>
                  {
                    twitter &&
                    <a href={twitter} className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500" target="_blank">
                      <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  }
                </li>
              </ul>

              {
                onRedemptionInfoUpdate !== null &&
                <section aria-labelledby="information-heading" className="mt-3">
                  <h3 id="information-heading" className="block text-sm font-bold text-gray-900">
                    Redemption information
                  </h3>

                  <div className="mt-2">
                    <InputGroup
                      rows={[
                        {
                          id: 'purchases-popup-discord-name',
                          name: 'Discord Name',
                          onChange: (e) => setDiscordName(e.target.value),
                          value: discordName,
                          // defaultValue: discord_name,
                        },
                        {
                          id: 'purchases-popup-wallet-address',
                          name: 'Redemption Address',
                          onChange: (e) => setRedemptionAddress(e.target.value),
                          value: redemptionAddress,
                          // defaultValue: redemption_address,
                        },
                      ]}
                    />
                    <Button
                      onClick={() =>
                        onRedemptionInfoUpdate(discordName, redemptionAddress)}
                      orange
                      style={{ width: '100%', marginTop: 5, marginBottom: 0 }}
                    >
                      Update</Button>

                  </div>
                </section>
              }

              <section aria-labelledby="information-heading" className="mt-3">
                <div className="border-t border-gray-200 mt-3 pt-3">
                  <h4 className="block text-sm font-bold text-gray-900">Delivery Status</h4>

                  <div className="mt-2 prose prose-sm whitespace-pre-line">
                    <Markdown>{shippingStatus}</Markdown>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  )
}

export default InventoryPopup;