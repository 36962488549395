import Customise from '../components/Customise';

const CustomisePage = () => {
  return (
    <div>
      <Customise />
    </div>
  )
}

export default CustomisePage;