import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import Web3 from 'web3';
import MetamaskButton from '../MetamaskButton';
import { publicMintDate } from './mintDate';
import { useAuth } from '../../context/Auth';
import SocialButton from '../SocialButton';

/**
 * Variable update before deploy
 */
const isoroomContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const priceInWei = 0.0888 * 10 ** 18;
const startTime = publicMintDate;

let counterInterval;
let startedInterval;

const TimerBox = ({ number, type }) => {
  return (
    <div
      className="rounded-md bg-white flex flex-col justify-center items-center font-bold w-16 h-20 shadow-lg mr-2"
    >
      <p style={{ fontSize: 36, lineHeight: 1 }}>{number}</p>
      <div>{type}</div>
    </div>
  )
}

const TopBanner = () => {
  const { connected, connectWallet } = useAuth();

  const [warning, setWarning] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalSupply, setTotalSupply] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  // Set 1 to show counter as default
  const [countDown, setCountDown] = useState(1);
  const [qty, setQty] = useState(1);
  const [started, setStarted] = useState(2);

  const getContract = () => {
    const web3 = new Web3(window.web3.currentProvider);
    const isoroomContract = require("../../utils/contracts/Isoroom.json");
    const isoroomContractConnection = new web3.eth.Contract(isoroomContract.abi, isoroomContractAddress);
    
    return isoroomContractConnection;
  }

  const resetState = () => {
    setWarning(null);
    setSuccessMessage(null);
    setLoading(null);
  }

  const updateCounter = () => {
    const secDiff = dayjs().diff(dayjs(startTime)) * -1;
    if (secDiff < 0) {
      clearInterval(counterInterval);
      return setCountDown(0);
    }
    setCountDown(secDiff);
  }

  const updateContractStarted = async () => {
    const isoroomContractConnection = getContract();
    let stage = await isoroomContractConnection.methods.activeStage().call();
    setStarted(stage == 2);

    if (stage == 2 && startedInterval) {
      clearInterval(startedInterval);
    }
  }

  const updateTotalSupplyAndBalance = async (address) => {
    const isoroomContractConnection = getContract();
    let ts = isoroomContractConnection.methods.totalSupply().call();
    let boa = isoroomContractConnection.methods.balanceOf(address).call();

    ts = await ts;
    boa = await boa;
    setTotalSupply(ts);
    setWalletBalance(boa);
  }

  const _connectWallet = async () => {
    try {
      resetState();
      setLoading(true);
      await connectWallet()
    } catch (e) {
      setWarning(e.message);
    } finally {
      setLoading(false);
    }
  }

  const mintPublicNow = async (address,qty) => {
    try {
      resetState();
      setLoading(true);

      const isoroomContractConnection = getContract();
      
      // 1.25x
      const gas = qty === 1 ? 187478 : 331733;

      await isoroomContractConnection.methods
        .genesisMint(qty)
        .send({
          from: address,
          value: `${priceInWei * qty}`,
          gas,
        });

      setSuccessMessage('Minting success, welcome to isofam! LFG!');
      updateTotalSupplyAndBalance(address);
    } catch (e) {
      setWarning('Wallet Connection Error: ' + e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    updateCounter();
    counterInterval = setInterval(updateCounter, 1000);

    return () => {
      clearInterval(counterInterval);
      clearInterval(startedInterval);
    }
  }, [])

  useEffect(() => {
    if (startedInterval) 
      clearInterval(startedInterval);
    
    if (connected == null) return;
    
    updateContractStarted(); // Init call
    updateTotalSupplyAndBalance(connected);
    startedInterval = setInterval(
      () => updateContractStarted(), 1000);
  }, [connected])

  const seconds = Math.floor((countDown / 1000) % 60);
  const minutes = Math.floor((countDown / 1000 / 60) % 60);
  const hours = Math.floor((countDown / (1000 * 60 * 60)) % 24);
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));

  const inStock = 3000 - totalSupply > 0;

  return (
    <div
      className="mt-10 p-4 rounded-xl min-h-36 shadow-lg bg-gradient-to-r from-orange-500 to-amber-600"
      style={{ width: '50%', minWidth: 320 }}
    >
      {countDown > 0 &&
        <div>
          <p className="font-bold text-white mb-2">WHITELIST SALE: 16th January 00:00 UTC</p>
          <div className="flex justify-start">
            <TimerBox number={days} type="Days" />
            <TimerBox number={hours} type="Hours" />
            <TimerBox number={minutes} type="Min" />
            <TimerBox number={seconds} type="Sec" />
          </div>
        </div>
      }
      {
        countDown == 0 &&
        <div>
          <p className="text-white font-bold mb-2">🎈 3000 / 3000 isorooms sold out!</p>
          <p className="text-white font-bold mb-2 flex flex-row items-center">Buy on <SocialButton type="opensea"/> now!</p>
          {
            // connected === null ?
            //   <MetamaskButton loading={loading} onClick={_connectWallet} />
            //   :
            //   <div className="bg-white rounded-md p-2">
            //     <p className="font-bold">Hi, {connected.substring(0, 10)}...</p>

            //     <p className="font-bold mt-4 mb-1 text-sm">
            //       Minted: {totalSupply}/3000
            //     </p>
            //     <p className="font-bold mb-1 mt-1 text-sm">
            //       You owned: {walletBalance} isorooms
            //     </p>
            //     <p className="font-bold mb-4 mt-1 text-sm">
            //     Price: 0.0888 ETH + gas
            //     </p>

            //     {
            //       (started && inStock) ?
            //         <>
            //           <p className="font-bold">Select mint quantity (Max 2)</p>
            //           <div className="flex flex-row mt-4">
            //             <div className="w-24">
            //               <Select
            //                 value={{ label: qty, value: qty }}
            //                 placeholder="Qty"
            //                 style={{ width: 20 }}
            //                 onChange={(v) => setQty(v.value)}
            //                 options={[
            //                   { label: 1, value: 1 },
            //                   { label: 2, value: 2 },
            //                 ]}
            //               />
            //             </div>
            //             <button
            //               disabled={loading}
            //               onClick={() => mintPublicNow(connected, qty)}
            //               className={`
            //                 relative ml-4
            //                 text-white text-lg leading-6 font-semibold py-2 px-4 border border-transparent
            //                 rounded-xl focus:ring-2 focus:ring-offset-2
                            
            //                 bg-gradient-to-r from-orange-800 to-red-700
                            
            //                 transition-all duration-400 ease-in-out  hover:from-red-700 hover:to-orange-800
            //                 focus:ring-offset-white focus:ring-red-900 focus:outline-none
            //                 shadow-sm hover:shadow-xl

            //                 active:red-800
            //               `}
            //             >{
            //                 loading ? 'Loading...'
            //                   : `Mint ${qty || ''} Now!`
            //               }</button>
            //           </div>
            //           {successMessage !== null 
            //             && <p className="text-green-900 mt-2 font-bold">{successMessage}</p>}

            //         </>
            //         :
            //         <div />
            //     }
            //   </div>
          }
          {warning !== null && <p className="text-red-900 mt-2 font-bold break-all">{warning}</p>}
        </div>
      }
    </div>
  )
}

export default TopBanner;