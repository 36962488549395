import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import PortalContainer from '../../sections/PortalContainer';
import Apiv2 from '../../services/Apiv2';
// @ts-ignore
import * as humanFormat from 'human-format';
import ListHeader from '../../components/store/ListHeader';
import Strapi from '../../services/Strapi';

const REACT_APP_ETHPLORER_URL =  process.env.REACT_APP_ETHPLORER_URL
const REACT_APP_ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;

interface isoTokenInfoPorps {
  address: string;
  decimals: string;
  name: string;
  owner: string;
  symbol: string;
  totalSupply: string;
  transfersCount: number;
  lastUpdated: number;
  issuancesCount: number;
  holdersCount: number;
  ethTransfersCount: number;
  price: boolean;
  countOps: number;
}

const PortalBridge = () => {
  const alert = useAlert();
  const [data, setData] = useState<any>({});
  const [isoTokenInfo, setIsoTokenInfo] = useState<isoTokenInfoPorps | null>(null);
  const [storeAvgPrice, setStoreAvgPrice] = useState(0);

  const fetchData = async () => {
    try {
      const result = await Apiv2.get('analytics');
      setData(result.data);
    } catch (e: any) {
      alert.error(e.message);
    }
  }

  const fetchIsoTokenInfo = async () => {
    const url = `${REACT_APP_ETHPLORER_URL}/getTokenInfo/${REACT_APP_ISOTOKEN_ADDRESS}?apiKey=freekey`
    const response = await fetch(url);
    const result = await response.json();
    setIsoTokenInfo(result)
  }

  const fetchProducts = async () => {
    try {
      let url = 'store-products?';
      url += '&pagination[limit]=100&sort[0]=id%3Adesc';
      const result = await Strapi.get(url);
      const sum = result.data.reduce((pv: any, cv: any) => pv + cv.attributes.price, 0)
      setStoreAvgPrice(sum / result.data.length)
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  useEffect(() => {
    fetchData();
    fetchIsoTokenInfo();
    fetchProducts();
  }, []);

  let balanceSum = 0;
  const balanceStat = Object.keys(data?.balance || {}).map(key => {
    const stat = parseInt(data.balance[key])
    balanceSum += stat;
    return {
      name: key,
      stat,
    }
  });

  const totalSupplyStat = [
    {
      name: '$ISO Total Supply',
      stat: parseInt(data.totalSupply),
    },
    {
      name: 'isoroom owned supply',
      stat: balanceSum,
    },
    {
      name: 'isoroom owned percentage',
      stat: balanceSum / data.totalSupply * 100,
      symbol: '%',
    },
  ];

  const isoTokenStat = [
    {
      name: 'Holders',
      stat: isoTokenInfo?.holdersCount,
    },
    {
      name: 'Holder avg $ISO',
      stat: (parseInt(data.totalSupply) - balanceSum) / (isoTokenInfo?.holdersCount || 1) - 3,
    },
  ];

  const isoStoreStat = [
    {
      name: 'Last 100 whitelist avg price',
      stat: storeAvgPrice,
    }
  ]

  return (
    <PortalContainer>
      <h1 className="text-2xl font-bold text-gray-900">Analytics</h1>
      <p className="mt-1 text-sm text-gray-500">
        Data about iso.
      </p>


      <h3 className="text-lg mt-6 text-gray-900">Total supply</h3>
      <dl className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-4">
        {totalSupplyStat.map((item) => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{humanFormat(item.stat || 0)}{item.symbol || ''}</dd>
          </div>
        ))}
      </dl>

      <h3 className="text-lg mt-6 text-gray-900">Balance owned by isoroom</h3>
      <dl className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-4">
        {balanceStat.map((item) => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{humanFormat(item.stat || 0)}</dd>
          </div>
        ))}
      </dl>

      <h3 className="text-lg mt-6 text-gray-900">ISO Token stat</h3>
      <dl className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-4">
        {isoTokenStat.map((item) => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{humanFormat(item.stat || 0)}</dd>
          </div>
        ))}
      </dl>

      <h3 className="text-lg mt-6 text-gray-900">isostore stat</h3>
      <dl className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-4">
        {isoStoreStat.map((item) => (
          <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">{humanFormat(item.stat || 0)}</dd>
          </div>
        ))}
      </dl>
    </PortalContainer>
  );
}

export default PortalBridge;
