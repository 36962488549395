import React, { useEffect, useState } from 'react';
import { Fireworks } from 'fireworks/lib/react'
import gift20220507Json from '../../utils/gift/20220507.json';
import Button from '../Button';
import { useAlert } from 'react-alert';
import Strapi from '../../services/Strapi';
import ModalWrapper from '../general/ModalWrapper';
import Badge from '../Badge';

let fxProps = {
  count: 3,
  interval: 300,
  colors: ['#CE2029', '#FFFCAF', '#FFE17C', '#FF664B', '#903843'],
  calc: (props, i) => ({
    ...props,
    x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
    y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
  })
}

const tokenExhcnage = {
  "batch": "gift-20220507",
  "reward_key": "500_iso_token_exchange",
  "name": "500",
  "type": "iso_token",
  "amount": 1000,
  "link": "https://twitter.com/isoworldnft",
  "image": "https://pbs.twimg.com/profile_images/1455005125387243520/pt3qfKo9_400x400.jpg"
}

const ConnectionModal = ({ address, batchId, open, onClose, roomIds = [], claimable }) => {
  const alert = useAlert();
  const [message, setMessage] = useState(null);
  const [fire, setFire] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showGifts, setShowGifts] = useState(false);
  const [discordName, setDiscordName] = useState({});
  const [claimDiscordName, setClaimDiscordName] = useState({});
  const [claimAddress, setClaimAddress] = useState({});
  const [exchangeGift, setExchangeGift] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async ({
    all_gifts,
    discord_name,
    claim_discord_name,
    claim_address,
    address,
    batch,
    exchangeGift,
  }) => {
    try {
      setLoading(true);
      setMessage(null);

      for (let i = 0; i < all_gifts.length; i++) {
        const thisGift = all_gifts[i];
        const data = {
          data: {
            address: claim_address[thisGift.roomId]?.toLocaleLowerCase() || address?.toLocaleLowerCase(),
            discord_name: claim_discord_name[thisGift.roomId] || discord_name,
            batch,
            token_id: thisGift.roomId,
            reward_key: exchangeGift[thisGift.roomId] ? tokenExhcnage.reward_key : thisGift.reward_key,
            meta: exchangeGift[thisGift.roomId] ? tokenExhcnage : thisGift,
          }
        };

        await Strapi.post(`redemptions`, JSON.stringify(data));
      }
      setMessage('All done! please check the announcement after the gift room is closed for more redemption details. You can update the discord ID anytime before the deadline. If you have any questions please submit a ticket on Discord.');
      setSubmitted(true);
      alert.success('Submitted');
      // onClose();
    } catch (e) {
      alert.error(e.toString());
      setMessage(e.toString());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      setFire(true);
      setShowGifts(false);
      setSubmitted(false);
      setMessage(null);
    }

    setTimeout(() => {
      setShow(open);
    }, 100)

    setTimeout(() => {
      setFire(false);
    }, 5000);

    setTimeout(() => {
      setShowGifts(true);
    }, 1000)
  }, [open]);

  /**
   * Find out if any WL duplicated, allow user to input more than 1 address
   */
  let allGifts = roomIds.map(id => ({
    roomId: id,
    ...gift20220507Json[id]
  }));
  const duplicatedWLCount = {};
  allGifts.forEach(g => {
    if (g.type !== 'whitelist') return;
    if (!duplicatedWLCount[g.name]) {
      duplicatedWLCount[g.name] = 1;
    } else {
      duplicatedWLCount[g.name] += 1;
    }
  });
  allGifts = allGifts.filter(item => item.name);

  let highestWL = 0;
  Object.keys(duplicatedWLCount).forEach(k => {
    if (duplicatedWLCount[k] > highestWL) highestWL = duplicatedWLCount[k];
  })

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white p-4" style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
          <div className="text-left w-full">
            <img
              className="absolute right-4 top-4 hover:opacity-50 transition duration-150 ease-in-out cursor-pointer"
              width="20"
              src="/close-svgrepo-com.svg"
              onClick={onClose}
            />
            <p className="text-xl font-bold"><b>{allGifts.length > 0 ? 'Congratulations! ' : ''}You won {allGifts.length} gift{allGifts.length > 1 ? 's' : ''}</b></p>

            <div className="my-3 bg-orange-100 rounded-lg p-2">
              <Badge mode="yellow">Announcement!</Badge>
              <br />
              <a href="https://discord.com/channels/903843953760165898/941254707157037056/972521814838353931"
                className="font-bold underline mt-2 ml-2" target="_blank">
                New gift room announcement on Discord. Click here to learn more.
              </a>
            </div>

            <div>
              <p className="text-sm my-2">Please input your discord name to claim the gifts. You can update your discord name multiple times, if you sell your isoroom the new owner can retrieve your gift.</p>
              <p className="text-sm my-2">Remember to keep your isoroom staked to ensure the gift can be sent. We will verify after the gift room is closed. For details please check on the Discord announcement.</p>
            </div>

            {
              !submitted &&
              <div className={`${showGifts ? 'opacity-100' : 'opacity-0'} transition duration-700 ease-in-out`}>
                {
                  allGifts.map(item => (
                    <div className="px-4 py-2 bg-gray-50 hover:bg-gray-100 mb-1 rounded-md">
                      <div key={item.roomId} className="flex flex-row items-center">
                        <a href={item.link} target="_blank"><img src={item.image} className="rounded-full w-12 h-12 mr-2" /></a>
                        <div>
                          <p className="text-xs">
                            Room: <b>{item.roomId} </b>
                            | Type: <b>{item.type === 'iso_token' ? '$ISO Token' : item.type === 'nft' ? 'NFT' : 'Whitelist'}</b>
                            | Mint Day: <b>{item.mint_date ? item.mint_date : 'N/A'}</b>
                          </p>
                          <p className="text-xs">
                            Discord: <b>{item.discord && item.discord !== 'Closed Discord' ? <a href={item.discord} className="underline">Click here (please check on twitter if closed)</a> : 'Not opening'}</b>
                          </p>
                          <p className="mt-2">
                            <b className={`${exchangeGift[item.roomId] ? 'line-through' : ''}`}>
                              Gift: {item.name} {item.type === 'iso_token' ? '$ISO Token' : item.type === 'nft' ? 'NFT' : 'Whitelist'}</b>
                            {exchangeGift[item.roomId] && <b className="ml-2">500 $ISO</b>}
                          </p>
                        </div>
                      </div>
                      {
                        item.type === 'whitelist' &&
                        <div>
                          <div className="flex flex-row mt-4">
                            <div>
                              <label class="block text-gray-700 text-xs font-bold" for="address">
                                Wallet address
                              </label>
                              <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="address"
                                type="text"
                                placeholder="address"
                                onChange={(e) => setClaimAddress(ori => ({ ...ori, [item.roomId]: e.target.value }))}
                              />
                            </div>
                            <div>
                              <label class="block text-gray-700 text-xs font-bold" for="discord">
                                Discord Name
                              </label>
                              <input
                                onChange={(e) => setClaimDiscordName(ori => ({ ...ori, [item.roomId]: e.target.value }))}
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="discord" type="text" placeholder="discord"
                              />
                            </div>
                          </div>

                          <div className="mt-2 flex items-center">
                            <input
                              type="checkbox"
                              id={`${item.roomId}-trade`}
                              onChange={(e) => setExchangeGift(ori =>
                                ({ ...ori, [item.roomId]: e.target?.checked }))}
                            />
                            <label className="ml-2 text-xs font-bold" htmlFor={`${item.roomId}-trade`}>Not my cup of tea! Exchange for 500 $ISO</label>
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            }
            {
              (claimable && !submitted) &&

              <div className="p-2 bg-gray-50 mt-2 rounded-md">
                <div className="flex flex-col">
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="discord">
                      Your Discord Name on isoroom server
                    </label>
                    <input
                      onChange={(e) => setDiscordName(e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="discord" type="text" placeholder="discord"
                    />
                  </div>
                  {/* {
                      highestWL > 1 &&
                      <p className="font-bold">* You won more than 1 whitelist on same project, please create a ticket on Discord and tag @isostan for extra whitelist submission.</p>
                    } */}
                </div>
                <Button
                  disabled={loading}
                  style={{ width: '100%' }}
                  onClick={() => onSubmit({
                    all_gifts: allGifts,
                    discord_name: discordName,
                    claim_address: claimAddress,
                    claim_discord_name: claimDiscordName,
                    address,
                    batch: batchId,
                    exchangeGift,
                    // token_id: roomIds,
                    // reward_key: allGifts.map(item => item.name + ' ' + item.type),
                    // meta: allGifts,
                  })}
                >{loading ? 'Loading...' : 'Submit'}</Button>
              </div>
            }
            {
              message && <p className="text-md font-bold mt-1 mb-4 text-red-900">{message}</p>
            }
            <p className="text-xs font-bold">
              [{batchId}] is <a className="underline" href="https://isoroom.mypinata.cloud/ipfs/QmVTBCxq9Yg4XhMoeV41JLEsXxWikFWRsUQDmGCGH17t9o" target="_blank">randomized</a>, <a className="underline" href="https://etherscan.io/tx/0xe6a8fba6db7d4f315e18bfda3ec21beae8fe02500a29831a1ba3a3120ef92d47" target="_blank">hashed</a> and <a className="underline" href="https://etherscan.io/tx/0x39ac9263cd9798b248ed5b2b5645bbf0881e946a378b5d8cb95f4993d6b21d05#eventlog" target="_blank">shifted</a> on isoroom contract to prove fairness. This is the <a className="underline text-red-700" href="https://isoroom.mypinata.cloud/ipfs/QmPB8sLDh4uzzSCTcSa4BZNM1hGMtSbtTUb8CLjX9S2xFC" target="_blank">final result</a>.
            </p>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConnectionModal;