import { useRef, useEffect, useState, useCallback } from "react";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { BigNumber } from 'ethers';
// @ts-ignore
import * as humanFormat from 'human-format';
import MenuModal from './MenuModal';

interface ProfileShortcutProps {
  /** Connected wallet address, null if not connected */
  address: string | null;
  /** User balance on token */
  balance: {
    [coin: string]: string
  };

  className?: string;
}

const dp = BigNumber.from(10).pow(18);

const getTotalBal = (bal: any) => {
  let result = BigNumber.from(0);

  Object.keys(bal).map(key => {
    result = result.add(BigNumber.from(bal[key]))
  })

  return result.div(dp).toString();
}

/**
 * Show user's token balance and extend to profile menu
 */
const ProfileShortcut: React.FC<ProfileShortcutProps>
  = ({ address, balance, ...props }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const iso = getTotalBal(balance);

    return (
      <div {...props} className={['relative', props?.className].join(' ')}>
        <button className="
        w-full px-4 py-1 bg-white
        flex justify-center items-center rounded-full shadow cursor-pointer
        hover:bg-gray-50 active:bg-gray-100
        focus:outline-none focus:ring focus:ring-offset-2 focus:ring-orange-600
        transition duration-150 ease-in-out
        "
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {
            address !== null ?
              <>
                <div className="mr-2 h-6 flex items-center">
                  <Jazzicon
                    diameter={25}
                    seed={jsNumberForAddress(address)}
                  />
                </div>
                <div>
                  <p className="font-bold text-sm select-none">
                    {humanFormat(parseInt(iso)) || 0} ISO + eISO
                  </p>
                </div>
              </>
              :
              <p className="h-6 font-bold text-sm select-none">Connect</p>
          }
        </button>

        {
          address !== null &&
          <div className="absolute w-64 bottom-10 left-0">
            <MenuModal
              show={menuOpen}
              onClose={() => setMenuOpen(false)}
              address={address}
            >
              {
                Object.keys(balance).map(key => (
                  <div className="py-1 flex flex-row items-center">
                    <img src="https://isoroom.io/logo-06.png" width="20" height="20" />
                    <span className="ml-2 font-bold">
                      {key} {humanFormat(
                        BigNumber.from(balance[key]).div(dp).toNumber(), { decimals: 4 }) || 0}
                    </span>
                  </div>
                ))
              }

            </MenuModal>
          </div>
        }
      </div>
    )
  }

export default ProfileShortcut;