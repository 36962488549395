import styled from 'styled-components';
import { Button, CircledButton, SIZES } from '../../styles/styled/shared';

export const NavbarStyled = styled.div`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;

  .navbar {
    position: relative;
    z-index: 5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media (max-width:${SIZES.mobile}) {
      justify-content: center;
    }

    .logo {
      padding: 2rem 0;
      color: white;
      font-weight: bold;
      font-size: 32px;

      &__iso {
        height: 60px;
      }
    }

    .dropdown-icon {
      margin-left: 5px;
      color: red;
    }

    &__menu {
      padding: 2rem 0;
      display: flex;
      align-items: center;

      @media (max-width:${SIZES.mobile}) {
        display: none;
      }

      .dropdown {
        position: relative;

        &__button {
          ${Button}
        }

        &__text {
          display: flex;
          align-items: center;
        }

        &__icon {
          transition: all 200ms;
        }

        &__content {
          position: absolute;
          z-index: 5;
          left: 0;
          margin: 0;
          width: 120px;
          top: calc(100% + .25rem);
          background: white;
          padding: .75rem 1rem;
          border-radius: .25rem;
          box-shadow: 4px 2px 17px -5px rgba(0,0,0,0.5);
          opacity: 0;
          pointer-events: none;
          transform: translateY(-10px);
          transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

          &--active {
            opacity: 1;
            transform: translateY(0);
            pointer-events: unset;
          }

          li {
            list-style-type: none;
            cursor: pointer;
            font-weight: 500;
            color: #8c8c8c;
            margin-bottom: .75rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__button {
      ${Button}
    }

    &__connect-btn {
      ${CircledButton}
      margin-left: 2rem;
    }
  
  }
`