import isoTokenContract from "../contracts/IsoToken.json";

const ISOTOKEN_ADDRESS = process.env.REACT_APP_ISOTOKEN_ADDRESS;

const ADDRESS_ISOESCROW = process.env.REACT_APP_ADDRESS_ISOESCROW;
const ADDRESS_ISOSTORE = process.env.REACT_APP_ADDRESS_ISOSTORE;

const prepareTransfer = async (
  web3: any,
  from: string,
  toType: 'escrow' | 'store',
  value: string,
  callback: any
) => {
  // C For contract
  const to = toType === 'escrow' ? ADDRESS_ISOESCROW : ADDRESS_ISOSTORE;
  const IsoTokenC = new web3.eth.Contract(isoTokenContract.abi, ISOTOKEN_ADDRESS);
  const gasLimit = await IsoTokenC.methods
    .transfer(to, value).estimateGas({ from });
  const gasPrice = await web3.eth.getGasPrice();

  const params = {
    from,
    gasLimit: gasLimit,
    gasPrice: Math.floor(gasPrice * 1.2),
  }

  IsoTokenC.methods.transfer(to, value).send(params)
    .on('transactionHash', callback.transactionHash)
    .on('confirmation', callback.confirmation)
    .on('error', callback.error)
};

export default prepareTransfer;