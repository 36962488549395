import MetamaskButton from './MetamaskButton'

const ConnectSection = ({
  loading,
  connected,
  disconnect,
  connectWallet,
}) => {
  return (
    <div className='max-w-24 m-x-auto flex flex-col items-center justify-center' style={{ height: '100vh', maxWidth: 480, margin: '0 auto' }}>
      {!loading && <p className="font-semibold mb-4">Connect your wallet to enter isoportal!</p>}

      <div
        className="w-full h-48 rounded-lg shadow-4xl flex justify-center items-center"
        style={{ background: 'url(/image/preview.png) center center / cover' }}
      >
        <MetamaskButton
          address={connected}
          onClick={() => connected ? disconnect() : connectWallet()}
        />
      </div>
      <a href="/" className="text-sm font-bold underline">🔙 Back to isoroom.io</a>
    </div>
  )
}

export default ConnectSection;