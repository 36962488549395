

import { FaCheckCircle, FaImage } from 'react-icons/fa';
import { LandingPageStyled, RoadMapWrapper, TeamMemberWrapper, LandingTextStyled } from '../styles/styled/landingPage';
import '../styles/landing.css';

import Navbar from "../components/Navbar";
import cx from "classnames";
import { Parallax } from '../components/Parallax';
import WhitelistMintSecion from '../components/minting/WhitelistMintSection';
import { SIZES } from '../styles/styled/shared';
import { useEffect, useState } from 'react';

const LandingPage = (props) => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= parseInt(SIZES.mobile.replace('px', ''))) {
      setIsSmallScreen(true)
    }
  }, [])

  return (
    <LandingPageStyled>

      <Navbar />

      <WhitelistMintSecion />
      <Parallax />

      <div className="backdrop_text">
        <h1 className="text">isoworld</h1>
      </div>

      <div className="app-container mx-auto p-4">
        <LandingTextStyled>
          <div className="landing">
            <h1>isoworld</h1>

            <div className="text-content">
              <p>We all love NFT! We love the art, we love the culture,</p>
              <p>and of course it's the community that we love! Surrounded</p>
              <p>by a group of NFT investors and enthusiasts, we discover</p>
              <p>that there is a common need among them, which is to show</p>
              <p>and share their favourite NFT collections in a more stylish</p>
              <p>and interactive way.</p>
            </div>

            <div className="text-content-mobile">
              We all love NFT! We love the art, we love the culture,
              and of course it's the community that we love! Surrounded
              by a group of NFT investors and enthusiasts, we discover
              that there is a common need among them, which is to show
              and share their favourite NFT collections in a more stylish
              and interactive way.
            </div>

            <hr />

            <div className="landing__btns">
              {/* <button type="button">connect</button> */}
              <button type="button" onClick={() => window.open('https://isoroom.gitbook.io/isoworld/', '_blank')}>
                whitepaper</button>
            </div>
          </div>
        </LandingTextStyled>

        <h1 className="opaque_text" id="#roadmap">roadmap</h1>

        <div className="roadmap">
          {roadmapData.map((step, index) => {
            const unique = [2, 3];
            return (
              <RoadMapWrapper
                key={index}
                direction={index % 2 == 0 ? "row" : "row-reverse"}
                image={step.stepImage}
                imageMargin={step.imageMargin}
                backgroundImage={step.background}
                background={step.background_color}>
                <div className="text">
                  <div className="title">
                    <p>{step.title}</p>
                    {step.hasCheck ? <CheckIconWhiteMiddle /> : null}
                  </div>
                  <p className="desc">{step.description}</p>
                </div>
                <div
                  className={cx('image-box', {
                    'unique': unique.includes(index)
                  })}
                  style={index + 1 == roadmapData.length
                    ? { paddingLeft: '4rem' }
                    : {}
                  }>
                  <img
                    title={`roadmap-step-${index}`}
                    loading='lazy'
                    alt="step.title"
                    src={`/landing/${step.stepImage}`} />
                </div>
              </RoadMapWrapper>
            )
          })}
        </div>

        <div className="welcome">
          <p className="title">7/ isoworld Game</p>
          <p className="desc">
            Holders will be able to mint the isoland with $ISO and start building isohouses to earn some more $ISO (Build to earn). There are some more interesting gameplays such as Disaster Attack (Attack to earn) and Tourism (Connect to earn).
          </p>

          <div className="welcome__btns">
            {/* <button type="button">
              connect
            </button> */}
            <a href="https://isoroom.gitbook.io/isoworld/">
              <button type="button">
                whitepaper
              </button>
            </a>
          </div>
        </div>

        <h1 className="opaque_text mt-10">team</h1>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-12 teams">
          {teamData.map((team, index) => (
            <div
              key={index}
              className="col-span-1 md:col-span-4 overflow-hidden">
              <a href={team.href} target="_blank">
                <TeamMemberWrapper background={team.background_color}>
                  <div className="role text-md">
                    <p>{team.role}</p>
                  </div>

                  <div className="center-image">
                    <img
                      title={`iso-team-${index}`}
                      loading="lazy"
                      alt={team.title}
                      src={`/landing/${team.image}`} />
                  </div>

                  <div className="username">
                    <div className="logo">
                      <FaImage color="white" size={13} />
                    </div>
                    <p>{team.username}</p>
                  </div>
                </TeamMemberWrapper>
              </a>
            </div>
          ))}
        </div>
        <div className="text-center mt-3">
          <a
            href="https://isoroom.gitbook.io/isoworld/team"
            target="_blank"
            className="font-bold underline"
          >
            More team members on whitepaper!
          </a>
        </div>

        <div className="footer">
          <p className="text-xs">© 2022 isoroom. All rights reserved</p>
        </div>

      </div>
    </LandingPageStyled>
  )
}

const CheckIconWhiteMiddle = () => {
  return (
    <div className="check-icon">
      <div className="white-mid"></div>
      <FaCheckCircle
        size={50}
        color="#5bd8ab" />
    </div>
  )
}

const roadmapData = [
  {
    title: "1/ Genesis isoroom (SOLD OUT) - ETH",
    description: "For Genesis isoroom, the total supply is 3,000 and there are 2,000 holders and 90% of rooms are staked to earn $ISO. There are cool bedrooms and workrooms in the Genesis isoroom collection.",
    background_color: "#041f40",
    dummy_image_color: "#313a55",
    hasCheck: true,
    background: "bg7.png",
    stepImage: "1.png",
    imageMargin: "-9.5rem"
  },
  {
    title: "2/ isogiftroom",
    description: " is where we provide weekly giftboxes to our holders (Whitelists, isoCoupons, $ISO Tokens and more). For one room holder, you may only need around 1 - 1.5 month to get a Gold Tier Whitelist such as BBRC, Mindblowon, Just Ape etc.",
    background_color: "#f39c21",
    dummy_image_color: "#d89e16",
    hasCheck: true,
    background: "bg6.png",
    stepImage: "2.png",
    imageMargin: "-9.5rem"
  },
  {
    title: "3/ isoroom Customization",
    description: "You can connect your wallet and choose which NFTs to be showcased in your 3D rendered isoroom.",
    background_color: "#e34a4d",
    dummy_image_color: "#6e1718",
    hasCheck: true,
    background: "bg5.png",
    stepImage: "3.png",
    imageMargin: '0'
  },
  {
    title: "4/ isostore",
    description: "There will be 0 gas fee to rob our marketplace. And you can rob the whitelists in isostore with $eISO and isocoupons.",
    background_color: "#77ef7f",
    dummy_image_color: "#528ac8",
    hasCheck: true,
    background: "bg4.png",
    stepImage: "4.png",
    imageMargin: '-1.2rem'
  },
  {
    title: "5/ Utility isoroom (Gen 2)",
    description: "For Utility isoroom, the total supply is 6,000 and holders can also stake and earn $ISO. There are cute kitchens and activity rooms in the Utility isoroom collection. It is required to build the Utility isohouse and launch disaster attack in the isoworld game.",
    background_color: "#081c1a",
    dummy_image_color: "#d0a864",
    hasCheck: false,
    background: "bg3.png",
    stepImage: "5.png",
    imageMargin: "-9.5rem"
  },
  {
    title: "6/ Royal isoroom (Gen 3)",
    description: "For Royal isoroom, the total supply is 1,500 and holders can mint with $ISO. It maximizes the isoworld in-game staking earning rate by maximising the population multiplier.",
    background_color: "#081c1a",
    dummy_image_color: "#d0a864",
    hasCheck: false,
    background: "bg2.png",
    stepImage: "6.png",
    imageMargin: "-7rem",
  },
]

const teamData = [
  {
    background_color: "#ec6543",
    role: "architect",
    username: "isoroom.sam (@iso_sam)",
    image: '7.png',
    href: 'https://twitter.com/iso_sam'
  },
  {
    background_color: "#2c60ab",
    role: "blockchain expert",
    username: "isoroom.stan (@stanxxxc)",
    image: '8.png',
    href: 'https://twitter.com/stanxxxc'
  },
  {
    background_color: "#c57be7",
    role: "architect",
    username: "isoroom.nd (@isoandy_)",
    image: '9.png',
    href: 'https://twitter.com/isoandy_'
  },
]

export default LandingPage;
