import Rect, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import { TweenLite, Linear, ScrollTrigger, gsap } from 'gsap/all'
import { useState } from 'react';
import './style.css'


export const Parallax = () => {
  
  const [images, setImages] = useState([]);

  useEffect(() => {
    //create the image sequence
    gsap.registerPlugin(ScrollTrigger);

    const canvas = document.getElementById("hero-lightpass");
    const context = canvas.getContext("2d");
    const vw = (coef) => window.innerWidth * (coef/100)
    const vh = (coef) => window.innerHeight * (coef/100)

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const frameCount = 75;
    const currentFrame = index => ( `/parallax/landing${(index + 1).toString().padStart(4, '0')}.png`);

    const images = []
    const sequence = {
      frame: 0
    };

    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
    }

    let sequenceTL = gsap
      .timeline()
      .to(sequence, {frame: frameCount - 1, snap: "frame", onUpdate: render})

    ScrollTrigger.create({
      animation: sequenceTL,
      trigger: ".blue",
      start: 1,
      end: "center top",
      scrub: 1,
    });

    ScrollTrigger.create({
      trigger: ".blue",
      scrub: 1,
      pin: true,
      pinSpacing: true
    })

    images[0].onload = render;


    function render() {
      const img = images[sequence.frame];
      const scale = 1.05;
      const iw = img.width*scale;
      const ih = img.height*scale;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        img,
        canvas.width/2 - iw/2,
        // canvas.height/2 - ih/2,
        -80,
        iw,
        ih
      );
    };
   
  }, [])

  return (
    <div className="description panel blue">
      <canvas id="hero-lightpass" />
    </div>
  )
}

// export const Parallax = () => {
//   useEffect(() => {
//     const html = document.documentElement;
//     const canvas = document.getElementById("hero-lightpass");
//     const context = canvas.getContext("2d");
  
//     const frameCount = 75;
//     const currentFrame = index => (
//       `/parallax/landing${index.toString().padStart(4, '0')}.png`
//     )

//     console.log({ context })
  
//     const preloadImages = () => {
//       for (let i = 1; i < frameCount; i++) {
//         const img = new Image();
//         img.src = currentFrame(i);
//       }
//     };


//     const img = new Image()
//     img.src = currentFrame(1);
//     canvas.width=2000;
//     canvas.height=920;
//     img.onload=function(){
//       context.drawImage(img, 0, 0);
//     }


//     console.log({ img })
  
//     const updateImage = index => {
//       img.src = currentFrame(index);
//       // context.clearRect(0, 0, canvas.width, canvas.height);
//       context.drawImage(img, 0, 0);
//     }

//     window.addEventListener('scroll', () => {  
//       const scrollTop = html.scrollTop;
//       const maxScrollTop = html.scrollHeight - window.innerHeight;
//       const scrollFraction = scrollTop / maxScrollTop;
//       const frameIndex = Math.min(
//         frameCount - 1,
//         Math.ceil(scrollFraction * frameCount)
//       );
      
//       requestAnimationFrame(() => updateImage(frameIndex + 1))
//     });
  
//     preloadImages()
//   }, [])

//   return (
//     <section style={styles.container}>
//       <canvas 
//         id="hero-lightpass"
//         style={styles.canvas}/>
      
//     </section>
//   )
// }

const styles = {
  scrollElement: {
    position: "absolute",
    height: "6000px",
    width: "100px",
    top: "0",
    zIndex: "0",
    background: "blue"
  },
  container: {
    height: "200vh",
    background: '#000',
  },
  canvas: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100vw",
    maxHeight: "100vh",
  }
}