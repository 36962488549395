import { FC, useCallback, useEffect, useState } from 'react';
import InventoryItem, { InventoryItemProps } from '../../components/store/InventoryItem';
import InventoryPopup from '../../components/store/InventoryPopup';
import LootboxInventoryPopup from '../../components/store/LootboxInventoryPopup';
import ListHeader from '../../components/store/ListHeader';
import { useAuth } from '../../context/Auth';
import Strapi from '../../services/Strapi';

const PortalInventory:FC<any> = () => {
  const { connected, web3, signature, sigData } = useAuth();
  const [largeViewGrid, setLargeViewGrid] = useState(false);
  const [inventories, setInventories] = useState<any>([]);
  const [popupOpen, setPopupOpen] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [lootboxResult, setLootboxResult] = useState([]);

  const onRedemptionInfoUpdate = useCallback(async (
    id: string,
    name: string,
    redemptionAddress: string,
    ids: Array<number>
  ) => {
        
    try {
      if (id === null)
        throw new Error('Missing ID, please update discord name on inventory page.');

      if (name.length === 0)
        throw new Error('Missing Discord name.');

      if (redemptionAddress.length === 0)
        throw new Error('Missing wallet address.');

      const url = 'store-transactions/update/discord-name';
      const data = {
        sig_data: sigData,
        signature,
        discord_name: name,
        redemption_address: redemptionAddress,
        transaction_ids: ids
      };
      await Strapi.post(url, JSON.stringify(data));
      alert('Redemption information updated');
    } catch (e: any) {
      alert(e.message || e.toString);
    }
  }, [signature, sigData]);

  const onLootboxOpen = useCallback(async (id: string, discordName: string, addr: string) => {

    try {
      setLoading(true);
      const data = {
        sig_data: sigData,
        signature,
        transaction_id: id,
        discord_name: discordName,
        redemption_address: addr,
      }
      const result = await Strapi.post('store-lootbox-transactions/redeem', JSON.stringify(data));
      if (result.status === 'error') throw new Error(result.message);
      setLootboxResult(result.items);

      
      if (connected) fetchInventoryItems(connected);
    } catch (e: any) {
      alert(e.message || e.toString);
    } finally {
      setLoading(false);
    }
  }, [signature, sigData, connected]);

  const fetchInventoryItems = async (address: string) => {
    try { 
      const url = `store-product/inventory?wallet_address=${address}&page=1`;
      const res = await Strapi.get(url);
      setInventories(res.data);
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (connected) {
      fetchInventoryItems(connected);
    }
  }, [connected]);

  function closeModal() {
    setPopupOpen(null);
    setLootboxResult([])
  }

  return (
    <>
      <LootboxInventoryPopup
        open={popupOpen !== null && popupOpen?.type === 'lootbox'}
        onClose={closeModal}
        name={popupOpen?.name || ''}
        price={popupOpen?.price || 0}
        token={popupOpen?.token || ''}
        imageSrc={popupOpen?.imageSrc || ''}
        imageAlt={popupOpen?.imageAlt || ''}
        shippingStatus={popupOpen?.shippingStatus || popupOpen?.error || ''}
        twitter={popupOpen?.twitter || ''}
        redemption_address={popupOpen?.redemption_address || ''}
        discord_name={popupOpen?.discord_name || ''}
        description={popupOpen?.description}
        lootboxResult={lootboxResult}
        onLootboxOpen={(discordName: string, addr: string) => {
          if (!popupOpen.canOpen) return alert('Not ready to open yet!');
          /** 
           * should not be an issue anymore since after lootbox open,
           * it will refetch the updated list of inventories.
           * ----> if (popupOpen.status === 'completed') return alert('Already opened'); 
           */
          onLootboxOpen(popupOpen.transactions[0].id, discordName, addr)
        }}
        loading={loading}
      />

      <InventoryPopup
        open={popupOpen !== null && popupOpen?.type !== 'lootbox'}
        onClose={closeModal}
        name={popupOpen?.name || ''}
        price={popupOpen?.price || 0}
        token={popupOpen?.token || ''}
        imageSrc={popupOpen?.imageSrc || ''}
        imageAlt={popupOpen?.imageAlt || ''}
        shippingStatus={popupOpen?.shipping_status || ''}
        twitter={popupOpen?.twitter || ''}
        redemption_address={popupOpen?.redemption_address || ''}
        discord_name={popupOpen?.discord_name || ''}
        onRedemptionInfoUpdate={
          popupOpen?.type === 'inventory' ?
            (v: string, addr: string) => onRedemptionInfoUpdate(popupOpen.id, v, addr, popupOpen.transactions.map((p: any) => p.id))
            : null}
      />

      <main className="max-w-7xl mx-auto">
        <div
          className="rounded-lg"
          style={{
            background: `url('https://pbs.twimg.com/profile_banners/1496398461822029825/1651806413/1500x500') center center / contain`,
            height: 150,
            width: '100%',
            position: 'relative',
            marginBottom: 10,
          }}
        />

        <div className="mt-4">
          <ListHeader
            onShowAll={() => { }}
            header="Inventory"
            setMobileFiltersOpen={() => { }}
            onViewGridChange={() => setLargeViewGrid(!largeViewGrid)}
          />
          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Inventory
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
              <div className="lg:col-span-4">
                <div className={`grid grid-cols-${largeViewGrid ? '1' : '2'}
                  sm:grid-cols-${largeViewGrid ? '2' : '4'}
                  lg:grid-cols-${largeViewGrid ? '3' : '6'}`}>
                  { inventories.map((item: any, index: number) => (
                    <InventoryItem
                      key={index}
                      onClick={() => setPopupOpen(item)}
                      id={item.id}
                      imageSrc={item.imageSrc}
                      imageAlt={item.imageAlt}
                      name={item.name}
                      price={item.price}
                      token={item.token}
                      category={item.category}
                      description={item.description}
                      status={item.status}
                      qty={item.qty || 1}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

    </>
  );
}

export default PortalInventory;
