import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import EventEmitter from "./EventEmitter.js";

export default class Resources extends EventEmitter {
  constructor(roomData) {
    super();

    this.roomData = roomData;

    this.items = {};
    this.toLoad = 0;
    this.loaded = 0;

    this.setLoaders();
    this.startLoading();
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader();
  }

  startLoading() {
    // Load each source
    const roomType = this.roomData.type;
    const combination = this.roomData.combination;

    /**
     * Remove 0.gltf which we do not have
     */
    for (let key of Object.keys(combination)) {
      if (combination[key] === 0)
        delete combination[key];
    }

    this.toLoad = Object.keys(combination).length;

    for (const key in combination) {
      const type = key.substring(0, key.length - 3);
      const path = `https://df16ha7u1eog5.cloudfront.net/object/${roomType}/${type}/${combination[key]}.gltf`;

      this.loaders.gltfLoader.load(path, (file) => {
        this.sourceLoaded(type, file);
      });
    }
  }

  sourceLoaded(key, file) {
    this.items[key] = file;
    this.loaded++;

    if (this.loaded === this.toLoad) {
      this.trigger("ready");
    }
  }
}
