import React, { useState } from 'react';

export const SceneContext = React.createContext(null);

const SceneContextWrapper = ({ children }) => {
  const [scene, setScene] = useState(null);

  return (
    <SceneContext.Provider value={{ scene, setScene }}>
      {children}
    </SceneContext.Provider>
  )
}

export default SceneContextWrapper;