import { Dialog, Transition } from '@headlessui/react';
import { LockOpenIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import Checkbox from '../../Checkbox';
import Markdown from '../../Markdown';
import cx from 'classnames';
import Strapi from '../../../services/Strapi';
import { useAuth } from '../../../context/Auth';

interface ProductPopupProps {
  open: boolean;
  onClose: () => void;
  onPay: (type: string, couponId: any, qty: number) => void;
  id: any;
  type: string;
  name: string;
  price: number;
  token: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  highlights: string[];
  twitter: string;
  coupons: any[];
  maxQty: number;
}

/**
 * A template storybook
 */
const ProductPopup = ({
  open,
  onClose,
  onPay,
  id,
  type,
  name,
  price,
  token,
  description,
  imageSrc,
  imageAlt,
  highlights,
  twitter,
  coupons,
  maxQty,
}: ProductPopupProps) => {
  const [selectedCoupon, setSelectedCoupon] = useState<{ amount: number, label: string, value: number } | null>(null);
  const [tnc, setTnc] = useState(false);
  const [tncAlert, setTncAlert] = useState(false);
  const [qty, setQty] = useState<any>({ label: 1, value: 1 });
  const { connected } = useAuth()

  const couponOpt = coupons.map(i => ({ ...i, value: i.id, label: `${i.amount} $ISO coupon` }));

  const [limitQty, setLimitQty] = useState<number>(1);
  const [purchaseLeft, setPurchaseLeft] = useState<number>(0)
  const [purchaseLimitReached, setPurchaseLimitReached] = useState<boolean>(false);
  const [stakedRoomsCount, setStakedRoomsCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function getProductData() {
    setIsLoading(true);
    const params = `product_id=${id}&wallet_address=${connected}`;
    const data = await Strapi.get(`store-product/get?${params}`);

    setLimitQty(data.limit_qty);
    setPurchaseLeft(data.purchase_left);
    setStakedRoomsCount(data.staked_rooms);
    
    if (data.purchase_left == 0) {
      setPurchaseLimitReached(true)
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (type && type.toLowerCase() == 'limit') {
      getProductData()
    }
  }, [])

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
                  <div className="sm:col-span-4 lg:col-span-5">
                    <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                      <img src={imageSrc} alt={imageAlt} className="object-center object-cover" />
                    </div>
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="text-2xl font-extrabold text-gray-900 sm:pr-12">{name}</h2>

                    { twitter && (
                      <ul role="list" className="flex items-center space-x-6 mt-2">
                        <li>
                          <a href={twitter} className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500" target="_blank">
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    )}

                    <section aria-labelledby="information-heading" className="mt-3">
                      <div>
                        <h3 id="information-heading" className="block text-sm font-bold text-gray-900">Description</h3>
                        <Markdown>{description}</Markdown>
                      </div>

                      <div className="border-t border-gray-200 mt-3 pt-3">
                        <h3 id="information-heading" className="block text-sm font-bold text-gray-900">
                          Highlights
                        </h3>

                        <div className="prose prose-sm text-gray-500">
                          <ul role="list" className="text-sm list-disc ml-4">
                            {highlights.map((highlight) => (
                              <li key={highlight}>{highlight}</li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="mt-6">
                        <h3 id="information-heading" className="block text-sm font-bold text-gray-900">Price</h3>
                        <p className="font-bold">
                          <span className={`${selectedCoupon ? 'line-through mr-1' : ''}`}>{price}</span>
                          {selectedCoupon && `${price - selectedCoupon.amount}`}
                          <span className="ml-1">$ISO</span>
                        </p>
                      </div>
                    </section>

                    { purchaseLimitReached && stakedRoomsCount !== 0 && (
                      <p className=" mt-3 text-xs text-red-600 font-bold">
                        * You've purchased the maximum allowed per day.
                      </p>
                    )}

                    { type.toLowerCase() == 'limit' && stakedRoomsCount == 0 && (
                      <p className=" mt-3 text-xs text-red-600 font-bold">
                        * You have no staked rooms.
                     </p>
                    )}

                    <div className={cx("bg-gray-100 p-2 rounded-lg mt-6", {
                      'hidden': limitQty == 0 || isLoading || purchaseLimitReached
                    })}>
                      {
                        (coupons && coupons.length > 0) && (
                          <section className="mb-4">
                            <h3 id="information-heading" className="block text-sm font-bold text-gray-900">Coupon To Use</h3>
                            <Select
                              isClearable
                              value={selectedCoupon || {}}
                              placeholder="Select coupon"
                              onChange={(v: any) => setSelectedCoupon(v)}
                              options={couponOpt}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
                            />
                          </section>
                        )
                      }

                      { purchaseLeft !== 0 && (
                        <div className="mt-4">
                          <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={qty}
                            placeholder="Qty"
                            onChange={(v) => setQty(v)}
                            options={ new Array(purchaseLeft)
                              .fill(1)
                              .map((_, i) => ({ value: _ + i, label: _ + i }))
                            }
                          />
                          <span className="text-xs font-bold">
                            * You can buy {limitQty} per staking room
                          </span>
                        </div>
                      )}

                      <section>
                        <Checkbox
                          checked={tnc}
                          className="mb-4 ml-1"
                          textClassName={`${tncAlert ? 'text-red-700' : ''}`}
                          onChange={(e: any) => setTnc(e.target.checked)}
                          text="I understand once the transaction is started it cannot be reverted."
                        />
                        <div className="grid grid-cols-2 gap-2">
                          <button
                            onClick={() => tnc ? onPay('iso', selectedCoupon, qty.value) : setTncAlert(true)}
                            className={`bg-gray-200 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-bold text-gray-900 hover:bg-gray-300
                              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500
                              ${!tnc ? 'cursor-not-allowed' : ''}`}
                          >
                            Pay by {token}
                          </button>
                          <button
                            onClick={() => tnc ? onPay('eiso', selectedCoupon, qty.value) : setTncAlert(true)}
                            className={`bg-orange-200 border-2 border-amber-600 rounded-md py-3 px-8 flex items-center justify-center text-base font-bold text-white hover:bg-orange-700
                              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-500
                              ${!tnc ? 'cursor-not-allowed' : ''}`}
                          >
                            Pay by eISO
                          </button>
                        </div>
                      </section>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ProductPopup;