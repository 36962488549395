import { useState, useRef } from 'react';
import { useAlert } from 'react-alert'

const MetamaskButton = ({ address = null, onClick, className = '' }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const _onClick = async () => {
    try {
      setLoading(true);
      await onClick();
    } catch(e) {
      console.log(e);
      alert.error(e.message || e.toString());
    } finally {
      setLoading(false);
    }
  }

  return (
    <button
      disabled={loading}
      onClick={() => _onClick()}
      className={`
              ${loading ? 'bg-gray-200' : 'bg-white'}
                text-sm text-gray-900 font-bold shadow-lg px-8 py-2 rounded-full
                flex flex-row justify-center items-center
                hover:bg-teal-400 hover:shadow-md hover:text-white
                active:bg-teal-500
                transaction-all duration-200
                ${className}
              `}
    >
      {
        loading ? 'Loading...' 
          : address ? `Sign for ${address.substr(0, 4) + '...' + address.substr(-4)}`
            : 'Connect Wallet'
      }
    </button>
  )
}

export default MetamaskButton;