class Theme {
  common = {
    blue: '#01B4D8',
    teal: '#479992',
    orange: '#F29E1E',
    red: '#9e2a2b',
    yellow: '#ffbe0b',
    lime: '#2a9d8f',
    pink: '#de69b0',
    montserrat: "'Montserrat', sans-serif",
  }

  light = Object.assign({}, this.common, {
    backgroundColor: '#ffffff',
    backgroundGrayColor: '#f3f6fa',
    primary: '#1cb4d8',
    primaryLight: '#89e1ef',
    primaryDark: '#007dc6',
    textColor: '#070304',
    textColorLight: '#9894ab',
    secondary: '#474377',
  });

  dark = {};

  getColor = (colorName) => {
    return this.common[colorName];
  };
}

export const ISOtheme = new Theme();