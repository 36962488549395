import * as THREE from "three";
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import ObjectLoader from "./ObjectLoader";
import Floor from './Floor';

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    // Wait for resources
    this.resources.on("ready", () => {
      // Setup
      this.environment = new Environment();
      this.objectLoader = new ObjectLoader();
      this.floor = new Floor();
    });
  }

  update() {
    // if (this.fox) this.fox.update();
  }

  changeFrame(imgSrc, frame) {
    this.objectLoader.changeFrame(imgSrc, frame);
  }
}
