import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SocialButton from '../SocialButton';
import Container from './Container';
import PublicMintSection from './PublicMintSection';
import { publicMintDate } from './mintDate';

let interval;
const network = process.env.REACT_APP_NETWORK_VERSION;
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const etherscanString = `https://${network == 4 ? 'rinkeby.' : ''}etherscan.io/address/${contractAddress}`;

const TopBanner = () => {
    const [img, setImg] = useState(1);

    useEffect(() => {
        interval = setInterval(() => {
            setImg(now => {
                if (now < 5) return now + 1;
                return 1;
            })
        }, 3500);

        return () => clearInterval(interval);
    }, [])

    const publicMintStart = dayjs().diff(dayjs(publicMintDate));

    return (
        <Container className="transition-all duration-1000 ease-in-out">
            <div className="left">
                <img className="w-32 md:w-64" width="300" height="86.25" src="/image/isoroom-h-logo.png" className="" />
                <p className="mt-4 text-2xl font-bold text-blue">Showcase your favourite NFT collections in your house</p>
                <p className="mt-8">
                    We all love NFT! We love the art, we love the culture, and of course it's the community that we love! Surrounded by a group of NFT investors and enthusiasts, we discover that there is a common need among them, which is to show and share their favourite NFT collections in a more stylish and interactive way.
                </p>
                <p className="mt-4 flex items-center">
                    <SocialButton type="discord" />
                    <SocialButton type="twitter" className="ml-1" />
                </p>
                <PublicMintSection />
                <div className="font-bold mt-4">
                    <p className="flex row items-center">
                    Contract<img src="/check.png" width="10" className="mr-1"/>: 
                        <a className="break-all underline max-w-full overflow-hidden"
                        href={etherscanString} target="_blank">{contractAddress}</a>
                    </p>
                    <p>
                    Opensea: <a className="break-all underline max-w-full overflow-hidden"
                        href="https://opensea.io/collection/genesis-isoroom" target="_blank">
                            https://opensea.io/collection/genesis-isoroom
                        </a>
                    </p>
                </div>
            </div>
            <div className="right">
                <div className="bg-gray-300 w-full relative banner-img flex justify-center">
                    {
                        [
                            '/image/rooms/bedroom.jpg',
                            '/image/rooms/kitchen.jpg',
                            '/image/rooms/livingroom.jpg',
                            '/image/rooms/toilet.jpg',
                            '/image/rooms/workroom.jpg',
                        ].map((item, i) =>
                            <div
                                className="room-image w-full h-full absolute top-0 left-0 transition-all duration-1000"
                                style={{
                                    opacity: img === i + 1 ? 100 : 0,
                                    background: `url("${item}") center center / cover`
                                }}>
                            </div>
                        )
                    }
                </div>
            </div>
        </Container>
    )
}

export default TopBanner;