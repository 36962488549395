import Badge from "../../Badge";

export interface ProductItemProps {
  onClick: () => void;
  id: string;
  imageSrc: string;
  imageAlt: string;
  name: string;
  price: number;
  token: string;
  category: string;
  type?: string;
  seller: {
    name: string;
    profilePic: string;
  };
  qty: {
    stock: number;
    total: number;
  };
  description: string;
  mint_date?: string;
  discord?: string;
  twitter?: string;
  special: string | null;
  disabled?: boolean;
}

/**
 * A ProductItem storybook
 */
const ProductItem = ({
  onClick,
  id,
  imageSrc,
  imageAlt,
  name,
  price,
  token,
  category,
  qty,
  seller,
  special = null,
  disabled = false,
}: ProductItemProps) => {
  const _disabled = disabled || qty.stock === 0;

  return (
    <div
      onClick={() => _disabled ? () => {} : onClick()}
      key={id}
      className={`relative group p-2 rounded-md hover:bg-gray-100
        duration-300 ease-in-out ${_disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
      <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100 shadow relative">
        <img
          src={imageSrc}
          alt={imageAlt}
          className={`object-center object-cover w-full ${_disabled ? 'grayscale' : ''}
            ${special !== null ? 'rainbow' : ''}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src="/image/1x1-square.png";
          }}
        />
        <Badge
          mode={
              qty.stock === 0 ? 'red'
                : 'default'
          } className="absolute top-2 right-1.5 capitalize"
        >
          {
            qty.stock === 0 ? 'Sold Out' :
            `${qty.stock}/${qty.total} Left`
          }
        </Badge>
        {
          special &&
            <Badge
              mode="indigo"
              className="absolute bottom-2 right-1.5 capitalize"
            >
              {special}
            </Badge>
        }
      </div>
      <div className="p-1">
        <h3 className="mt-4 font-bold text-sm text-gray-700">
          <span className="absolute inset-0" />
          {name}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{category}</p>
        <p className="mt-1 text-sm font-medium text-gray-900">{price} {token}</p>
      </div>
    </div>
  )
}

export default ProductItem;