import Web3 from 'web3';

const NETWORK_VERSION = process.env.REACT_APP_NETWORK_VERSION; // 1, 4

const connectMetamask = async (listener) => {
  let accountAddress;
  if (!window.ethereum) throw new Error('Please install MetaMask.')

  // As we are doing mainnet or rinkeby only, 0x1 & 0x4 is fine
  const chainId = `0x${NETWORK_VERSION}`;

  if (window.ethereum.networkVersion != NETWORK_VERSION) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
  }

  // If still not working, ask to switch manually
  if (window.ethereum.networkVersion != NETWORK_VERSION) {
    throw new Error(`Please switch to Ethereum ${NETWORK_VERSION == 1 ? 'Mainnet' : 'Rinkeby'}`)
  }


  /**
   * Set up listener after connected
   */
  window.ethereum.on('accountsChanged', listener.handleAccountsChanged);
  window.ethereum.on('chainChanged', listener.handleChainChanged);
  window.ethereum.on('disconnect', listener.handleDisconnect)

  const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
  const web3 = new Web3(window.ethereum);

  return { accounts, web3 };
};

const removeMetamaskListener = (listener) => {
  if (!window.ethereum) return;
  window.ethereum.removeListener('accountsChanged', listener.handleAccountsChanged);
  window.ethereum.removeListener('chainChanged', listener.handleChainChanged);
  window.ethereum.removeListener('disconnect', listener.handleDisconnect);
}

export {
  connectMetamask,
  removeMetamaskListener,
};
