import { useState } from 'react';
import Button from '../Button';
import ModalWrapper from '../general/ModalWrapper';

const typeToText = (type) => {
  return type === 'iso_token' ? '$ISO Token'
    : type === 'nft' ? 'NFT'
      : type === 'whitelist' ? 'Whitelist'
        : ''
}

const GiftHistoryModal = ({ open, onClose, history = [], loadMore }) => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const _loadMore = async () => {
    try {
      setLoading(true);
      await loadMore();
    } catch (e) {
      setErrMsg(e.message || e.toString());
    }
    finally {
      setLoading(false);
    }
  }

  const historyByBatch = {};
  history.forEach(item => {
    if (!historyByBatch[item.attributes.batch])
      historyByBatch[item.attributes.batch] = [];

    historyByBatch[item.attributes.batch].push({ ...item.attributes })
  });

  return (
    <ModalWrapper open={open} onClose={onClose} >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white p-4" style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
          <div className="text-left w-full">
            <img
              className="absolute right-4 top-4 hover:opacity-50 transition duration-150 ease-in-out cursor-pointer"
              width="20"
              src="/close-svgrepo-com.svg"
              onClick={onClose}
            />
            <p className="text-xl font-bold">My Gift History</p>
            {
              Object.keys(historyByBatch).map(batch => (
                <div className="mt-4 mb-4">
                  <p className="font-bold text-sm">{batch}</p>
                  {
                    historyByBatch[batch].map(item => {
                      if (!item.reward) return;
                      return (
                        <a href={item.reward.link} target="_blank">
                          <div key={item.reward.tokenId} className="flex flex-row px-4 py-2 bg-gray-50 hover:bg-gray-100 mb-1 rounded-md">
                            <img
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "/image/1x1-square.png";
                              }}
                              src={item.reward.image}
                              className="rounded-full w-12 h-12 mr-2"
                            />
                            <div>
                              <p className="text-xs">Type: {typeToText(item.reward.type)}</p>
                              <p><b>Gift: {item.reward.name} {typeToText(item.reward.type)}</b></p>
                            </div>
                          </div>
                        </a>
                      )
                    })
                  }
                </div>
              ))
            }
            <Button
              loading={loading}
              style={{ margin: '0 auto' }}
              onClick={_loadMore}
            >Load More</Button>
            {
              errMsg.length > 0 &&
                <p className="text-red-900 mt-2 font-bold break-all">{errMsg}</p>
            }
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default GiftHistoryModal;