import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';

let provider;

const disconnectWalletConnect = async () => {

}

const connectWalletConnect = async (listener) => {
  const provider = new WalletConnectProvider({
    rpc: {
      1: "https://eth-mainnet.alchemyapi.io/v2/L-Kxl89o-t03u-FR6wTUrKSAxVKahRDD",
    },
  });
  
  //  Enable session (triggers QR Code modal)
  await provider.enable();
  const web3 = new Web3(provider);
  provider.on("accountsChanged", listener.handleAccountsChanged);
  provider.on("chainChanged", listener.handleChainChanged);
  provider.on("disconnect", listener.handleDisconnect);

  const accounts = await web3.eth.getAccounts();
  return { accounts, web3 };
}

export {
  connectWalletConnect,
  disconnectWalletConnect,
}