import dayjs from 'dayjs';
import Markdown from '../../Markdown';
import { FC } from 'react'

interface AnnouncementProps {
  announcements: Array<any>
}

const Announcement:FC<AnnouncementProps> = ({ announcements }) => {
  return (
    <>
      {
        announcements.length > 0 &&
        <div className="mt-4 col-span-1 lg:col-span-2 rounded-md p-2 pb-0 shadow-md font-sans bg-white min-h-200px">
          <p className="text-lg font-semibold text-slate-900">📰 Announcement</p>

          <div className="overflow-y-scroll h-full max-h-44" >
            {announcements.map((post) => (
              <div key={post.title} className="mt-2">
                <p className="text-xs text-gray-500">
                  <time dateTime={post.datetime}>{dayjs(post.publishedAt).format('YYYY-MM-DD')}</time>
                </p>
                <a href={post.url || '#'} target="_blank" className="mt-1 block">
                  <p className="font-semibold text-gray-900">{post.title}</p>
                  <Markdown>{post.content}</Markdown>
                  {
                    post.url !== null &&
                    <p className="mt-1 text-xs font-bold text-orange-800">Click to read more</p>
                  }
                </a>
                <hr className="my-2" />
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}

export default Announcement;