import { CashIcon, ChevronRightIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { FC } from 'react';
import Web3 from 'web3';

const web3 = new Web3();

interface Transaction   {
  id: number;
  txhash: string;
  fromToken: string;
  toToken: string;
  status: string;
  value: string;
  timestamp: string;
};

interface Props {
  transactions: Transaction[];
  onPendingClick: any;
}

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  pending: 'bg-yellow-100 text-yellow-800 cursor-pointer underline',
  failed: 'bg-gray-100 text-gray-800',
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const typeRename = (from: string, to: string) => {
  const _from = from === process.env.REACT_APP_ISOTOKEN_ADDRESS ? 'ISO' : 'eISO';
  const _to = to === process.env.REACT_APP_ISOTOKEN_ADDRESS ? 'ISO' : 'eISO';
  return `${_from} > ${_to}`;
}

const HistoryTable: FC<Props> = ({ transactions, onPendingClick }) => {
  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-900">Recent activity</h1>
      <p className="mt-1 text-sm text-gray-500">
        Past 100 activities
      </p>

      {/* Activity list (smallest breakpoint only) */}
      <div className="shadow sm:hidden">
        <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {transactions.map((transaction) => (
            <li key={transaction.id}>
              <a
                target="_blank"
                href={`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${transaction.txhash}`}
                className="block px-4 py-4 bg-white hover:bg-gray-50"
              >
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{transaction.txhash}</span>
                      <span>
                        <span className="text-gray-900 font-medium">{web3.utils.fromWei(transaction.value)}</span>{' '}
                        {typeRename(transaction.fromToken, transaction.toToken)}
                      </span>
                      <time>
                        {dayjs(transaction.timestamp).format('YYYY-MM-DD hh:mm')} +UTC
                      </time>
                    </span>
                  </span>
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </a>
            </li>
          ))}
        </ul>

        <nav
          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="flex-1 flex justify-between">
            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Previous
            </a>
            <a
              href="#"
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Next
            </a>
          </div>
        </nav>

        <nav
          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="flex-1 flex justify-between">
            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Previous
            </a>
            <a
              href="#"
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Next
            </a>
          </div>
        </nav>
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto mt-8">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactions.map((transaction) => (
                    <tr key={transaction.id} className="bg-white">
                      <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <div className="flex">
                          <a
                          target="_blank"
                          href={`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${transaction.txhash}`} className="group inline-flex space-x-2 truncate text-sm">
                            <p className="text-gray-500 truncate group-hover:text-gray-900">
                              {transaction.txhash}
                            </p>
                          </a>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <span className="text-gray-900 font-medium">{web3.utils.fromWei(transaction.value)} </span>
                        {typeRename(transaction.fromToken, transaction.toToken)}
                      </td>
                      <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                        <div
                          className={classNames(
                            // @ts-ignore
                            statusStyles[transaction.status],
                            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize',
                          )}
                          onClick={() => transaction.status === 'pending' 
                            ? onPendingClick(transaction)
                            : {}
                          }
                        >
                          {transaction.status}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                        <time>
                          {dayjs(transaction.timestamp).format('YYYY-MM-DD hh:mm')} +UTC
                        </time>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HistoryTable;