import * as THREE from "three";
import Experience from "../Experience.js";

export default class Room {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    this.createFloor();
    this.createFloorB();
  }

  createFloor() {
    let pos = { x: 0, y: 0, z: 0 };
    let scale = { x: 8, y: 0.1, z: 8 };
  
    let blockPlane = new THREE.Mesh(
      new THREE.BoxBufferGeometry(),
      new THREE.MeshPhongMaterial({
        color: 0xc5c1b6,
        emissive: 0xffffff,
        emissiveIntensity: 0.2
      })
    );
    blockPlane.position.set(pos.x, pos.y, pos.z);
    blockPlane.scale.set(scale.x, scale.y, scale.z);
    blockPlane.castShadow = false;
    blockPlane.receiveShadow = true;
    this.scene.add(blockPlane);
  
    blockPlane.userData.ground = true
  }

  createFloorB() {
    let pos = { x: -0.05, y: 0.05, z: -0.05 };
    let scale = { x: 2.1, y: 0.1, z: 2.1 };
  
    let blockPlane = new THREE.Mesh(
      new THREE.BoxBufferGeometry(),
      new THREE.MeshPhongMaterial({
        color: 0xffffff,
        shininess: 0,
      })
    );
    blockPlane.position.set(pos.x, pos.y, pos.z);
    blockPlane.scale.set(scale.x, scale.y, scale.z);
    blockPlane.castShadow = false;
    blockPlane.receiveShadow = false;
    this.scene.add(blockPlane);
  }
  
}