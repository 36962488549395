import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MetamaskButton from '../../components/MetamaskButton';
import { useAuth } from '../../context/Auth';

const ImageWrapper = styled.div`
  .dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      padding: 2px;
      background-color: white;
      border-radius: 50%;
    }
  }

  :hover > .dropdown {
    opacity: 1;
  }
`

const Wallet = ({ roomId = null, selectImg = () => { }, selectRoom = () => { } }) => {
  const { connected, connectWallet, disconnect } = useAuth();
  const [error, setError] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [loading, setLoading] = useState(null); // null | string
  const [address, setAddress] = useState(null);
  const [ended, setEnded] = useState(false);
  const [searchParams] = useSearchParams();
  const [frameCount, setFrameCount] = useState(3);

  const disconnectAndReload = () => {
    setNfts(null);
    setAddress(null);
    setEnded(false);

    window.location.href = '/portal';
  }

  const fetchProfile = async (address) => {
    try {
      setError(null);
      setLoading('Fetching NFTs...');

      const response = await fetch(
        'https://api.opensea.io/api/v1/'
        + 'assets?order_direction=desc&limit=50&offset=0&owner='
        + address,
        {
          headers: { 'X-API-KEY': '183b9eb7bba7411ebfb69d147201ac20' }
        }
      );

      const result = await response.json();
      let assets = result.assets;
      if (!assets || assets.length === 0) throw new Error('NFTs not found.');
      if (assets?.filter(i => i.asset_contract.address === 
          '0xe3edd17272c618693757a917e566749a03397165').length == 0)
        throw new Error('isoroom not found.');

      setNfts(assets);
    } catch (e) {
      setError(e.message || e.toString());
    } finally {
      setLoading(null);
    }
  }

  const loadMore = async (offset) => {
    try {
      setLoading('Loding more NFTs...');
      const response = await fetch(
        'https://api.opensea.io/api/v1/'
        + 'assets?order_direction=desc&limit=50&offset=0&owner='
        + address + `&offset=${offset}`,
        {
          headers: { 'X-API-KEY': '183b9eb7bba7411ebfb69d147201ac20' }
        }
      );
      const result = await response.json();
      if (!result || result?.assets.length === 0) return setEnded(true);
      setNfts(ori => [...ori, ...result.assets]);
    } catch (e) {
      setError(e.message || e.toString());
    } finally {
      setLoading(null);
    }
  }

  useEffect(() => {
    const qsAddress = searchParams.get('address');
    if (qsAddress) {
      fetchProfile(false, qsAddress);
    }
  }, []);

  const updateFrameCount = async (rmId) => {
    let scoreTarget = rmId - 298 + 11;
    if (scoreTarget < 11) scoreTarget = scoreTarget + 2990;

    const request = await fetch(`https://df16ha7u1eog5.cloudfront.net/score/${scoreTarget}.json`);
    const roomData = await request.json();
    const _frameCount = roomData.attributes?.find(i => i.trait_type === 'frame')?.value[0]
    setFrameCount(parseInt(_frameCount));
  }

  useEffect(() => {
    if (roomId !== null)
      updateFrameCount(roomId);
  }, [roomId])

  useEffect(() => {
    if (connected !== null)
      fetchProfile(connected);
  }, [connected]);

  const filteredNfts = roomId == null ?
    nfts?.filter(i => i.asset_contract.address === '0xe3edd17272c618693757a917e566749a03397165') || []
    : nfts || []

  return (
    <div className="wallet px-4 z-50 bg-white min-h-full">
      {
        filteredNfts && filteredNfts.length > 0 ?
          <div className="py-2 px-8 pt-8">

            <button
              className="text-sm font-bold mb-2 border rounded-full px-4 py-1 cursor-pointer"
              onClick={disconnectAndReload}
            >Disconnect</button>

            <h3 className="text-xs font-bold text-blue-800">Hi, {address}</h3>
            <h2 className="text-4xl font-bold">Customise your isoroom</h2>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {
                filteredNfts.map((item, i) => (
                  <div key={i}>
                    <ImageWrapper key={i} className="col-span-1 relative">
                      <img
                        className="w-full h-auto opacity-0"
                        src="https://via.placeholder.com/1"
                      />
                      <div
                        style={{ background: 'url(' + item.image_url + ') center center / cover' }}
                        className="image-preview shadow-xl rounded-lg absolute top-0 left-0 w-full h-full cursor-pointer">
                      </div>
                      <div className="dropdown rounded-lg text-center cursor-pointer py-6 transition-all duration-200 ease-in-out">
                        {
                          roomId === null ?
                            <>
                              <div
                                onClick={() => selectRoom(item.token_id)}
                                className="bg-white text-xs font-bold p-2 rounded-full hover:opacity-60 transition-opacity duration-200"
                              >Customisation</div>
                            </>
                            :
                            <>
                              <p className="font-bold text-white text-sm mb-2">Replace Frame</p>
                              <div className="flex flex-row">
                                {
                                  new Array(frameCount).fill('_').map((_, i) => (
                                    <div
                                      key={i}
                                      onClick={() => selectImg(item.image_url, i + 1)}
                                      className="number hover:opacity-60 transition-opacity duration-200">
                                      {i + 1}</div>
                                  ))
                                }
                              </div>
                            </>
                        }
                      </div>
                    </ImageWrapper>

                    <div className="flex flex-row mt-2">
                      <img className="w-8 h-8 mt-1 mr-1 rounded-full" src={item.asset_contract?.image_url} />
                      <div>
                        <p className="text-sm font-bold text-blug-900">{item.name}</p>
                        <p className="text-xs text-blug-500">{item.asset_contract?.name || ''}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className='flex flex-col items-center justify-center w-full mb-24 mt-4'>
              {
                ended ?
                  <p>All NFTs loaded</p>
                  : roomId !== null &&
                    <button
                      disabled={loading !== null}
                      onClick={() => loadMore(nfts.length)}
                      className="
                        text-md font-bold shadow-lg px-8 py-2 rounded-full bg-white
                        flex flex-row justify-center items-center
                        hover:bg-amber-100 hover:shadow-md
                        active:bg-amber-300
                        transaction-all duration-200
                      "
                    >
                      {loading || 'Load More'}
                    </button>
              }
            </div>
          </div>
          :
          <div className='flex flex-col items-center justify-center' style={{ height: '100vh' }}>
            {!loading && <p className="font-semibold mb-4">Connect your wallet to customise your isoroom!</p>}

            <div
              className="w-full h-48 rounded-lg shadow-4xl flex justify-center items-center"
              style={{ background: 'url(/image/preview.png) center center / cover' }}
            >
              <MetamaskButton
                address={connected}
                onClick={() => connected ? disconnect() : connectWallet()}
              />
            </div>

            <p className="text-sm mt-4 font-semibold text-rose-800">
              Service temporarily suspended due to Opensea API limitation, we are working on it by switching to another stable service provider. Stay tuned.
              {/* {error} */}
            </p>
            <a href="/" className="text-sm font-bold underline">🔙 Back to isoroom.io</a>
          </div>
      }
    </div>
  )
}

export default Wallet;