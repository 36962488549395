import dayjs from 'dayjs';
import ListHeader from '../../components/store/ListHeader';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Markdown from '../../components/Markdown';
import Strapi from '../../services/Strapi';
import Badge from '../../components/Badge';

const PortalDashboard = ({ }) => {
  const alert = useAlert();
  const [announcements, setAnnouncements] = useState([]);
  const lastSeen = parseInt(localStorage.getItem('announcement-last-seen') || '0')

  const fetchAnnouncement = async () => {
    try {
      const result = await Strapi.get('announcements?sort=id:desc');
      const data = result.data.map((i: any) => ({ id: i.id, ...i.attributes }));
      setAnnouncements(data);
    } catch (e: any) {
      alert.error(e.message || e.toString());
    }
  }

  useEffect(() => {
    fetchAnnouncement();
    localStorage.setItem('announcement-last-seen', `${new Date().getTime()}`);
  }, []);

  return (
    <>
      <main className="max-w-7xl mx-auto">
        <div
          className="rounded-lg"
          style={{
            background: `url('https://images.unsplash.com/photo-1636109751387-e5e2434268e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1280&q=80') center center / cover`,
            height: 150,
            width: '100%',
            position: 'relative',
            marginBottom: 10,
          }}
        />

        <div className="mt-4">
          <ListHeader
            onShowAll={(v) => (v)}
            header="Announcement"
            setMobileFiltersOpen={() => { }}
            onViewGridChange={() => { }}
          />
          <section aria-labelledby="products-heading" className="pt-3 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            {
              announcements.map((post: any) => (
                <div className="w-full rounded-md p-2 pb-0 shadow-md font-sans bg-white">
                  <div className="overflow-y-scroll h-auto" >
                    <div key={post.title} className="">
                      <p className="text-xs text-gray-500 py-2">
                        {
                          dayjs(post.publishedAt).diff(dayjs(lastSeen)) > 0 &&
                          <Badge mode="red" className="mr-2 mt-2">New</Badge>
                        }
                        <time dateTime={post.datetime}>{dayjs(post.publishedAt).format('YYYY-MM-DD')}</time>
                      </p>
                      <p className="font-semibold text-gray-900">{post.title}</p>
                      <div className="mt-1 text-sm text-gray-500">
                        <Markdown>{post.content}</Markdown>
                      </div>
                      <a href={post.url || '#'} target="_blank" className="mt-1 block">
                        {
                          post.url !== null &&
                          <p className="mt-1 text-xs font-bold text-orange-800">Click to read more</p>
                        }
                      </a>
                      <hr className="my-2" />
                    </div>
                  </div>
                </div>
              ))
            }
          </section>
        </div>
      </main>
    </>
  )
}

export default PortalDashboard;